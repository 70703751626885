<template>
<div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title">Mis cursos</p>
    </header>
    <section class="modal-card-body">
        <b-tabs type="is-boxed">
            <b-tab-item label="Enseñanza Básica">
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="b in basica" v-bind:key="b.id">
                        <nav class="panel is-default">
                            <p class="panel-heading">
                                {{b.nombre}}
                            </p>
                            <div class="panel-block" v-for="c in b.cursos" v-bind:key="c.id">
                                <b-checkbox :type="'is-' + color" v-model="cursos_basica" :native-value="c.id">
                                    {{b.nombre}} {{c.letra}}
                                </b-checkbox>
                            </div>
                        </nav>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Enseñanza Media">
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="b in media" v-bind:key="b.id">
                        <nav class="panel is-default">
                            <p class="panel-heading">
                                {{b.nombre}}
                            </p>
                            <div class="panel-block" v-for="c in b.cursos" v-bind:key="c.id">
                                <b-checkbox :type="'is-' + color" v-model="cursos_media" :native-value="c.id">
                                    {{b.nombre}} {{c.letra}}
                                </b-checkbox>
                            </div>
                        </nav>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="PreKinder">
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="k in prekinder" v-bind:key="k.id">
                        <nav class="panel is-default">
                            <p class="panel-heading">
                                {{k.nombre}}
                            </p>
                            <div class="panel-block" v-for="c in k.cursos" v-bind:key="c.id">
                                <b-checkbox :type="'is-' + color" v-model="cursos_prekinder" :native-value="c.id">
                                    {{k.nombre}} {{c.letra}}
                                </b-checkbox>
                            </div>
                        </nav>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Kinder">
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="k in kinder" v-bind:key="k.id">
                        <nav class="panel is-default">
                            <p class="panel-heading">
                                {{k.nombre}}
                            </p>
                            <div class="panel-block" v-for="c in k.cursos" v-bind:key="c.id">
                                <b-checkbox :type="'is-' + color" v-model="cursos_kinder" :native-value="c.id">
                                    {{k.nombre}} {{c.letra}}
                                </b-checkbox>
                            </div>
                        </nav>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
        <b-button :type="'is-' + color" :loading="isLoadingGuardar" @click="guardar">Guardar cursos</b-button>
    </section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
</div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['color', 'asignatura'],
    name: 'Modal',
    data() {
        return {
            basica: [],
            media: [],
            kinder : [],
            prekinder: [],
            cursos_media: [],
            cursos_basica: [],
            cursos_kinder: [],
            cursos_prekinder: [],
            isFullPage: true,
            isLoading: false,
            isLoadingGuardar: false
        }
    },
    methods: {
        guardar() {
            let self = this
            self.isLoadingGuardar = true
            let params = {
                basica : this.cursos_media,
                media: this.cursos_basica,
                kinder: this.cursos_kinder,
                prekinder: this.cursos_prekinder,
                asignatura: this.asignatura
            }
            axios.post('/api/usuarios/asignar/cursos', params).then((res) => {
                if(res.data.status) {
                    this.$buefy.toast.open({
                        message: res.data.message,
                        position: 'is-top',
                        type: 'is-success'
                    })
                    this.$emit('close')
                    this.$emit('cerrarModal')
                }
                self.isLoadingGuardar = false
            }).catch((err) => {
                self.isLoadingGuardar = false
                throw err
            })
        },
        getCursos() {
            let self = this
            self.isLoading = true
            axios.post('/api/niveles/get/curso', {asignatura: this.asignatura}).then((res) => {
                res.data.forEach((item) => {
                    if (item.educacion_id == 1) {
                        if (item.cursos.length) {
                            this.basica.push(item)
                            item.cursos.forEach((i) => {
                                if(i.ramos.length) {
                                    this.cursos_basica.push(i.id)
                                }
                            });
                        }
                    }
                    if (item.educacion_id == 2) {
                        if (item.cursos.length) {
                            this.media.push(item)
                            item.cursos.forEach((i) => {
                                if(i.ramos.length) {
                                    this.cursos_media.push(i.id)
                                }
                            });
                        }
                    }
                    if (item.educacion_id == 3) {
                        if (item.cursos.length) {
                            this.prekinder.push(item)
                            item.cursos.forEach((i) => {
                                if(i.ramos.length) {
                                    this.cursos_prekinder.push(i.id)
                                }
                            });
                        }
                    }
                    if (item.educacion_id == 4) {
                        if (item.cursos.length) {
                            this.kinder.push(item)
                            item.cursos.forEach((i) => {
                                if(i.ramos.length) {
                                    this.cursos_kinder.push(i.id)
                                }
                            });
                        }
                    }
                })
                self.isLoading = false
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getCursos()
    }
}
</script>
