<template>
  <div class="container" >
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active"><a href="#" aria-current="page">Home</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="box">
            <div>
                <h1 class="title is-3 mb-6 has-text-info">Te damos la bienvenida, {{user.name}}</h1>
            </div>
            <div class="columns">
                <div class="column is-3 ">
                   <div class="box has-background-light">
                        <div class="columns">
                            <div class="column is-2 pr-0 pl-0">
                                <h1 class="title is-3 mt-2 has-text-info " >{{count_progresiva}}</h1>
                            </div>
                            <div class="column is-10">
                                <h1 class="subtitle is-5">Evaluaciones Progresivas</h1>
                                <h1 class="title is-5">Aplicadas</h1>
                            </div>
                        </div>
                   </div>
                   <div class="box has-background-light">
                        <div class="columns">
                            <div class="column is-2 pr-0 pl-0">
                                <h1 class="title is-3 mt-2 has-text-info " >{{count_simce}}</h1>
                            </div>
                            <div class="column is-10">
                                <h1 class="subtitle is-5">Ensayos SIMCE</h1>
                                <h1 class="title is-5">Aplicados</h1>
                            </div>
                        </div>
                   </div>
                   <div class="box has-background-light">
                        <div class="columns">
                            <div class="column is-2 pr-0 pl-0">
                                <h1 class="title is-3 mt-2 has-text-info " >{{en_curso.total}}</h1>
                            </div>
                            <div class="column is-10">
                                <h1 class="subtitle is-5 ">Evaluaciones</h1>
                                <h1 class="title is-5">en curso</h1>
                            </div>
                        </div>
                   </div>
                   <div class="box has-background-light">
                        <div class="columns">
                            <div class="column is-2 pr-0 pl-0">
                                <h1 class="title is-3 mt-2 has-text-info " >{{finalizadas.total}}</h1>
                            </div>
                            <div class="column is-10">
                                <h1 class="subtitle is-5">Evaluaciones</h1>
                                <h1 class="title is-5">Finalizadas</h1>
                            </div>
                        </div>
                   </div>
                   <div class="box has-background-light">
                        <div class="columns">
                            <div class="column is-2 pr-0 pl-0">
                                <h1 class="title is-3 mt-2 has-text-info " >0</h1>
                            </div>
                            <div class="column is-10">
                                <h1 class="subtitle is-6">Rubricas/Listas/Escalas</h1>
                                <h1 class="title is-5">Aplicadas</h1>
                            </div>
                        </div>
                   </div>   
                </div>
                <div class="column is-5" >                   
                   <div class="box has-background-light">
                        <img :src="'/assets/img/portada_evalua.png'" alt="aplicar_prueba" width="550">
                   </div>
                </div>
                <div class="column is-4">
                    <div class="box">
                        <div class="columns">
                            <div class="column is-2">
                                <img :src="'/assets/img/iconos/aplicar_prueba.png'" alt="aplicar_prueba" width="42">
                            </div>
                            <div class="column is-8">
                                <h1 class="title is-5">Aplicar</h1>
                                <h1 class="subtitle is-5">Evaluacion</h1>
                            </div>
                            <div class="column is-2 "  >
                                <b-button tag="router-link" :to="'/asignacion/educacion'" type="is-info" class="is-full aeduc-home-arrow"><i class="fas fa-chevron-right"></i></b-button>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="columns">
                            <div class="column is-2">
                                <img :src="'/assets/img/iconos/creado_prueba.png'" alt="creado_prueba" width="42">
                            </div>
                            <div class="column is-8">
                                <h1 class="title is-5">Creador</h1>
                                <h1 class="subtitle is-5">de Evaluaciones</h1>
                            </div>
                            <div class="column is-2">
                                <b-button tag="router-link" :to="'/crear/evaluacion/niveles'" type="is-info" class="is-full aeduc-home-arrow"><i class="fas fa-chevron-right"></i></b-button>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="columns">
                            <div class="column is-2">
                                <img :src="'/assets/img/iconos/prueba_curso.png'" alt="prueba_curso" width="42">
                            </div>
                            <div class="column is-8">
                                <h1 class="title is-5">Mis Evaluaciones</h1>
                                <h1 class="subtitle is-5">en curso</h1>
                            </div>
                            <div class="column is-2">
                                <b-button tag="router-link" :to="'/evaluaciones/en_curso'" type="is-info" class="is-full aeduc-home-arrow"><i class="fas fa-chevron-right"></i></b-button>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="columns">
                            <div class="column is-2">
                                <img :src="'/assets/img/iconos/prueba_finalizada.png'" alt="prueba_finalizada" width="42">
                            </div>
                            <div class="column is-8">
                                <h1 class="title is-5">Mis Evaluaciones</h1>
                                <h1 class="subtitle is-5">finalizadas</h1>
                            </div>
                            <div class="column is-2">
                                <b-button tag="router-link" :to="'/evaluaciones/finalizadas'" type="is-info" class="is-full aeduc-home-arrow"><i class="fas fa-chevron-right"></i></b-button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="box">
                        <div class="columns">
                            <div class="column is-2">
                                <img :src="'/assets/img/iconos/recursos_digitales.png'" alt="recursos_digitales" width="42">
                            </div>
                            <div class="column is-8">
                                <h1 class="title is-5">Recursos</h1>
                                <h1 class="subtitle is-5">digitales</h1>
                            </div>
                            <div class="column is-2">
                                <b-button tag="router-link" :to="'/recursosdigitales'" type="is-info" class="is-full aeduc-home-arrow"><i class="fas fa-chevron-right"></i></b-button>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from '../components/Colegios.vue'
import VueC3 from 'vue-c3'

import {
    mapGetters
} from 'vuex'
import axios from 'axios'
import Vue from 'vue'


export default {
    name: "Home",
    metaInfo: {
        title: 'Home'
    }, 
    components: {
        Colegios,
         VueC3
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },
    data(){
        return{
            count_progresiva : 0,
            count_simce : 0,
            en_curso:0,
            finalizadas:0,       

        }
    },
    methods:{     
        asignaciones(){
             axios.post('/api/asignaciones/all', {
            }).then((res) => {
               res.data.forEach((item) => {
                    if (item.prueba.prueba_tipo_id == 1) {
                        this.count_progresiva++;
                    } 
                    if(item.prueba.prueba_tipo_id == 2) {
                        this.count_simce++;
                    }
                })
            }).catch((err) => {
                throw err
            })
        },
        getEvaluacionesPendientes() {
            axios.post('/api/asignaciones/en_curso', {              
            }).then((res) => {
                this.en_curso = res.data
            }).catch((err) => {
                throw err
            })
        },
        getEvaluacionesFinalizadas() {
            this.isLoading = true;
            axios.post('/api/asignaciones/finalizadas', {
            }).then((res) => {
                this.finalizadas = res.data
            }).catch((err) => {
                throw err
            })
        },
    },

    mounted() {
        this.asignaciones()
        this.getEvaluacionesPendientes()
        this.getEvaluacionesFinalizadas()

    }
};
</script>
