import store from '../store/index';

export default function auth({ next, router }) {

    if (store.getters['auth/acceso'].rol_id == 5) {
        return next();
    }

    return router.push({
        name: 'Home'
    });
}
