<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Crear evaluación</router-link>
                        </li>
                        <li>
                            <router-link to="/crear/evaluacion/niveles">Niveles</router-link>
                        </li>
                        <li>
                            <router-link :to="'/crear/evaluacion/niveles/' + route_nivel + '/asignaturas'">Asignaturas</router-link>
                        </li>
                        <li>
                            <router-link :to="'/crear/evaluacion/niveles/' + route_nivel + '/asignaturas/' +  route_asignatura + '/evaluaciones'">Evaluaciones</router-link>
                        </li>
                        <li>
                            <router-link :to="'/crear/evaluacion/niveles/' + route_nivel + '/asignaturas/' + route_asignatura + '/objetivos/' + this.$route.params.tipo + '/' + route_prueba">Objetivos</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Crear lista de cotejo</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="columns">
        <div class="column is-3">
            <div class="box">
                <div class="columns">
                    <div class="column is-6">
                        <h2 class="subtitle is-6 mt-3">OAs / Criterios</h2>
                    </div>
                    <div class="column">
                        <div class="is-pulled-right">
                            <b-button type="is-primary" rounded outlined>Ver detalle</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="box">
                <div class="columns">
                    <div class="column is-8">
                        <h2 class="subtitle mt-2">Crear evaluación, {{infoAsignatura.nombre}}, {{infoNivel.nombre}}</h2>
                    </div>
                    <div class="column">
                        <div class="is-pulled-right">
                            <div class="buttons">
                                <b-dropdown aria-role="list">
                                    <button class="button is-primary is-outlined is-rounded" slot="trigger" slot-scope="{ active }">
                                        <span>Más acciones</span>
                                        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                    </button>

                                    <b-dropdown-item aria-role="listitem" @click="guardar_borrador">Guardar como borrador</b-dropdown-item>
                                    <!-- <b-dropdown-item aria-role="listitem">Descargar prueba</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem">Eliminar prueba</b-dropdown-item> -->
                                </b-dropdown>
                                <b-button type="is-primary" class="ml-3" rounded @click="guardar" :loading="loadingGuardar">Guardar</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <div class="columns">
            <div class="column">

                <table class="table is-fullwidth is-bordered">
                    <tr>
                        <td></td>
                        <td :colspan="criteriosTitulos.length" class="has-text-centered">
                            <strong>Categorías</strong>

                        </td>
                    </tr>
                    <tr>
                        <td class="has-text-centered">
                            <strong>Criterios</strong>
                            <br>
                            <b-button class="mt-2" type="is-info is-light is-small" outlined rounded @click="agregarCriterio">+ Agregar Criterio</b-button>
                            <b-button class="mt-2 ml-2" type="is-danger is-light is-small" outlined rounded @click="quitarCriterio">- Quitar Criterio</b-button>
                        </td>
                        <td v-show="key < nivelesTotal" v-for="(criterio, key) in criteriosTitulos" v-bind:key="key" class="has-background-white-ter">

                            <div class="columns">
                                <div class="column">
                                    <p class="is-small">Puntos</p>
                                </div>
                                <div class="column">
                                    <b-numberinput rounded size="is-small" :controls="false" v-model.lazy="puntos[key]" :value="puntos[key]"></b-numberinput>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-input rounded :lazy="true" v-model="criteriosTitulos[key]" :value="criterio"></b-input>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr v-show="key < criteriosTotal" v-for="(crit, key) in criteriosTotal" v-bind:key="key">
                        <td class="has-background-white-ter" v-show="k - 1 < nivelesTotal" v-for="(criterio, k) in criterios[key]" v-bind:key="k">
                            <b-button expanded class="mb-2" outlined type="is-info" v-if="k==0" @click="getObjetivos(key)">+ Objetivos de Aprendizaje</b-button>
                            <div v-if="Object.keys(objetivos[key]).length !== 0 && k==0">
                                <div class="notification">
                                    <button class="delete" @click="eliminarObj(key)"></button>
                                    <p class="title is-5">{{objetivos[key].codigo}} </p>
                                </div>
                            </div>
                            <b-input v-if="k==0" :lazy="true" :has-counter="false" v-model="criterios[key][k]" maxlength="200" type="textarea"></b-input>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</div>
</template>

<script>
import Colegios from '../../../components/Colegios.vue'
import Objetivos from '../../../components/crear/evaluacion/Objetivos.vue'

import axios from 'axios'

export default {
    name: "CrearEvaluacionRubrica",
    metaInfo: {
        title: 'Creación evaluación'
    },
    components: {
        Colegios
    },
    data() {
        return {
            route_nivel: this.$route.params.nivel,
            route_asignatura: this.$route.params.asignatura,
            route_prueba: this.$route.params.prueba,
            route_tipo: this.$route.params.tipo,
            infoAsignatura: {},
            infoNivel: {},
            isFullPage: false,
            isLoading: false,
            criteriosTitulos: [
                'Si',
                'No'
            ],
            puntos: [
                1,0
            ],
            criterios: [
                [
                    ''
                ],
            ],
            objetivos:[],
            criteriosTotal: 1,
            nivelesTotal: 2,
            loadingGuardar: false,
            informacion: []
        }
    },
    methods: {
        setObjetivo(args) {
            this.$set(this.objetivos, args.index, args);
        },
        getObjetivos(params) {
            this.$buefy.modal.open({
                parent: this,
                component: Objetivos,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba,
                    index: params
                },
                events: {
                    setObjetivos: this.setObjetivo
                }
            })
        },
        eliminarObj(key) {
            this.$set(this.objetivos, key, {});
        },
        guardar() {
            if (!this.criterios[0][0]) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar al menos un criterio',
                    type: 'is-danger'
                })
            }

            let self = this;
            self.loadingGuardar = true;

            let link = '/api/creador/evaluacion/rubrica/update';

            if (!this.informacion) {
                link = '/api/creador/evaluacion/rubrica/store';
            }
            this.$buefy.dialog.confirm({
                title: 'Guardar evaluacion',
                message: 'Si guardas la lista de cotejo ya no podras editarla, es recomendable guardar como borrador, selecciona "mas acciones" luego clic en "guardar como borrador", de lo contrario solo Guarda',
                confirmText: 'Guardar',
                type: 'is-info',
                hasIcon: true,
                onConfirm: () => {
                    axios.post(link, {
                        puntos: this.puntos,
                        criterios: this.criterios,
                        criteriosTotal: this.criteriosTotal,
                        nivelesTotal: this.nivelesTotal,
                        criteriosTitulos: this.criteriosTitulos,
                        nivel: this.route_nivel,
                        asignatura: this.route_asignatura,
                        prueba: this.route_prueba,
                        informacion: this.informacion,
                        objetivos: this.objetivos
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'Lista de cotejo creada exitosamente',
                                type: 'is-success'
                            });
                        }
                        return this.$router.push({
                                    name: 'EvaluacionesMisEvaluaciones'                       
                                });
                        self.loadingGuardar = false;
                    }).catch((err) => {
                        self.loadingGuardar = false;
                        throw err
                    })
                }
            })
        },
        guardar_borrador() {
            if (!this.criterios[0][0]) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar al menos un criterio',
                    type: 'is-danger'
                })
            }

            let self = this;
            self.loadingGuardar = true;

            let link = '/api/creador/evaluacion/rubrica/update_borrador';

            if (!this.informacion) {
                link = '/api/creador/evaluacion/rubrica/store_borrador';
            }
            axios.post(link, {
                puntos: this.puntos,
                criterios: this.criterios,
                criteriosTotal: this.criteriosTotal,
                nivelesTotal: this.nivelesTotal,
                criteriosTitulos: this.criteriosTitulos,
                nivel: this.route_nivel,
                asignatura: this.route_asignatura,
                prueba: this.route_prueba,
                informacion: this.informacion,
                objetivos: this.objetivos
            }).then((res) => {
                if (res.data) {
                    this.$buefy.toast.open({
                        message: 'Lista de cotejo creada exitosamente',
                        type: 'is-success'
                    });
                }
                return this.$router.push({
                            name: 'EvaluacionesMisEvaluaciones'                       
                        });
                self.loadingGuardar = false;
            }).catch((err) => {
                self.loadingGuardar = false;
                throw err
            })
                
        },
        render() {
            this.isLoading = true
            for (var i = 0; i <= 30; i++) {
                this.$set(this.criterios, i, [
                    '', '', '', '', '', '', '', '', ''
                ]);
                this.$set(this.objetivos, i, {});
            }
            axios.get(`/api/creador/evaluacion/lista_de_cotejo/${this.route_prueba}`).then((res) => {
                this.informacion = res.data[0];
                if (this.informacion) {

                    let criterios = JSON.parse(this.informacion.criterios);
                    criterios.forEach((criterio, key) => {
                        let nuevo_criterio=criterio;
                        for(var i=criterio.length; i<= 9; i++) {
                            this.$set(nuevo_criterio, i, '');
                        }
                        this.$set(this.criterios, key, nuevo_criterio);

                    })
                    this.criteriosTotal = criterios.length;

                    let criteriosTitulos = JSON.parse(this.informacion.criterios_titulos);
                    criteriosTitulos.forEach((criterio, key) => {
                        this.$set(this.criteriosTitulos, [key], criterio);
                    })
                    this.nivelesTotal = criteriosTitulos.length;

                    let puntajes = JSON.parse(this.informacion.puntos);
                    puntajes.forEach((item, key) => {
                        this.$set(this.puntos, [key], item);
                    })

                    let objetivos = JSON.parse(this.informacion.objetivos);
                    objetivos.forEach((item, key) => {
                        this.$set(this.objetivos, [key], item);
                    })
                }


                this.isLoading = false;
            }).catch((err) => {
                throw err;
            });


        },
        agregarCriterio: function() {
            if (this.criteriosTotal >= 30) {
                return this.$buefy.toast.open({
                    message: 'No puedes crear más de 30 criterios',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.criteriosTotal++;
        },
        quitarCriterio: function() {
            if (this.criteriosTotal <= 1) {
                return this.$buefy.toast.open({
                    message: 'No puedes quitar más criterios',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.criteriosTotal--;
        },
        agregarNivel: function() {
            if (this.nivelesTotal >= 6) {
                return this.$buefy.toast.open({
                    message: 'No puedes crear más de 6 niveles',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.nivelesTotal++;
        },
        quitarNivel: function() {
            if (this.nivelesTotal <= 1) {
                return this.$buefy.toast.open({
                    message: 'No puedes quitar más niveles',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.nivelesTotal--;
        },
        getAsignatura() {
            axios.post('/api/asignaturas/asignatura', {
                asignatura_id: this.$route.params.asignatura
            }).then((res) => {
                this.infoAsignatura = res.data;
            }).catch((err) => {
                throw err
            });
        },
        getNivel() {
            axios.post('/api/niveles/nivel', {
                nivel_id: this.$route.params.nivel
            }).then((res) => {
                this.infoNivel = res.data;
            }).catch((err) => {
                throw err
            });
        },
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getAsignatura()
        this.getNivel()
        this.render()
    }
}
</script>
