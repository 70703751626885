<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Asignación</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Asignaturas</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">

        <div class="container">
            <div v-for="(programa_array, programa) in programas">

                <div class="columns">
                    <div class="column">
                        <h1 class="title is-5">{{programa}}</h1>
                    </div>
                </div>

                <div v-for="(madres_array, madres) in programa_array">

                    <div class="columns" v-if="madres_array.length > 1">
                        <div class="column">
                            <h1 class="title is-6">{{madres}}</h1>
                        </div>
                    </div>

                    <table class="table is-fullwidth">
                        <tbody>
                            <tr v-for="asignatura in madres_array">
                                <td> <span :class="'has-text-' + asignatura.imagen">
                                        <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                            <circle cx="50" cy="50" r="40"></circle>
                                        </svg>
                                    </span>
                                </td>
                                <td width="80%">{{ asignatura.nombre}}</td>
                                <td width="20%">
                                    <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                                    <router-link class="button is-small" :to="{ path: '/asignacion/educacion/'+asignatura.id}">
                                        Seleccionar
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr>
            </div>
        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</div>
</template>
<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "AsignacionAsignatura",
    metaInfo: {
        title: 'Seleccionar asgiantura'
    },
    components: {
        Colegios
    },
    data() {
        return {
            programas: [],         
            isLoading: false
        }
    },
    methods: {
        getCursos() {
            this.isLoading = true;
            axios.post('/api/asignaturas/usuario/configuracion/get', { nivel : 12 }).then((res) => {
                this.programas = res.data;
                this.isLoading = false;
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getCursos()
    }
}
</script>
