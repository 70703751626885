<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Aplicar</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns" v-if="curso">
            <div class="column is-10">
                <h1 class="title">Evaluación, {{evaluacion.prueba_tipo.nombre}} {{evaluacion.prueba_etapa.nombre}} {{evaluacion.asignatura.nombre}}, {{curso.nivel.nombre}} {{curso.letra}}</h1>
            </div>
            <div class="column is-2">
                <div class="is-pulled-right">
                    <b-button :type="'is-' + evaluacion.asignatura.imagen" icon-left="stop" icon-pack="fas" :loading="isloadingEvaluacion" @click="finalizar">Finalizar evaluación</b-button>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-12">
                <b-table :data="alumnos" :hoverable="true" :loading="isLoading" :default-sort="['name', 'asc']" bordered>
                    <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
                        {{ props.row.id }}
                    </b-table-column>
                    <b-table-column field="rut" label="RUT" sortable v-slot="props">
                        {{ props.row.rut }}
                    </b-table-column>
                    <b-table-column field="name" label="Nombres" sortable v-slot="props">
                        {{ props.row.name }}
                    </b-table-column>
                    <b-table-column field="estado" label="Estado" v-slot="props">
                        <span v-html="estado(props.row.asignacion_alumno.inicio, props.row.asignacion_alumno.termino)"></span>
                    </b-table-column>
                     <b-table-column field="progreso" label="Progreso" v-slot="props">
                        <b-progress :type="'is-' + evaluacion.asignatura.imagen" :value="props.row.asignacion_alumno.avance" show-value format="percent"></b-progress>
                    </b-table-column> 
                    <b-table-column label="Acciones" v-slot="props">
                        
                        <b-dropdown aria-role="list">
                            <button class="button is-default is-small" slot="trigger" slot-scope="{ active }">
                                <span>Acciones</span>
                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                            </button>
                            <b-dropdown-item aria-role="listitem" @click="tabular(props.row.id)"><i class="fas fa-table"></i> Tabular respuestas</b-dropdown-item>
                        </b-dropdown>
                    </b-table-column>
                    <b-table-column label="Evaluación" v-slot="props">
                        <b-field>
                                <b-switch 
                                @input="reabrir(props.row.asignacion_alumno.id)" 
                                v-model="props.row.asignacion_alumno.abierta">
                                
                                </b-switch>
                        </b-field>
                    </b-table-column>

                </b-table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "Cursos",
    metaInfo: {
        title: 'Aplicar evaluación'
    },
    components: {
        Colegios
    },
    data() {
        return {
            
            evaluacion: null,
            curso: null,
            alumnos: [],
            isLoading: false,
            isloadingEvaluacion: false
        }
    },
    methods: {
        tabular(alumno) {

            if (this.evaluacion.prueba_etapa_id == 35) {
                return this.$router.push({
                    name: 'TabulacionRubrica',
                    params: {
                        asignatura: this.$route.params.asignatura,
                        curso: this.$route.params.curso,
                        prueba: this.$route.params.prueba,
                        asignacion: this.$route.params.asignacion,
                        alumno: alumno
                    }
                });
            }

            if (this.evaluacion.prueba_etapa_id == 36) {
                return this.$router.push({
                    name: 'TabulacionRubrica',
                    params: {
                        asignatura: this.$route.params.asignatura,
                        curso: this.$route.params.curso,
                        prueba: this.$route.params.prueba,
                        asignacion: this.$route.params.asignacion,
                        alumno: alumno
                    }
                });
            }

            if (this.evaluacion.prueba_etapa_id == 37) {
                return this.$router.push({
                    name: 'TabulacionRubrica',
                    params: {
                        asignatura: this.$route.params.asignatura,
                        curso: this.$route.params.curso,
                        prueba: this.$route.params.prueba,
                        asignacion: this.$route.params.asignacion,
                        alumno: alumno
                    }
                });
            }

            return this.$router.push({
                name: 'Tabulacion',
                params: {
                    asignatura: this.$route.params.asignatura,
                    curso: this.$route.params.curso,
                    prueba: this.$route.params.prueba,
                    asignacion: this.$route.params.asignacion,
                    alumno: alumno
                }
            });
        },
        reabrir(alumno){
            axios.post('api/asignaciones/reabre',{
                id_asignacion: alumno
            })
            .then((res)=>{
                console.log(res.data);
            })
        }
        ,
        estado(inicio, termino) {
            if (termino) {
                return '<span class="tag is-success">Finalizado</span>'
            }
            if (inicio) {
                return '<span class="tag is-warning">En curso</span>'
            }
            return '<span class="tag">Sin iniciar</span>'
        },
        finalizar() {
            this.isloadingEvaluacion = true
            axios.post('/api/pruebas/finalizar', {
                    asignacion_id: this.$route.params.asignacion
                })
                .then((res) => {
                    if (res.data.status) {
                        this.isloadingEvaluacion = false
                        return this.$router.push({
                            name: 'AsignacionResultado',
                            params: {
                                asignacion: this.$route.params.asignacion
                            }
                        });
                    } else {
                        this.isloadingEvaluacion = false
                        this.$buefy.toast.open({
                            message: res.data.message,
                            type: 'is-warning'
                        })

                        if (res.data.next) {
                            return this.$router.push({
                                name: 'TabulacionRevisionConRubricas',
                                params: {
                                    asignatura: this.$route.params.asignatura,
                                    curso: this.$route.params.curso,
                                    prueba: this.$route.params.prueba,
                                    asignacion: this.$route.params.asignacion
                                }
                            });
                        }

                    }

                }).catch((err) => {
                    this.isloadingEvaluacion = false
                    throw err
                })
        },
        getInformacion() {
            axios.post('/api/pruebas/get', {
                    prueba: this.$route.params.prueba
                })
                .then((res) => {
                    this.evaluacion = res.data
                }).catch((err) => {
                    throw err
                })
        },
        getCurso() {
            axios.post('/api/cursos/curso', {
                curso_id: this.$route.params.curso
            }).then((res) => {
                this.curso = res.data;
            }).catch((err) => {
                throw err
            })
        },
        getAlumnos() {
            this.isLoading = true;
            axios.post('/api/cursos/get/alumnos/en_curso', {
                curso_id: this.$route.params.curso,
                asignacion_id: this.$route.params.asignacion
            }).then((res) => {
                this.alumnos = res.data.users;
                this.isLoading = false;
            }).catch((err) => {
                throw err
            })
        }

    },
    mounted() {
        this.getInformacion()
        this.getCurso()
        this.getAlumnos()
    }
}
</script>
