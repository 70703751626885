<template >
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/configuracion/usuarios">Configuracion de Docente</router-link>
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Docente</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
            <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Docente</h1>
                <p>Completa el formulario para crear o editar un docente</p>
            </div>
        </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Nombres">
                        <b-input v-model="name" ref="name" placeholder="Ingrese nombres y apellidos"></b-input>
                    </b-field>
                    <b-field label="Rut">
                        <b-input v-model="rut" ref="rut" placeholder="Ingrese RUT del usuario" maxlength="11"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Email">
                        <b-input v-model="email" ref="email" placeholder="Ingrese email del usuario (o RUT si es estudiante)"></b-input>
                    </b-field>
                    <b-field label="Fono">
                        <b-input v-model="phone" placeholder="Ingrese fono del usuario (opcional)" maxlength="9"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p>La clave por defecto de los docentes creados es: <b>aeduc2021</b></p>
                    <b-button v-if="id" type="is-primary" @click="crear" :loading="loadingStore">Editar usuario</b-button>
                    <b-button v-else type="is-primary" @click="crear" :loading="loadingStore">Crear usuario</b-button>
                </div>
            </div>
    </div>
    </div>
</template>
<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    components: {
        Colegios
    },
    data() {
        return {
            id: this.$route.params.id,
            rol: null,
            name: null,
            email: null,
            rut: null,
            phone: null,
            password: null,
            columns: [],
            checkboxGroup: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            loadingStore: false,
            page: 1,
            perPage: 20,
            buscar_nombre: null,
            buscar_rbd: null,
            periodo : 2021
        }
    },
    methods: {
        crear() {
            let self = this

            if (!this.email) {
                this.$buefy.toast.open({
                    message: `Debes ingresar un email`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.email.focus();
                return
            }

            if (!this.name) {
                this.$buefy.toast.open({
                    message: `Debes ingresar los nombres`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.name.focus();
                return
            }
            if (!this.rut) {
                this.$buefy.toast.open({
                    message: `Debes ingresar el RUT`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.rut.focus();
                return
            }

            self.loadingStore = true;

            var params = {
                params: this.$data
            };

            let method = 'storedocente';
            if (this.id) {
                method = 'updatedocente';
            }

            axios.post('/api/usuarios/' + method, params)
                .then(response => {
                    self.loadingStore = false;
                    if (response.data.status) {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'No se pudo crear el usuario',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    self.loadingStore = false;
                    throw error
                });

        },
        getUsuario(){
            if (this.id) {
                let params = {
                    id: this.id
                }
                axios.post('/api/usuarios/get', params)
                    .then(response => {
                        if(response.data == "false"){
                            window.location.href = '/configuracion/usuarios';
                        }
                        else{
                            let data = response.data                                                  
                            this.rol = data.rol_id
                            this.email = data.email
                            this.name = data.name
                            this.rut = data.rut
                            this.phone = data.phone
                        }
                     
                      
                       
                    })
                    .catch(error => {
                        this.$buefy.toast.open({
                            message: 'No se pudo encontrar al usuario',
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                        throw error
                    });
            }
        },
    },
    mounted() {
        this.getUsuario()
    }
}
</script>
