import Vue from "vue";
import VueProgressBar from 'vue-progressbar'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";


const options = {
    color: '#2496f5',
    failedColor: '#874b4b',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    location: 'top',
    inverse: false
}

Vue.use(VueProgressBar, options)
Vue.config.productionTip = false;

store.dispatch('auth/me').then((status) => {
    if (status) {
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount("#app")
    }
})
