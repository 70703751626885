<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link :to="'/aplicar/evaluacion/en_curso/' + asignatura + '/' + curso + '/' + prueba + '/' + asignacion ">Aplicar</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Tabular respuestas</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">

        <div class="columns" v-if="cursoInfo">
            <div class="column">
                <h1 class="title">Evaluación, {{evaluacionInfo.prueba_tipo.nombre}} {{evaluacionInfo.prueba_etapa.nombre}} {{evaluacionInfo.asignatura.nombre}}, {{cursoInfo.nivel.nombre}} {{cursoInfo.letra}}</h1>

                <div class="columns">
                    <div class="column">
                        <h2 class="subtitle mt-2">Tabulación de respuestas para, {{alumnoInfo.name}}</h2>
                    </div>
                    <div class="column">
                        <div class="is-pulled-right">
                            <b-button type="is-primary" @click="guardar" :loading="loadingGuardar">Guardar respuestas</b-button>
                        </div>
                    </div>
                </div>

                <b-table :data="preguntasInfo" :hoverable="true" :loading="isLoading" bordered>
                    <b-table-column field="orden" label="Nº" width="40" v-slot="props">
                        {{ props.row.orden }}
                    </b-table-column>
                    <b-table-column field="pregunta" label="Pregunta" width="50%" v-slot="props">
                        <span v-html="props.row.pregunta" style="font-size:12px"></span>
                    </b-table-column>
                    <b-table-column label="Respuestas" v-slot="props">

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field" v-for="respuesta in props.row.respuestas" v-bind:key="respuesta.id">
                                    <div v-if="!props.row.puntos">
                                        <b-radio v-if="respuesta.respuesta != 'NO' && respuesta.respuesta != '<p>NO</p>'" v-model="respuestas[props.row.orden]" :name="'r' + respuesta.pregunta_id" :native-value="respuesta.letra">
                                            {{respuesta.letra}}
                                        </b-radio>
                                    </div>
                                </div>
                                <div class="field" v-if="props.row.puntos">
                                    <span v-if="!props.row.revisiondocente" class="tag is-warning">Falta revisar</span>
                                    <span v-if="props.row.revisiondocente" class="tag is-success">Revisado</span>
                                </div>
                            </div>
                        </div>

                    </b-table-column>
                    <b-table-column label="Acciones" width="20" v-slot="props" centered>
                        <b-button v-if="!props.row.puntos" icon-left="sync-alt" icon-pack="fas" type="is-small" @click="limpiar(props.row.orden)"></b-button>

                        <b-button v-if="props.row.puntos" icon-left="edit" icon-pack="fas" type="is-small" @click="evaluar()">Evaluar</b-button>

                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
export default {
    name: "Tabulacion",
    metaInfo: {
        title: 'Tabular respuestas'
    },
    components: {
        Colegios
    },
    data() {
        return {
            asignatura: this.$route.params.asignatura,
            curso: this.$route.params.curso,
            prueba: this.$route.params.prueba,
            asignacion: this.$route.params.asignacion,
            alumno: this.$route.params.alumno,
            alumnoInfo: [],
            evaluacionInfo: null,
            cursoInfo: null,
            preguntasInfo: [],
            respuestas: [],
            loadingGuardar: false
        }
    },
    methods: {
        limpiar(orden) {
            this.$set(this.respuestas, orden, null);
        },
        evaluar() {
            return this.$router.push({
                name: 'TabulacionRevisionConRubricas',
                params: {
                    asignatura: this.$route.params.asignatura,
                    curso: this.$route.params.curso,
                    prueba: this.$route.params.prueba,
                    asignacion: this.$route.params.asignacion,
                    alumno: this.$route.params.alumno
                }
            });
        },
        guardar() {
            let self = this
            self.loadingGuardar = true
            axios.post('/api/tabular/alternativas/store', {
                    asignacion: this.$route.params.asignacion,
                    alumno: this.$route.params.alumno,
                    respuestas: this.respuestas
                })
                .then((res) => {
                    if (res.data) {
                        return this.$router.push({
                            name: 'EvaluacionEnCurso',
                            params: {
                                asignatura: this.$route.params.asignatura,
                                curso: this.$route.params.curso,
                                prueba: this.$route.params.prueba,
                                asignacion: this.$route.params.asignacion
                            }
                        });
                    }
                    self.loadingGuardar = false
                }).catch((err) => {
                    self.loadingGuardar = false
                    throw err
                })
        },
        getInformacion() {
            axios.post('/api/pruebas/get', {
                    prueba: this.$route.params.prueba
                })
                .then((res) => {
                    this.evaluacionInfo = res.data
                }).catch((err) => {
                    throw err
                })
        },
        getCurso() {
            axios.post('/api/cursos/curso', {
                curso_id: this.$route.params.curso
            }).then((res) => {
                this.cursoInfo = res.data;
            }).catch((err) => {
                throw err
            })
        },
        getAlumno() {
            axios.post('/api/usuarios/get', {
                id: this.alumno
            }).then((res) => {
                this.alumnoInfo = res.data;
            }).catch((err) => {
                throw err
            });
        },
        getPreguntas() {
            let self = this;
            this.isLoading = true;
            axios.post('/api/asignaciones/preguntas', {
                asignacion_id: this.$route.params.asignacion,
                alumno_id: this.$route.params.alumno
            }).then((res) => {
                this.preguntasInfo = res.data;
                this.preguntasInfo.forEach((pregunta) => {
                    pregunta.respuestas.forEach((respuesta) => {
                        if (respuesta.check) {
                            this.$set(self.respuestas, pregunta.orden, respuesta.letra);
                        }

                    })
                })
                this.isLoading = false;
            }).catch((err) => {
                throw err
            });
        }
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getInformacion()
        this.getCurso()
        this.getAlumno()
        this.getPreguntas()
    }
}
</script>
