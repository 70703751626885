<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Mis evaluaciones</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Evaluaciones</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <b-field label="Asignaturas">
                    <b-select v-model="asignatura" expanded placeholder="Selecciona una asignatura" @input="loadAsyncData()">
                        <option value="null">Todas</option>
                        <option v-for="asignatura in asignaturas" :value="asignatura.id" :key="asignatura.id">
                            {{ asignatura.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Niveles">
                    <b-select v-model="nivel" placeholder="Selecciona un nivel" expanded @input="loadAsyncData()">
                        <option value="null">Todos</option>
                        <option v-for="nivel in niveles" :value="nivel.id" :key="nivel.id">
                            {{ nivel.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                    aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                    <b-table-column field="id" label="ID" sortable v-slot="props">
                        {{ props.row.id }}
                    </b-table-column>

                    <b-table-column label="Evaluación" v-slot="props">
                        {{ props.row.evaluacion_tipo.nombre }}
                    </b-table-column>

                    <b-table-column field="nombre" label="Nombre" sortable v-slot="props">
                        {{props.row.nombre}}
                    </b-table-column>

                    <b-table-column field="estado" label="Estado" sortable v-slot="props">
                        <span v-html="getEstado(props.row.estado)" />
                    </b-table-column>

                    <b-table-column field="asignatura_id" label="Asignatura" sortable v-slot="props">
                        <span :class="'tag is-' + props.row.asignatura.imagen + ''">{{ props.row.asignatura.nombre }}</span>
                    </b-table-column>
                    
                    
                    <b-table-column field="prueba_tipo_id" label="Tipo" sortable v-slot="props">
                        {{props.row.prueba_tipo.nombre}}
                    </b-table-column>
                    <b-table-column field="prueba_etapa_id" label="Etapa" sortable v-slot="props">
                        {{props.row.prueba_etapa.nombre}}
                    </b-table-column>


                    <b-table-column label="Preguntas" v-slot="props">
                        {{ (props.row.preguntas).length }}
                    </b-table-column>

                    <b-table-column field="nivel_id" label="Nivel" sortable v-slot="props" >
                        {{ props.row.nivel.nombre }}
                    </b-table-column>

                    <!-- <b-table-column label="Asignaciones" v-slot="props">
                        <ul v-for="asignacion in props.row.asignaciones" v-bind:key="asignacion.id" style="list-style-type:disc;">
                            <li class="is-size-7">{{ asignacion.name }} | {{ getDate(asignacion.created_at) }}</li>
                        </ul>
                    </b-table-column> -->

                    <b-table-column label="Acciones" v-slot="props">
                        <div class="buttons">
                            <b-button v-if="props.row.estado==0" icon-left="edit" icon-pack="fas" type="is-small" outlined @click="editar(props.row.id, props.row.asignatura_id, props.row.nivel_id, props.row.evaluacion_tipo_id)">Continuar edición</b-button>

                            <b-button v-if="props.row.estado==1" icon-left="file-pdf" icon-pack="fas" type="is-small" outlined @click="pdf(props.row.id ,props.row.nivel_id)">Descargar</b-button>

                        </div>
                    </b-table-column>
                    <b-table-column label="Eliminar" v-slot="props">
                        <div class="buttons">
                           
                            <b-button  icon-left="trash" icon-pack="fas" type="is-small is-danger " outlined @click="eliminar(props.row.id)"></b-button>

                        </div>
                    </b-table-column>

                    <template slot="empty">
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>No se encontraron evaluaciones</p>
                            </div>
                        </section>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "EvaluacionesMisEvaluaciones",
    metaInfo: {
        title: 'Mis evaluaciones'
    },
    components: {
        Colegios
    },
    data() {
        return {
            data: [],
            loading: false,
            total: 0,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            asignaturas: [],
            niveles: [],
            asignatura: null,
            nivel: null,
            search_fechas: null
        }
    },
    methods: {
        pdf(prueba, nivel) {
            window.open(process.env.VUE_APP_API_URL + '/prueba/' + prueba +'/'+ nivel);
        },
        getEstado(args){
            if (args == 0){
                return '<span class="tag is-light">Borrador</span>';
            }
            if (args == 1){
                return '<span class="tag is-success">Cerrada</span>';
            }
        },
        getDate(date) {
            return moment(date).locale('es').format('LLL')
        },
        formatter(d) {
            return d[0].toLocaleDateString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }) + ' - ' + d[1].toLocaleDateString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })
        },
        editar(prueba, asignatura, nivel, tipo) {

            if (tipo == 1) {
                return this.$router.push({
                    name: 'CrearEvaluacionPrueba',
                    params: {
                        nivel: nivel,
                        asignatura: asignatura,
                        prueba: prueba,
                        tipo: tipo
                    }
                });
            }else if(tipo == 2 || tipo == 3 || tipo == 4) {
                return this.$router.push({
                    name: 'CrearEvaluacionRubrica',
                    params: {
                        nivel: nivel,
                        asignatura: asignatura,
                        prueba: prueba,
                        tipo: tipo
                    }
                });
            }

        },
        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar evaluacion',
                message: '¿Estás seguro que deseas eliminar la evaluacion?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/pruebas/eliminar', {
                        prueba: id
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'evaluacion eliminada correctamente',
                                type: 'is-success'
                            })
                            this.loadAsyncData()
                        }
                    }).catch((err) => {
                        throw err
                    })
                }
            })
        },
        formatDateTime(fecha) {
            return moment(fecha).locale('es').format('DD-MM-YYYY, h:mm:ss a');
        },
        // formatDate(fecha) {
        //     return moment(fecha).locale('es').format('LL');
        // },
        getAsignaturas() {
            axios.get('/api/ramos/getAll').then((res) => {
                res.data.forEach((item) => {
                    this.asignaturas.push(item.asignatura)
                })
            }).catch((err) => {
                throw err
            })
        },
        getNiveles() {
            axios.get('/api/niveles/get').then((res) => {
                this.niveles = res.data
            }).catch((err) => {
                throw err
            })
        },
        /*
         * Load async data
         */
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `asignatura=${this.asignatura}`,
                `nivel=${this.nivel}`,
            ].join('&')

            this.loading = true
            axios.get(`/api/pruebas/creadas/list?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
    },
    mounted() {
        this.loadAsyncData()
        this.getAsignaturas()
        this.getNiveles()
    }
}
</script>
