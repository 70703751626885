<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li><li>
                                <router-link to="/evaluaciones/finalizadas">Evaluaciones Finalizadas</router-link>
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Remedial</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>

        <div class="box">
            <div class="columns">
                <div class="column is-10">
                    <h1 class="title" v-if="curso">
                        Evaluación, {{ evaluacion.prueba_tipo.nombre }} {{ evaluacion.prueba_etapa.nombre }}
                        {{ evaluacion.asignatura.nombre }}, {{ curso.nivel.nombre }} {{ curso.letra }}
                    </h1>
                </div>
                <div class="column is-2">
                </div>
            </div>
            <div class="columns">
                <div class="column is-12">
                    <b-table :data="alumnos" :loading="isLoading" :default-sort="['name', 'asc']" bordered>
                        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>
                        <b-table-column field="rut" label="RUT" sortable v-slot="props">
                            {{ props.row.rut }}
                        </b-table-column>
                        <b-table-column field="name" label="Nombres" sortable v-slot="props">
                            {{ props.row.name }}
                        </b-table-column>
                        <b-table-column field="Remedial" label="Remedial" sortable v-slot="props">
                            <b-button class="is-danger" outlined @click="generar_remedial(props)"> 
                                Generar pdf 
                                <b-icon></b-icon>
                                <i class="fas fa-file-pdf"></i>
                            </b-button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../components/Colegios.vue";
import axios from "axios";

export default {
    name: "Cursos",
    metaInfo: {
        title: "Aplicar evaluación",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            evaluacion: null,
            curso: null,
            alumnos: [],
            isLoading: false,
            isloadingEvaluacion: false,
            asignacion_id: this.$route.params.asignacion_id,

        };
    },
    methods: {   
        getInformacion() {
            axios
                .post("/api/pruebas/get", {
                    prueba: this.$route.params.prueba_id,
                })
                .then((res) => {
                    this.evaluacion = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getCurso() {
            axios
                .post("/api/cursos/curso", {
                    curso_id: this.$route.params.curso_id,
                })
                .then((res) => {
                    this.curso = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getAlumnos() {
            this.isLoading = true;
            axios
                .post("/api/cursos/get/alumnos", {
                    curso_id: this.$route.params.curso_id,
                })
                .then((res) => {
                    this.alumnos = res.data.users;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
        generar_remedial(alumno){
            //this.asignacion_id = this.$route.params.asignacion_id;
            window.open(process.env.VUE_APP_API_URL + '/remedial/' + alumno.row.id + '/'+ this.asignacion_id);
        }
    },

    mounted() {
        this.getInformacion();
        this.getCurso();
        this.getAlumnos();
    },
};
</script>