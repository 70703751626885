<template>
<div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title">Pregunta {{pregunta}}</p>
        <div class="is-pulled-right">
            <b-button rounded type="is-primary" :loading="loadingGuardar" @click="guardar">Guardar</b-button>
        </div>
        <div class="is-pulled-right ml-2">
            <b-button rounded @click="cancelar">Cancelar</b-button>
        </div>
    </header>
    <section class="modal-card-body">
        <div class="columns">
            <div class="column is-3">

                <b-button expanded @click="getObjetivos">+ Objetivos de Aprendizaje</b-button>

                <div v-if="Object.keys(objetivo).length !== 0">
                    <div class="notification">
                        <p class="title is-5">{{objetivo.codigo}} </p>
                        <p>{{objetivo.descripcion | truncate(100)}}</p>
                    </div>
                </div>

                <b-button :disabled="Object.keys(objetivo).length === 0" expanded class="mt-2" @click="getIndicadores">+ Indicador</b-button>

                <div v-if="Object.keys(indicador).length !== 0">
                    <div class="notification">
                        <p>{{indicador.descripcion | truncate(100)}}</p>
                    </div>
                </div>

                <b-button expanded class="mt-2" @click="getHabilidades">+ Habilidad</b-button>

                <div v-if="Object.keys(habilidad).length !== 0">
                    <div class="notification">
                        <p class="title is-5">{{habilidad.codigo}} </p>
                        <p>{{habilidad.descripcion | truncate(100)}}</p>
                    </div>
                </div>

                <hr>
                <b-field label="Dificultad">
                    <b-select v-model="dificultad" placeholder="Selecciona la dificultad" expanded>
                        <option value="1">Inicial</option>
                        <option value="2">Intermedia</option>
                        <option value="3">Avanzada</option>
                    </b-select>
                </b-field>
                <b-field label="Puntaje">
                    <b-input type="number" v-model="puntaje"></b-input>
                </b-field>
            </div>
            <div class="column is-9">
                <vue-editor v-model="enunciado"
                :editorOptions="configEnunciado"></vue-editor>
                <br>
                <div class="columns">
                    <div class="column is-1">
                        <b-radio-button v-model="alternativaButton"
                             native-value="A">
                             A
                         </b-radio-button>
                    </div>
                    <div class="column is-11">
                        <vue-editor
                        :class="hideToolbarA ? 'hide-toolbar' : ''"
                        @blur="onEditorBlurA($event)"
                        @focus="onEditorFocusA($event)"
                        v-model="alternativa1" :editorOptions="configRespuesta"></vue-editor>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-1">
                        <b-radio-button v-model="alternativaButton"
                             native-value="B">
                             B
                         </b-radio-button>
                    </div>
                    <div class="column is-11">
                        <vue-editor
                        :class="hideToolbarB ? 'hide-toolbar' : ''"
                        @blur="onEditorBlurB($event)"
                        @focus="onEditorFocusB($event)"
                        v-model="alternativa2" :editorOptions="configRespuesta"></vue-editor>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-1">
                        <b-radio-button v-model="alternativaButton"
                             native-value="C">
                             C
                         </b-radio-button>
                    </div>
                    <div class="column is-11">
                        <vue-editor
                        :class="hideToolbarC ? 'hide-toolbar' : ''"
                        @blur="onEditorBlurC($event)"
                        @focus="onEditorFocusC($event)"
                        v-model="alternativa3" :editorOptions="configRespuesta"></vue-editor>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-1">
                        <b-radio-button v-model="alternativaButton"
                             native-value="D">
                             D
                         </b-radio-button>
                    </div>
                    <div class="column is-11">
                        <vue-editor
                        :class="hideToolbarD ? 'hide-toolbar' : ''"
                        @blur="onEditorBlurD($event)"
                        @focus="onEditorFocusD($event)"
                        v-model="alternativa4" :editorOptions="configRespuesta"></vue-editor>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-1">
                        <b-radio-button v-model="alternativaButton"
                             native-value="E" >
                             E
                         </b-radio-button>
                    </div>
                    <div class="column is-11">
                        <vue-editor
                        :class="hideToolbarE ? 'hide-toolbar' : ''"
                        @blur="onEditorBlurE($event)"
                        @focus="onEditorFocusE($event)"
                        v-model="alternativa5" :editorOptions="configRespuesta" ></vue-editor>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-2">
                        Agregar salto de linea
                    </div>
                    <div class="column is-10">
                        <b-checkbox
                            v-model="page_break"
                        ></b-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import Objetivos from '../../../components/crear/evaluacion/Objetivos.vue'
import Indicadores from '../../../components/crear/evaluacion/Indicadores.vue'
import Habilidades from '../../../components/crear/evaluacion/Habilidades.vue'

import {
    VueEditor,
    Quill
} from "vue2-editor";
import {
    ImageDrop
} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import axios from 'axios';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
    props: ['route_nivel', 'route_asignatura', 'route_prueba', 'pregunta', 'informacion', 'grupo'],
    name: 'CrerEvaluacionPreguntaAlernativa',
    components: {
        VueEditor
    },
    data() {
        return {
            configEnunciado: {
                readOnly: false,
                placeholder: 'Ingresa una pregunta',
                modules: {
                    imageDrop: true,
                    imageResize: {},
                    toolbar: {
                        container: [
                            [{
                                'font': []
                            }],
                            [{
                                header: [1, 2, false]
                            }],
                            ['bold', 'italic', 'underline'],
                            [{
                                'color': []
                            }, {
                                'background': []
                            }], // dropdown with defaults from theme
                            [{
                                align: []
                            }],
                            [{
                                'list': 'ordered'
                            }, {
                                'list': 'bullet'
                            }],
                            ['link', 'image', 'formula'],
                            ['clean']
                        ]
                    }
                }
            },
            configRespuesta: {
                readOnly: false,
                placeholder: 'Ingresa una respuesta',
                modules: {
                    imageDrop: true,
                    imageResize: {},
                    toolbar: {
                        container: [
                            [{
                                'font': []
                            }],
                            [{
                                header: [1, 2, false]
                            }],
                            ['bold', 'italic', 'underline'],
                            [{
                                'color': []
                            }, {
                                'background': []
                            }], // dropdown with defaults from theme
                            [{
                                align: []
                            }],
                            [{
                                'list': 'ordered'
                            }, {
                                'list': 'bullet'
                            }],
                            ['link', 'image', 'formula'],
                            ['clean']
                        ]
                    }
                }
            },
            puntaje: 1,
            enunciado: null,
            page_break:false,
            alternativa1: null,
            alternativa2: null,
            alternativa3: null,
            alternativa4: null,
            alternativa5: null,
            dificultad: 1,
            objetivo: {},
            indicador: {},
            habilidad: {},
            loadingGuardar: false,
            alternativaButton: false,
            hideToolbarEnunciado: true,
            hideToolbarA: true,
            hideToolbarB: true,
            hideToolbarC: true,
            hideToolbarD: true,
            hideToolbarE: true,
        }
    },
    methods: {
        onEditorBlurEnunciado(editor) {
            this.hideToolbarEnunciado = true
        },
        onEditorFocusEnunciado(editor) {
            this.hideToolbarEnunciado = false
        },
        onEditorBlurA(editor) {
            this.hideToolbarA = true
        },
        onEditorFocusA(editor) {
            this.hideToolbarA = false
        },
        onEditorBlurB(editor) {
            this.hideToolbarB = true
        },
        onEditorFocusB(editor) {
            this.hideToolbarB = false
        },
        onEditorBlurC(editor) {
            this.hideToolbarC = true
        },
        onEditorFocusC(editor) {
            this.hideToolbarC = false
        },
        onEditorBlurD(editor) {
            this.hideToolbarD = true
        },
        onEditorFocusD(editor) {
            this.hideToolbarD = false
        },
        onEditorBlurE(editor) {
            this.hideToolbarE = true
        },
        onEditorFocusE(editor) {
            this.hideToolbarE = false
        },
        setObjetivo(args) {
            this.objetivo = args;
            this.indicador = {};
        },
        setIndicador(args) {
            this.indicador = args;
        },
        setHabilidad(args) {
            this.habilidad = args;
        },
        getObjetivos() {
            this.$buefy.modal.open({
                parent: this,
                component: Objetivos,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba
                },
                events: {
                    setObjetivos: this.setObjetivo
                }
            })
        },
        getIndicadores() {
            this.$buefy.modal.open({
                parent: this,
                component: Indicadores,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba,
                    objetivo: this.objetivo.id
                },
                events: {
                    setIndicador: this.setIndicador
                }
            })
        },
        getHabilidades() {
            this.$buefy.modal.open({
                parent: this,
                component: Habilidades,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba
                },
                events: {
                    setHabilidad: this.setHabilidad
                }
            })
        },
        cancelar() {
            this.$emit('close')
        },
        render() {
            if (this.informacion) {
                console.log(this.informacion);
                this.enunciado = this.informacion.pregunta;
                this.objetivo = this.informacion.objetivo;
                this.indicador = this.informacion.indicador ? this.informacion.indicador : {};
                this.habilidad = this.informacion.habilidad ? this.informacion.habilidad : {};
                this.puntaje = this.informacion.puntaje;
                this.dificultad = this.informacion.dificultad_id;
                this.alternativaButton = this.informacion.clave;
                this.page_break = this.informacion.page_break;
                this.informacion.respuestas.forEach((item, key) => {
                    if(key == 0) {
                        this.alternativa1 = item.respuesta
                    }
                    if(key == 1) {
                        this.alternativa2 = item.respuesta
                    }
                    if(key == 2) {
                        this.alternativa3 = item.respuesta
                    }
                    if(key == 3) {
                        this.alternativa4 = item.respuesta
                    }
                    if(key == 4) {
                        this.alternativa5 = item.respuesta
                    }
                })
            }
        },
        guardar() {

            if (Object.keys(this.objetivo).length === 0) {
                return this.$buefy.toast.open({
                    message: 'Debe seleccionar un objetivo de aprendizaje',
                    type: 'is-danger'
                })
            }

            if (!this.enunciado) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar una pregunta',
                    type: 'is-danger'
                })
            }
            if (!this.alternativa1) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar una respuesta',
                    type: 'is-danger'
                })
            }

            if (!this.alternativaButton) {
                return this.$buefy.toast.open({
                    message: 'Debe seleccionar una respuesta correcta',
                    type: 'is-danger'
                })
            }

            let self = this;
            self.loadingGuardar = true;

            let link = '/api/creador/evaluacion/pregunta/alternativa/update';

            if (!this.informacion) {
                link = '/api/creador/evaluacion/pregunta/alternativa/store';
            }

            axios.post(link, {
                enunciado: this.enunciado,
                dificultad: this.dificultad,
                objetivo: this.objetivo,
                indicador: this.indicador,
                habilidad: this.habilidad,
                nivel: this.route_nivel,
                asignatura: this.route_asignatura,
                prueba: this.route_prueba,
                informacion: this.informacion,
                puntaje: this.puntaje,
                alternativa1: this.alternativa1,
                alternativa2: this.alternativa2,
                alternativa3: this.alternativa3,
                alternativa4: this.alternativa4,
                alternativa5: this.alternativa5,
                alternativaButton: this.alternativaButton,
                page_break: this.page_break,
                grupo: this.grupo
            }).then((res) => {
                if (res.data) {
                    this.$buefy.toast.open({
                        message: '¡Pregunta creada!',
                        type: 'is-success'
                    });
                    this.$emit('getPreguntas')
                    this.$emit('close');
                }
                self.loadingGuardar = false;
            }).catch((err) => {
                self.loadingGuardar = false;
                throw err
            })
        },
    },
    filters: {
        truncate(value, length) {
            if (!value) {
                return value;
            }
            return value.length > length ? value.substr(0, length) + '...' : value
        }
    },
    mounted() {
        this.render()
    }
}
</script>

<style>
.ql-editor{
    min-height:50px !important;
}
.hide-toolbar .ql-toolbar {
  display: none;
}
.hide-toolbar .ql-container {
    border: 1px !important;
    border-color: #e3e3e3 !important;
    border-style: solid !important;
}
</style>
