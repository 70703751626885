<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Asignación</router-link>
                        </li>
                        <li>
                            <router-link to="/asignacion/asignatura">Asignaturas</router-link>
                        </li>
                        <li>
                            <router-link :to="{ path: '/asignacion/educacion/'+asignatura}">Educación</router-link>
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Cursos</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title is-5 has-text-primary">
                    Seleccione un nivel de educación 
                </h1>
                <b-select name='select' id="select" v-model="opciones" @change.native="onChange($event)" class="form-control column is-full">
                    <option value="3">Prekinder</option>
                    <option value="4">Kinder</option>
                    <option value="1">Educación Básica</option>
                    <option value="2">Educación Media</option>
                </b-select>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <table class="table is-fullwidth is-bordered is-striped">
                    <tbody>


                        <tr v-for="(datos, nombre) in cursos" :key="nombre">
                             <td width="200" class="has-text-centered has-background-light"><strong>{{ nombre }}</strong></td> <span></span>
                            <td v-for="dato in datos" :key="dato">
                                <router-link :to="{ path: '/asignacion/cursos/' + dato.nivel_id + '/' + dato.curso_id + '/' + asignatura}">
                                    <b-button class="aeduc-button-curso">
                                        {{ dato.letra }}
                                    </b-button>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>
</template>
<script>
import Colegios from "../../components/Colegios.vue";
import axios from "axios";

export default {
    name: "AsignacionCurso",
    metaInfo: {
        title: "Seleccionar curso",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            cursos: [],
            letras: [],
            isLoading: false,
            asignatura:this.$route.params.asignatura,
            opciones: this.$route.params.educacion ?? 1,

        };
    },
    methods: {
        getCursos(value) {
            let params = {
                asignatura:this.$route.params.asignatura,
                educacion: value,
            };

            this.isLoading = true;
            axios
                .post("/api/cursos/cursos_orden", params)
                .then((res) => {
                    console.log(res.data);
                    this.cursos = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },

        onChange(event) {
            this.getCursos(event.target.value);
        },
        selected() {
            this.getCursos(this.$route.params.educacion ?? 1);
        }
    },

    mounted() {
        this.selected();
    },
};
</script>

<style scoped>
.aeduc-button-curso {
    border-radius: 50px;
    transition: background 1s ease-out 10ms;
    font: Montserrat;
    opacity: 1;
}

.aeduc-button-curso:hover {
    color: white;
    background-color: #129ef7;
    transition: background 1s ease-out 10ms;
}
</style>
