<template>
<div class="container">
<div class="box">
    
    <div class="column">
        <div v-if="asignacione" class="box">
            <h4 v-if="asignacione.prueba.nombre" class="title is-5">Evaluación, {{asignacione.prueba.nombre}}</h4>
            <h4 v-else class="title is-5">Evaluación, {{asignacione.prueba.prueba_tipo.nombre}} ({{asignacione.prueba.prueba_etapa.nombre}})</h4>
            <p>{{asignacione.asignatura.nombre}}</p>
            <p>Profesor: {{asignacione.user.name}}</p>
            <p>Curso: {{asignacione.curso.nivel.nombre}} {{asignacione.curso.letra}}</p>
        </div>
    </div>
    <div class="columns">
        <div class="column is-6">
            <div class="box is-centered">
                <section>
                <b-upload v-model="file" class="file-label">
                                    <span class="file-cta">
                                        <span class="file-label">Seleccione archivo</span>
                                    </span>
                                    <span class="file-name" v-if="file">
                                        {{ file.name }}
                                    </span>
                    </b-upload>
                    <b-button type="is-primary" class="ml-2" icon-left="fas fa-upload" icon-pack="fas" @click="carga" >Cargar respuestas</b-button>
                </section>
                <br>
                <div id="mensajes" v-if="sinerrores == 0 && estado!='ok'" class="column">
                    <h3  class="has-text-centered" style="color:#00c181;font-weight:600">Sin errores, puede aplicar las respuestas</h3> 
                </div>
                <div id="mensajes" v-else-if="errores && sinerrores==1 && estado!='ok'" class="column">
                    <h3 class="has-text-centered" style="color:#ff6c2f;font-weight:600">Corrija los siguientes errores para poder aplicar las respuestas</h3>
                </div>
                <div id="mensajes" v-else-if="estado!='ok'">
                    <h3 class="has-text-centered" style="color:#00b4bc;font-weight:600">Suba el archivo de respuestas</h3> 
                </div>
                <div id="mensajes" v-if="estado=='ok'" class="column has-text-centered">
                    <h3 class="has-text-centered" style="color:#00c181;font-weight:600">Se han aplicado las respuestas</h3>
                    <br>
                    <b-button :type="'is-success'" name="button" outlined @click="ir_evaluacion(asignacione.asignatura_id, asignacione.curso_id, asignacione.prueba_id, asignacione.id)">Ir a la evaluación</b-button>
                    
                </div>
            </div>
            <div class="box is-centered">
                <div class="columns">
                    Verifique el orden en el que vienen las columnas del CSV, puede arrastrar y soltar para modificar su orden, para guardar, presione el botón
                </div>
                <div class="columns" style="display: flex;justify-content: center;">
                    <div> 
                        <b-table
                        :data="dataCustomCSV"
                        :columns="columns"
                        draggable
                        @dragstart="dragstart"
                        @drop="drop"
                        @dragover="dragover"
                        @dragleave="dragleave">
                        </b-table>
                    </div> 
                </div>
                <div class="columns" style="display: flex;justify-content: center;">
                    <div>
                        <b-button :type="'is-success'" name="button" outlined @click="setOrdenCsv()">Guardar Orden</b-button> 
                    </div>
                </div>
                <div class="columns" style="display: flex;justify-content: center;" v-if="mensaje">
                    <h4 class="title is-5 has-text-centered">{{mensaje}}</h4>
                </div>
                
            </div>
        </div>
        <div v-if="errores && sinerrores==1" class="column is-6">
            <div class="box">
                <div class="table-container">
                    <table class="table is-bordered">
                        <thead>
                            <tr>
                                <th class="has-text-centered" >
                                    Diagnóstico de incidencias
                                </th>
                                <th class="has-text-centered" >
                                    Fila
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(error,key) in errores">
                                <td>
                                    {{error}}
                                </td>
                                <td>
                                    {{key}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            
                                   
        </div>
        
        <div v-else>
            <div v-if="sinerrores == 0" class="column">
                    <div class="box has-text-centered">
                        
                        <b-button type="is-primary" icon-left="fas fa-plus" @click="aplicar" icon-pack="fas">Aplicar CSV</b-button>
                        <br>
                        <br>
                        <div class="field">  
                                <b-checkbox v-model="reemplazar">Reemplazar respuestas</b-checkbox>
                     
                        </div>

                    </div>
            </div> 
            
        </div>
        
    </div>
</div>              
</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default{
    name: "CargaCsvRespuestas",
   
    metainfo:{
        title: "Carga de respuestas CSV"
    },
    data(){
        return{
            file:null,
            errores:null,
            asignacione:null,
            sinerrores:null,
            reemplazar:false,
            estado:null,
            rut:1,
            asignatura:2,
            curso:3,
            codigo:4,
            alternativas:5,
            columns: [
                {
                    field: 'Columna',
                    label: 'Orden'
                }
             ],
            dataCustomCSV: [
                {'Columna': 'Rut'},
                {'Columna': 'Asignatura'},
                {'Columna': 'Curso'},
                {'Columna': 'Codigo'},
                {'Columna': 'Respuestas'}],
            
            draggingRow: null,
            draggingRowIndex: null,
            mensaje: null
        }
    },
    methods:{
        carga(){

            var msgs = document.getElementById("mensajes");
            msgs.style.display = "none";
            let formData = new FormData();
            this.estado=null;
            var json_arr = JSON.stringify(this.dataCustomCSV);
            formData.append('file', this.file);
            formData.append('asignacion',this.asignacione.id);
            formData.append('rut',this.rut);
            formData.append('asignatura',this.asignatura);
            formData.append('curso',this.curso);
            formData.append('codigo',this.codigo);
            formData.append('alternativas',this.alternativas);
            formData.append('dataorden',JSON.stringify(this.dataCustomCSV));

            axios.post('api/asignaciones/respuestascsv',formData).then((res)=>{
                this.errores = res.data.errores;
                this.sinerrores = res.data.ok;
                console.log(res.data);
                msgs.style.display = "block";
            })
            
        },
        getAsignacion(){
            let formData = new FormData();
            let idasignacion = this.$route.params.asignacion;
            axios.post('api/asignaciones/getasignacion',{
                idasignacion: idasignacion
            }).then((res)=>{
                this.asignacione = res.data.asignacion;
                console.log(this.asignacione.id);
            })
        },
        ir_evaluacion(asignatura, curso, prueba, asignacion) {
            return this.$router.push({
                name: 'EvaluacionEnCurso',
                params: {
                    asignatura: asignatura,
                    curso: curso,
                    prueba: prueba,
                    asignacion: asignacion
                }
            });
        },
        getOrdenCsv(){
            let formData = new FormData();
            formData.append('dataorden',JSON.stringify(this.dataCustomCSV));
            
            axios.post('api/asignaciones/getordencsv',formData).then((res)=>{
               this.dataCustomCSV = JSON.parse(res.data.orden);

            })
        },
        setOrdenCsv(){
            //let ordenNuevo= JSON.stringify(this.dataCustomCSV);
            //console.log(ordenNuevo);
            let formData = new FormData();
            formData.append('dataorden',JSON.stringify(this.dataCustomCSV));
            axios.post('api/asignaciones/setordencsv',formData).then((res)=>{
                //this.dataCustomCSV = JSON.parse(res.data.orden);
                console.log(res.data.mensaje);
                this.mensaje=res.data.mensaje;
            });

        },
        aplicar(){
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('reemplazar',this.reemplazar);
            formData.append('asignacion',this.asignacione.id);
            formData.append('rut',this.rut);
            formData.append('asignatura',this.asignatura);
            formData.append('curso',this.curso);
            formData.append('codigo',this.codigo);
            formData.append('alternativas',this.alternativas);
            formData.append('dataorden',JSON.stringify(this.dataCustomCSV));
            axios.post('api/asignaciones/respuestascsvaplicar',formData).then((res)=>{
                this.errores = res.data.errores;
                console.log(res.data);
                if(res.data.estado === 'ok'){
                    this.estado= res.data.estado;
                }
                console.log(res.data);
            })
        },
        dragstart (payload) {
        this.draggingRow = payload.row
        this.draggingRowIndex = payload.index
        payload.event.dataTransfer.effectAllowed = 'copy'
      },
      dragover(payload) {
        payload.event.dataTransfer.dropEffect = 'copy'
        payload.event.target.closest('td').classList.add('is-selected')
        payload.event.preventDefault()
      },
      dragleave(payload) {
        payload.event.target.closest('td').classList.remove('is-selected')
        payload.event.preventDefault()
      },
      drop(payload) {
        payload.event.target.closest('td').classList.remove('is-selected')
        const droppedOnRowIndex = payload.index
      const droppedOnRow = payload.row;
      this.changePos('CargaCSVRespuestas/changePos', this.draggingRowIndex, this.draggingRow, droppedOnRowIndex, droppedOnRow);
     
        //this.$toast.open(`Moved ${this.draggingRow.first_name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
      },
      changePos(s, draggingRowIndex, draggingRow, droppedOnRowIndex, droppedOnRow) {
            this.dataCustomCSV.splice(droppedOnRowIndex, 1, draggingRow);
            this.dataCustomCSV.splice(draggingRowIndex, 1, droppedOnRow);
            console.log(this.dataCustomCSV);
        }
    },
    
    mounted(){
        this.getOrdenCsv();
        this.getAsignacion()
    }
}

</script>