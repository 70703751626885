<template>
<div class="columns is-multiline">
    <div class="column is-4" v-for="ramo in ramos" v-bind:key="ramo.id">
        <article :class="'message is-' + ramo.asignatura.imagen">
            <div class="message-body">
                <div class="media">
                    <div class="media-left">
                        <figure class="image is-32x32">
                            <img :src="'/assets/img/asignaturas/' + ramo.asignatura.imagen + '.svg'" :alt="ramo.asignatura.imagen">
                        </figure>
                    </div>
                    <div class="media-content">
                        <h1 class="title is-5">{{ramo.asignatura.nombre}}</h1>
                    </div>
                </div>
                <nav class="panel mt-5 has-background-white">
                    <div class="panel-block" v-for="curso in ramo.cursos" v-bind:key="curso.id">
                        <span class="panel-icon">
                            <span :class="'has-text-' + ramo.asignatura.imagen">
                                <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                    <circle cx="50" cy="50" r="40"></circle>
                                </svg>
                            </span>
                        </span>
                        {{curso.nivel.nombre}} {{curso.letra}}
                    </div>
                </nav>
                <b-button :type="'is-' + ramo.asignatura.imagen" icon-left="plus" class="mt-5" @click="gestionar(ramo.asignatura.imagen, ramo.asignatura_id)">Gestionar cursos</b-button>
            </div>
        </article>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import ConfiguracionModal from '../../components/configuracion/Modal.vue'

export default {
    data() {
        return {
            ramos: []
        }
    },
    methods: {
        cerrarModal() {
            // this.$buefy.modal.close()
            this.getAsignaturas()
        },
        gestionar(color, asignatura) {
            this.$buefy.modal.open({
                parent: this,
                component: ConfiguracionModal,
                customClass: 'custom-class custom-class-2',
                trapFocus: true,
                props: {
                    color: color,
                    asignatura: asignatura
                },
                events : {
                    'cerrarModal' : this.cerrarModal
                }
            })
        },
        getAsignaturas() {
            axios.get('/api/ramos/get?asignatura=null').then((res) => {
                this.ramos = res.data;
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getAsignaturas()
    }
}
</script>
