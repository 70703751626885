<template>
<div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title">Pregunta agrupada</p>
        <div class="is-pulled-right">
            <b-button rounded type="is-primary" :loading="loadingGuardar" @click="guardar">Guardar</b-button>
        </div>
        <div class="is-pulled-right ml-2">
            <b-button rounded @click="cancelar" :disabled="cancelarButton">Cancelar</b-button>
        </div>
    </header>
    <section class="modal-card-body">
        <div class="columns">
            <div class="column">
                <vue-editor v-model="enunciado" :editorOptions="configEnunciado"></vue-editor>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="box" v-for="pregunta in preguntasGrupo" v-bind:key="pregunta.id">
                    <article class="media">
                        <figure class="media-left has-text-centered pa1 ba br1 w2">
                            {{pregunta.orden}}
                        </figure>
                        <div class="media-content">
                            <div class="content">
                                <span v-html="pregunta.pregunta"></span>
                                <br>
                                <div v-if="pregunta.criterios">
                                    <hr class="has-background-grey-lighter">
                                    <hr class="has-background-grey-lighter">
                                    <hr class="has-background-grey-lighter">
                                    <hr class="has-background-grey-lighter">
                                </div>
                                <div v-if="pregunta.respuestas[0].respuesta">
                                    <div class="columns" v-for="respuesta in pregunta.respuestas" v-bind:key="respuesta.id">
                                        <div class="column is-1">
                                            <div v-if="pregunta.clave == respuesta.letra" style="height: 25px;width: 25px; background-color: #0303;border-radius: 50%">
                                                <div class="ml-2">
                                                    {{respuesta.letra}}
                                                </div>
                                            </div>
                                            <div v-else style="height: 25px;width: 25px; background-color: #fff;border-radius: 50%;border-color:#0404;border-width:thin;border-style: solid">
                                                <div class="ml-2">
                                                    {{respuesta.letra}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-11">
                                            <span v-html="respuesta.respuesta"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-right">
                            <b-dropdown aria-role="list">
                                <button class="button is-default" slot="trigger" slot-scope="{ active }">
                                    <span>Acciones</span>
                                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                </button>

                                <b-dropdown-item aria-role="listitem" @click="editar(pregunta)"> <i class="fas fa-pencil-alt"></i> Editar</b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" @click="eliminar(pregunta)" class="has-text-danger"> <i class="fas fa-trash-alt"></i> Eliminar</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="box">
                    <div class="card" style="border-style: dashed;border-width:1px;border-color:#2496f5">
                        <div class="card-content has-text-centered">
                            <div class="columns">
                                <div class="column">
                                    <strong>Pregunta individual</strong>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button type="is-primary" rounded @click="preguntaAlternativa">Selección múltiple</b-button>
                                    <b-button type="is-primary" class="ml-5" rounded @click="preguntaAbierta">Abierta</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import {
    VueEditor,
    Quill
} from "vue2-editor";
import {
    ImageDrop
} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import axios from 'axios';

import CrearEvaluacionPreguntaAbierta from '../../../components/crear/evaluacion/PreguntaAbierta.vue'
import CrearEvaluacionPreguntaAlternativa from '../../../components/crear/evaluacion/PreguntaAlternativa.vue'

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
    props: ['route_nivel', 'route_asignatura', 'route_prueba', 'preguntas', 'informacion'],
    name: 'CrerEvaluacionPreguntaGrupal',
    components: {
        VueEditor
    },
    data() {
        return {
            configEnunciado: {
                readOnly: false,
                placeholder: 'Ingresa un enunciado',
                modules: {
                    imageDrop: true,
                    imageResize: {},
                    toolbar: {
                        container: [
                            [{
                                'font': []
                            }],
                            [{
                                header: [1, 2, false]
                            }],
                            ['bold', 'italic', 'underline'],
                            [{
                                'color': []
                            }, {
                                'background': []
                            }], // dropdown with defaults from theme
                            [{
                                align: []
                            }],
                            [{
                                'list': 'ordered'
                            }, {
                                'list': 'bullet'
                            }],
                            ['link', 'image', 'formula'],
                            ['clean']
                        ]
                    }
                }
            },
            enunciado:null,
            loadingGuardar: false,
            preguntasGrupo : [],
            grupoValor: this.preguntas,
            cancelarButton: false
        }
    },
    methods: {
        getPreguntas(){
            this.isLoading = true
            axios.get(`/api/creador/evaluacion/preguntas/${this.route_prueba}/${this.grupoValor}`).then((res) => {
                console.log(res);
                this.preguntasGrupo = res.data;
                console.log(res.data.length)
                if (res.data.length > 0){
                    this.cancelarButton = true
                }
                this.isLoading = false;
            }).catch((err) => {
                throw err;
            });
        },
        cancelar() {
            this.$emit('close')
        },
        render() {

            if (this.informacion) {
                this.grupoValor = this.informacion.grupo
                this.enunciado = this.informacion.enunciado
                this.getPreguntas()
            }
        },
        editar(obj) {
            if (obj.puntos) {
                this.$buefy.modal.open({
                    parent: this,
                    component: CrearEvaluacionPreguntaAbierta,
                    trapFocus: true,
                    canCancel: false,
                    // width: 2000,
                    hasModalCard: true,
                    fullScreen: true,
                    props: {
                        route_nivel: this.route_nivel,
                        route_asignatura: this.route_asignatura,
                        route_prueba: this.route_prueba,
                        pregunta: obj.orden,
                        grupo: this.grupoValor,
                        informacion: obj
                    },
                    events: {
                        'getPreguntas': this.getPreguntas
                    }
                })
            } else {
                this.$buefy.modal.open({
                    parent: this,
                    component: CrearEvaluacionPreguntaAlternativa,
                    trapFocus: true,
                    canCancel: false,
                    // width: 2000,
                    hasModalCard: true,
                    fullScreen: true,
                    props: {
                        route_nivel: this.route_nivel,
                        route_asignatura: this.route_asignatura,
                        route_prueba: this.route_prueba,
                        pregunta: obj.orden,
                        grupo: this.grupoValor,
                        informacion: obj
                    },
                    events: {
                        'getPreguntas': this.getPreguntas
                    }
                })
            }

        },
        preguntaAbierta() {
            this.$buefy.modal.open({
                parent: this,
                component: CrearEvaluacionPreguntaAbierta,
                trapFocus: true,
                canCancel: false,
                // width: 2000,
                hasModalCard: true,
                fullScreen: true,
                props: {
                    route_nivel: this.route_nivel,
                    route_asignatura: this.route_asignatura,
                    route_prueba: this.route_prueba,
                    pregunta: this.preguntasGrupo.length + this.grupoValor,
                    grupo: this.grupoValor
                },
                events: {
                    'getPreguntas': this.getPreguntas
                }
            })
        },
        preguntaAlternativa() {
            this.$buefy.modal.open({
                parent: this,
                component: CrearEvaluacionPreguntaAlternativa,
                trapFocus: true,
                canCancel: false,
                // width: 2000,
                hasModalCard: true,
                fullScreen: true,
                props: {
                    route_nivel: this.route_nivel,
                    route_asignatura: this.route_asignatura,
                    route_prueba: this.route_prueba,
                    pregunta: this.preguntasGrupo.length + this.grupoValor,
                    grupo: this.grupoValor
                },
                events: {
                    'getPreguntas': this.getPreguntas
                }
            })
        },
        eliminar(obj) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar pregunta',
                message: '¿Estás seguro que quieres <b>eliminar</b> la pregunta Nº ' + obj.orden + '? Esta acción no se puede revertir.',
                confirmText: 'Eliminar pregunta',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/creador/evaluacion/pregunta/delete', {
                        id: obj.id
                    }).then((res) => {
                        if (res.data.status) {
                            this.$buefy.toast.open({
                                message: res.data.message,
                                type: 'is-success'
                            })
                            this.getPreguntas()
                        }
                    }).catch((err) => {
                        throw err
                    });
                }
            })
        },
        guardar() {

            if (!this.enunciado) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar el enunciado',
                    type: 'is-danger'
                })
            }

            let self = this;
            self.loadingGuardar = true;

            let link = '/api/creador/evaluacion/pregunta/grupal/update';

            axios.post(link, {
                enunciado: this.enunciado,
                prueba : this.route_prueba,
                grupo: this.grupoValor
            }).then((res) => {
                if (res.data) {
                    this.$buefy.toast.open({
                        message: '¡Pregunta grupal creada!',
                        type: 'is-success'
                    });
                    this.$emit('getPreguntas')
                    this.$emit('close');
                }
                self.loadingGuardar = false;
            }).catch((err) => {
                self.loadingGuardar = false;
                throw err
            })
        },
    },
    mounted() {
        this.render()
    }
}
</script>
