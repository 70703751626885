<template>
<div class="container">
    <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active"><a href="#" aria-current="page"></a></li>
                        </ul>
                    </nav>
                   
                </div>
            </div>
        </div>
    <div class="box">
        <div class="columns">
        <div class="column">
        <div class="tabs is-toggle is-toggle-rounded is-fullwidth">
            <ul>
                
                <li>
                    <router-link to="/utp/principal" >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro progresiva</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/utp/principal">
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro SIMCE</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/utp/principal">
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro PSU</span>
                    </router-link>
                </li>
                <li class="is-active" >
                        <a>
                            <span class="icon is-small">
                                <i class="fas fa-image"></i>
                            </span>
                            <span >Graficos por curso</span>
                        </a>
                </li>
            </ul>
        </div>
        </div>
        </div>
            <div class="columns">
                <div class="column is-2">
                    <div class="field">
                        <label class="label">Seleccione un curso</label>

                        <div class="select">
                            <select id="curso" v-model="selCurso" @change="graficoPrueba()">
                                <option v-for="curso in cursos" v-bind:key="curso.id" :value="curso.id">{{curso.nivel.nombre}} {{curso.letra}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="field">
                        <label class="label">Seleccione el tipo de prueba</label>

                        <div class="select">
                            <select id="prueba_tipo" v-model="selPruebaTipo" @change="graficoPrueba()">
                                <option v-for="tipo in tipopruebas" v-bind:key="tipo.id" :value="tipo.id">{{tipo.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>
        </div>
        
        <div class="columns">
            <div class="column">
                <div class="box">
                    <h5 class="title is-5">% Logro por tipo de prueba</h5>
                    <hr>
                    <vue-c3 :handler="handler"></vue-c3>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6">
                <div class="field">
                    <label class="label">Seleccione una asignatura</label>

                    <div class="select is-fullwidth">
                        <select id="asignatura" v-model="selAsignatura" @change="graficoAsignatura()">
                            <option v-for="asignatura in asignaturas" v-bind:key="asignatura.id" :value="asignatura.id">{{asignatura.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6">
                <div class="box">
                    <h5 class="title is-5">Por nivel de aprendizaje</h5>
                    <hr>
                    <vue-c3 :handler="handler2"></vue-c3>
                </div>
            </div>
            <div class="column is-6">
                <div class="box">
                    <h5 class="title is-5">Por porcentaje de logro</h5>
                    <hr>
                    <vue-c3 :handler="handler3"></vue-c3>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default{
    name: "UtpGraficos",
    metaInfo: {
        title: 'Graficos'
    },
    components:{
        VueC3
    },
    data(){
        return{
            user:null,
            colegio_id:null,
            tipopruebas:null,
            cursos:null,
            asignaturas:null,
            selPruebaTipo:null,
            selAsignatura:null,
            selCurso:null,
            handler: new Vue,
            handler2: new Vue,
            handler3: new Vue
        }
    },
    methods:{
        getDatos(){
            axios.get('/api/utp/grafico').then((res)=>{
                this.user = res.data.user;
                this.colegio_id = res.data.colegio_id;
                this.tipopruebas = res.data.tipopruebas;
                this.cursos = res.data.cursos;
                this.asignaturas = res.data.asignaturas;

            }).catch((err)=>{
                throw err;
            });
        },
        initGrafico(){
            var colors = [];
            
            colors['Insuficiente'] = 'rgb(255, 30, 49)';
            colors['Elemental'] = 'rgb(255, 153, 24)';
            colors['Adecuado'] = 'rgb(108, 165, 84)';

            console.log(colors);
            const options = {
                data: {
                     x: 'x',
                    columns: [
                        ['x', '']
                    ],
                    type: 'bar',
                    order: null,
                    colors: this.scolors
                },
                tooltip: {
                    format: {
                        value: function(value, ratio, id, index) {
                            return value + '%';
                        }
                    }
                },
                axis: {
                    x: {
                        type: 'category' // this needed to load string x value
                    }
                },
                grid: {
                    x: {
                        show: true
                    },
                    y: {
                        show: true
                    }
                }
            }
            this.handler.$emit('init', options)
        },
        graficoPrueba(){
            console.log(this.selCurso + " | "+this.selPruebaTipo)

            if(typeof(this.selCurso)==='undefined' || this.selPruebaTipo==='undefined'){
                return;
            }
            axios.post('api/utp/graficoprueba', {
                curso : this.selCurso,
                tipo_prueba : this.selPruebaTipo
            }).then((res) =>{

                console.log(res.data.columns);
                const options = {
                    data: {
                        x:'x',
                    columns: res.data.columns,
                    
                    type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category' // this needed to load string x value
                        }
                    },
                    grid: {
                            x: {
                                show: true
                            },
                            y: {
                                show: true
                            }
                        },
                }
                this.handler.$emit('init',options);
            }).catch((err)=>{
                console.log(err);
                throw err;
            });
        },
        graficoAsignatura(){
            var curso= this.selCurso;
            var prueba_tipo = this.selPruebaTipo;
            var asignatura = this.selAsignatura;

            axios.post('api/utp/graficoNivelAprendizaje',{
                curso: curso,
                prueba_tipo: prueba_tipo,
                asignatura: asignatura
            }).then((res) =>{

                console.log(res.data.columns);
                const options = {
                    data: {
                        x:'x',
                    columns: res.data.columns,
                    
                    type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category' // this needed to load string x value
                        }
                    },
                    grid: {
                            x: {
                                show: true
                            },
                            y: {
                                show: true
                            }
                        },
                }
                this.handler2.$emit('init',options);
            }).catch((err)=>{
                console.log(err);
                throw err;
            });

            ////

            axios.post('api/utp/graficoPorcentajeLogro',{
                curso: curso,
                prueba_tipo: prueba_tipo,
                asignatura: asignatura
            }).then((res) =>{
                console.log(res.data.columns);
                const options = {
                    data: {
                        x:'x',
                    columns: res.data.columns,
                    
                    type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category' // this needed to load string x value
                        }
                    },
                    grid: {
                            x: {
                                show: true
                            },
                            y: {
                                show: true
                            }
                        },
                }
                this.handler3.$emit('init',options);
            }).catch((err)=>{
                console.log(err);
                throw err;
            });
        }
    },
    mounted(){
        this.getDatos();
        this.initGrafico();
        
    },
}
</script>
