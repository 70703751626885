<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Asignación</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Resultado del Alumno</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns ">
            <div class="column is-10">
                <b-button class="is-info mr-2" rounded outlined tag="router-link" :to="'/asignacion/resultado/'+asignacion">Resultados del Curso</b-button>
                <b-button class="is-info" rounded  tag="router-link" :to="'/asignacion/resultadosporestudiantes/'+asignacion">Resultados por estudiante</b-button>
            </div>
            <div class="column is-2">
                <!-- <b-button class="is-info" rounded tag="router-link" :to="'/resumen/'+asignacion">Informe estadístico</b-button> -->
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field is-horizontal">
                   <b-field label="Nombre">
                        {{informacion.alumno.name}}
                    </b-field>
                   <b-field label="Curso" class="ml-5">
                        {{informacion.curso.nivel.nombre}} {{informacion.curso.letra}}
                    </b-field>
                    <b-field v-if="informacion.prueba.nombre" label="Nombre prueba" class="ml-5">
                        {{informacion.prueba.nombre}}
                    </b-field>
                    <b-field label="Prueba" class="ml-5">
                        {{informacion.prueba.prueba_tipo.nombre}}
                    </b-field>
                    <b-field label="Etapa/Tipo" class="ml-5">
                        {{informacion.prueba.prueba_etapa.nombre}}
                    </b-field>
                </div>
            </div>
            <div class="column">

            </div>
        </div>

        <div class="columns">
            <div class="column is-2">
                <b-field label="Nota alumno">
                    {{informacion.nota}}
                </b-field>
                <b-field label="Preguntas correctas">
                    {{informacion.correctas}}/{{informacion.total}}
                </b-field>
                <b-field label="Preguntas incorrectas">
                    {{informacion.incorrectas}}/{{informacion.total}}
                </b-field>
                <b-field label="Preguntas omitidas">
                    {{informacion.omitidas}}/{{informacion.total}}
                </b-field>
            </div>
            <div class="column is-9">
                <vue-c3 :handler="handler"></vue-c3>
            </div>


        </div>
        <div class="columns" v-if="objetivos.length">
            <div class="column">
                <hr>
                <h2 class="subtitle has-text-centered">Porcentaje de logro por Objetivos de Aprendizaje</h2>
                <div class="box">
                    <b-table :data="objetivos" :hoverable="true" :default-sort="['porcentaje', 'desc']" bordered>
                        <b-table-column field="nombre" label="Código" width="150" sortable v-slot="props">
                            {{ props.row.nombre }}
                        </b-table-column>
                        <b-table-column field="descripcion" label="Descripción" v-slot="props">
                            {{ props.row.descripcion }}
                        </b-table-column>
                        <b-table-column field="porcentaje" label="Porcentaje" width="250" sortable v-slot="props">
                            <b-progress :type="'is-' + informacion.prueba.asignatura.imagen" :value="props.row.porcentaje" show-value format="percent"></b-progress>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
        <div class="columns" v-if="habilidades.length">
            <div class="column">
                <hr>
                <h2 class="subtitle has-text-centered">Porcentaje de logro por Habilidades</h2>
                <div class="box">
                    <b-table :data="habilidades" :hoverable="true" :default-sort="['porcentaje', 'desc']" bordered>
                        <b-table-column field="nombre" label="Código" width="150" sortable v-slot="props">
                            {{ props.row.nombre }}
                        </b-table-column>
                        <b-table-column field="descripcion" label="Descripción" v-slot="props">
                            {{ props.row.descripcion }}
                        </b-table-column>
                        <b-table-column field="porcentaje" label="Porcentaje" width="250" sortable v-slot="props">
                            <b-progress :type="'is-' + informacion.prueba.asignatura.imagen" :value="props.row.porcentaje" show-value format="percent"></b-progress>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>

</div>
</template>
<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default {
    name: "AsignacionResultado",
    metaInfo: {
        title: 'Resultado evaluación'
    },
    components: {
        Colegios,
        VueC3
    },
    data() {
        return {
            informacion: {
                curso: {
                    nivel: {
                        nombre: ''
                    }
                },
                alumno: {
                    nombre: ''
                },
                asignatura: {
                    nombre: ''
                },
                prueba: {
                    prueba_etapa: {
                        nombre: ''
                    },
                    prueba_tipo: {
                        nombre: ''
                    },
                    asignatura:{
                        nombre:''
                    }
                },
                resumen_prueba: {}
            },
            adecuado: 0,
            elemental: 0,
            insuficiente: 0,
            objetivos: [],
            habilidades: [],
            isFullPage: true,
            isLoading: false,
            handler: new Vue(),
            asignacion: this.$route.params.asignacion,
            alumno: this.$route.params.alumno
        }
    },
    methods: {
        average(arr) {
            var sums = {},
                counts = {},
                id = {},
                descripcion = {},
                results = [],
                name;
            for (var i = 0; i < arr.length; i++) {
                name = arr[i].nombre;
                if (!(name in sums)) {
                    sums[name] = 0;
                    counts[name] = 0;
                }
                sums[name] += arr[i].porcentaje;
                id[name] = arr[i].id;
                descripcion[name] = arr[i].descripcion;
                counts[name]++;
            }

            for (name in sums) {
                results.push({
                    id: id[name],
                    nombre: name,
                    descripcion: descripcion[name],
                    porcentaje: sums[name] / counts[name]
                });
            }
            return results;
        },
        getInformacion() {
            let self = this
            this.isLoading = true;
            axios.post('/api/pruebas/resultado_estudiante', {
                asignacion: this.$route.params.asignacion,
                alumno: this.$route.params.alumno
            }).then((res) => {

                this.informacion = res.data;

                const options = {
                    data: {
                    columns: [
                        ['Incompleto', 101 - this.informacion.porcentaje_logro],
                        ['Alumno', this.informacion.porcentaje_logro],
                    ],
                    type: 'pie'
                    }
                }

                this.handler.$emit('init', options)

                let index = 0;
                res.data.resumen_objetivos.forEach((item) => {
                    let porcentaje = 0;

                    if (this.objetivos[index]) {
                        porcentaje = parseInt(this.objetivos[index].porcentaje);
                    }

                    this.$set(this.objetivos, index, {
                        'id': item.objetivo_id,
                        'nombre': item.objetivo.codigo,
                        'descripcion': item.objetivo.descripcion,
                        'porcentaje': porcentaje + parseInt(item.porcentaje)
                    })

                    index++;
                })

                this.objetivos = this.average(this.objetivos);

                index = 0;
                res.data.resumen_habilidades.forEach((item) => {
                    let porcentaje = 0;

                    if (this.habilidades[index]) {
                        porcentaje = parseInt(this.habilidades[index].porcentaje);
                    }

                    this.$set(this.habilidades, index, {
                        'id': item.habilidad_id,
                        'nombre': item.habilidad.codigo,
                        'descripcion': item.habilidad.descripcion,
                        'porcentaje': porcentaje + parseInt(item.porcentaje)
                    })

                    index++;
                })

                this.habilidades = this.average(this.habilidades);

                this.isLoading = false;

            }).catch((err) => {
                this.isLoading = false;
                throw err
            })
        }
    },
    mounted() {
        this.getInformacion()
    }
}
</script>
