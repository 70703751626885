<template>
<div class="container">

</div>
</template>
<script>
import axios from 'axios'


export default {

   
    data() {
        return {
         
        }
    },
    methods: {
        
        getInformacion() {          
            axios.get('/api/PDF/informePDF', {
               
            }).then((res) => {

                
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getInformacion()
    }
}
</script>
