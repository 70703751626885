<template>
<div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title">Habilidades</p>
    </header>
    <section class="modal-card-body">
        <b-tabs>
            <b-tab-item label="Currículum nacional">
                <div class="columns is-multiline">
                    <div class="column is-12" v-for="habilidad in habilidades" v-bind:key="habilidad.id">

                        <div class="card">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    <b-icon v-if="habilidad.priorizado == 1" icon="star" size="is-small"></b-icon>
                                                    <b-icon v-if="habilidad.priorizado == 2" icon="star-outline" size="is-small">
                                                    </b-icon> {{habilidad.codigo}}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-button @click="selectHabilidad(habilidad)">Seleccionar</b-button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="field">
                                            <p class="subtitle is-6 has-text-grey">{{habilidad.asignatura.nombre}} / {{habilidad.nivel.nombre}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{habilidad.descripcion}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Creados">
                <div class="columns" v-show="show">
                    <div class="column">
                        <b-input type="textarea" v-model="descripcionHabilidad" maxlength="200"></b-input>
                        <b-button type="is-primary" @click="crearHabilidad">Crear Habilidad</b-button>
                    </div>
                </div>
                <div v-if="habilidadesCreados.length" class="columns is-multiline">
                    <div class="column is-12" v-for="habilidad in habilidadesCreados" v-bind:key="habilidad.id">

                        <div class="card">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    {{habilidad.codigo}}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-button @click="selectHabilidad(habilidad)">Seleccionar</b-button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="field">
                                            <p class="subtitle is-6 has-text-grey">{{habilidad.asignatura.nombre}} / {{habilidad.nivel.nombre}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{habilidad.descripcion}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="card" style="border-style: dashed;border-width:1px;border-color:#2496f5">
                            <div class="card-content has-text-centered">
                                <div class="columns">
                                    <div class="column">
                                        <b-button type="is-primary" rounded @click="show=true" outlined>Crear Habilidad</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </section>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['nivel', 'asignatura', 'prueba'],
    name: 'Habilidades',
    data() {
        return {
            show: false,
            isFullPage: false,
            isLoading: false,
            habilidades: [],
            habilidadesCreados: [],
            descripcionHabilidad: null
        }
    },
    methods: {
        getHabilidadesCreados() {
            axios.get(`/api/habilidades/get/creados/${this.asignatura}/${this.nivel}`).then((res) => {
                this.habilidadesCreados = res.data
            }).catch((err) => {
                throw err
            });
        },
        crearHabilidad() {
            axios.post('/api/habilidades/set/creados', {
                asignatura: this.asignatura,
                nivel: this.nivel,
                descripcion: this.descripcionHabilidad
            }).then(() => {
                this.getHabilidadesCreados();
            }).catch((err) => {
                throw err
            })
        },
        getHabilidades() {
            let self = this;
            self.isLoading = true;
            axios.get(`/api/habilidades/get/${this.asignatura}/${this.nivel}/${this.prueba}`).then((res) => {
                this.habilidades = res.data
                self.isLoading = false;
            }).catch((err) => {
                throw err
            });
        },
        selectHabilidad(args) {
            this.$emit('close')
            this.$emit("setHabilidad", {
                'id': args.id,
                'codigo': args.codigo,
                'descripcion': args.descripcion
            })
        }
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getHabilidades()
        this.getHabilidadesCreados()
    }
}
</script>
