<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active"><a href="#" aria-current="page"></a></li>
                        </ul>
                    </nav>
                   
                </div>
            </div>
        </div>
<div class="box">
<div class="columns">
    <div class="column">
        <div class="tabs is-toggle is-toggle-rounded is-fullwidth">
            <ul>
                
                <li v-bind:class="(prueba_tipo_id ==1)?'is-active':''" v-on:click="recargaPorcentajeLogro(1)" >
                    <a >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro progresiva</span>
                    </a>
                </li>
                <li v-bind:class="(prueba_tipo_id ==2)?'is-active':''" v-on:click="recargaPorcentajeLogro(2)">
                    <a >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro SIMCE</span>
                    </a>
                </li>
                <li v-bind:class="(prueba_tipo_id ==3)?'is-active':''" v-on:click="recargaPorcentajeLogro(3)">
                    <a >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro PSU</span>
                    </a>
                </li>
                <li class="" >
                        <router-link to="/utp/graficos">
                            <span class="icon is-small">
                                <i class="fas fa-image"></i>
                            </span>
                            <span >Graficos por curso</span>
                        </router-link>
                </li>
            </ul>
        </div>
    </div>
</div>
            <div class="columns">
                <div class="column">  
                    <div class="table-container" style="width:80%; max-width: 100%">
                        <table class="table is-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2" style="vertical-align: middle">Cursos</th>
                                <template v-for="(asignatura, indice) in porcentajes_logros" >
                                    <th class="has-text-centered" v-if="indice=='Ciencias Naturales' && prueba_tipo_id==3" v-bind:key="asignatura.id" :colspan="18">{{indice}}</th>
                                    
                                    <th class="has-text-centered" v-else-if="prueba_tipo_id==3" v-bind:key="asignatura.id" :colspan="6">{{indice}}</th>
                                    <th class="has-text-centered" v-else-if="prueba_tipo_id==1" v-bind:key="asignatura.id" :colspan="3">{{indice}}</th>  
                                    <th class="has-text-centered" v-else-if="prueba_tipo_id==2" v-bind:key="asignatura.id" :colspan="6">{{indice}}</th>                                  
                                </template>     
                            </tr>
                            <tr>
                                <template v-if="prueba_tipo_id==1">
                                    <template v-for="numero in porcentajes_logros">
                                        <th>
                                            Diagnóstica
                                        </th>
                                        <th>
                                            Intermedia
                                        </th>
                                        <th>
                                            Final
                                        </th>
                                    </template>
                                </template>
                                <template v-else-if="prueba_tipo_id==2">
                                    <template v-for="innn in porcentajes_logros">
                                        <template v-for="numero in 6">
                                            <th>
                                                {{numero}}
                                            </th>
                                        </template>
                                    </template>
                                </template>
                                <template v-else-if="prueba_tipo_id==3">
                                    <template v-for="(innn,indice) in porcentajes_logros">
                                        <template v-if="indice=='Ciencias Naturales'">
                                                <th v-for="etapa in etapas_ciencias_psu" v-bind:key="numero1">
                                                    {{etapa.nombre.slice(-2)}}
                                                </th>
                                        </template>
                                        <template v-else>
                                            <template v-for="numero2 in 6">
                                                <th>
                                                    {{numero2}}
                                                </th>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="curso in cursos" v-bind:key="curso.id">
                                <td>
                                {{curso.nivel.codigo}} {{curso.letra}}
                                </td>
                                <template   v-for="(asignatura,indice) in porcentajes_logros" >
                                    <template v-if="prueba_tipo_id==1">
                                        <td ref="jedi" class="has-text-centered" v-for="numero in 3"> <!-- Aqui va la casilla que depende del colspan-->
                                            <template v-for="(tipo, index) in asignatura"> <!-- index para ProgreseivaDiagnostico -->
                                                <h4 v-if="numero==1 && index.includes('Diagnóstico')" >
                                                    <template v-for="(porcent, idnivel) in tipo">
                                                        <a v-bind:style="{color: porcent.split('|')[2]}" v-on:click="ir_evaluacion(porcent.split('|')[1])" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra && porcent!==null && porcent.charAt(0)!='|' && porcent.charAt(0)!='-'" > {{porcent.split('|')[0]}}%</a>
                                                        <a v-else-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)=='-'">-</a>
                                                    </template>
                                                </h4>
                                                <h4 v-else-if="numero==2 && index.includes('Intermedia')" >
                                                    <template v-for="(porcent, idnivel) in tipo">
                                                        <a v-bind:style="{color: porcent.split('|')[2]}" v-on:click="ir_evaluacion(porcent.split('|')[1])" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)!='|' && porcent.charAt(0)!='-'"> {{porcent.split('|')[0]}}%</a>
                                                        <a v-else-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)=='-'">-</a>
                                                    </template>
                                                </h4>
                                                <h4 v-else-if="numero==3 && index.includes('Final')" >
                                                    <template v-for="(porcent, idnivel) in tipo">
                                                        <a v-bind:style="{color: porcent.split('|')[2]}" v-on:click="ir_evaluacion(porcent.split('|')[1])" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)!='|' && porcent.charAt(0)!='-'"> {{porcent.split('|')[0]}}%</a>
                                                        <a v-else-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null  && porcent.charAt(0)=='-'">-</a>
                                                    </template>
                                                </h4>
                                                
                                            </template>
                                        </td>
                                    </template>
                                    <template v-else-if="prueba_tipo_id==2">
                                       <td class="has-text-centered" v-for="numero in 6"> <!-- Aqui va la casilla que depende del colspan-->
                                            <template v-for="(tipo, index) in asignatura"> <!--Index es el nombre de ensayo -->
                                                <h4 v-if="numero==index.slice(-1)" >
                                                    <template v-for="(porcent, idnivel) in tipo">
                                                        <a v-on:click="ir_evaluacion(porcent.split('|')[1])" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)!='|' && porcent.charAt(0)!='-'"> {{porcent.split('|')[0]}}%</a>
                                                        <a v-else-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)=='-'">-</a>
                                                    </template>
                                               </h4>
                                               
                                            </template>
                                        </td>
                                    </template>
                                    <template v-else-if="prueba_tipo_id==3">
                                        <template v-if="indice=='Ciencias Naturales'">
                                            <td class="has-text-centered" v-for="etapa in etapas_ciencias_psu"> <!-- Aqui va la casilla que depende del colspan-->
                                                <template v-for="(tipo, index) in asignatura"> <!--Index es el nombre de ensayo -->
                                                    <h4 v-if="etapa.nombre.slice(-2)==index.slice(-2)" >
                                                        <template v-for="(porcent, idnivel) in tipo">
                                                            <a v-on:click="ir_evaluacion(porcent.split('|')[1])" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)!='|' && porcent.charAt(0)!='-'"> {{porcent.split('|')[0]}}%</a>
                                                            <a v-else-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)=='-'">-</a>
                                                        </template>
                                                    </h4>
                                                    
                                                </template>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td class="has-text-centered" v-for="numero in 6"> <!-- Aqui va la casilla que depende del colspan-->
                                                <template v-for="(tipo, index) in asignatura"> <!--Index es el nombre de ensayo -->
                                                    <h4 v-if="numero==index.slice(-1)" >
                                                        <template v-for="(porcent, idnivel) in tipo">
                                                            <a v-on:click="ir_evaluacion(porcent.split('|')[1])" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)!='|' && porcent.charAt(0)!='-'"> {{porcent.split('|')[0]}}%</a>
                                                            <a v-else-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra  && porcent!==null && porcent.charAt(0)=='-'">-</a>
                                                        </template>
                                                    </h4>
                                                    
                                                </template>
                                            </td>
                                        </template>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'

export default{
    name: "UtpPrincipal",
    metaInfo: {
        title: 'Dashboard'
    },
    components: {
        
    },
    data(){
        return{
            tipos:null,
            cursos:null,
            etapas:null,
            asignaturas:null,
            prueba_tipo_id:null,
            etapas_ciencias_psu:null,
            colegio_id:null,
            cantidad_pruebas:null,
            cantidad_pruebas_rendidas:null,
            porcentaje_rendido:null,
            prueba_rendida:null,
            arraynuevo:null,
            porcentajes_logros:null,
            max:100,
            includs:"Diagnóstica",
            resumenes:null
        }
    },
    methods:{
        getDatos(){
            console.log("before" + this.prueba_tipo_id);
            axios.post('/api/utp/dashboard',{
                prueba_tipo : this.prueba_tipo_id
            }).then((res)=>{
                this.tipos = res.data.tipos;
                this.cursos = res.data.cursos;
                this.etapas = res.data.etapas;
                this.asignaturas = res.data.asignaturas;
                this.prueba_tipo_id = res.data.prueba_tipo_id;
                this.etapas_ciencias_psu = res.data.etapas_ciencias_psu;
                this.colegio_id = res.data.colegio_id;
                this.cantidad_pruebas = res.data.cantidad_pruebas;
                this.cantidad_pruebas_rendidas = res.data.cantidad_pruebas_rendidas;
                this.porcentaje_rendido = res.data.porcentaje_rendido;
                this.prueba_rendida = res.data.prueba_rendida;
                this.arraynuevo = res.data.arraynuevo;
                this.porcentajes_logros = res.data.porcentajes_logros;
                this.resumenes = res.data.resumenes;
                console.log('res:' + this.resumenes[0].id);
                //td class="has-text-centered"
                //this.ajustarLargo();
                var el = document.querySelector(".has-text-centered");
                console.log(el);
            }).catch((err)=>{
                throw err

            });
        },
        recargaPorcentajeLogro(tipo_id){
            this.prueba_tipo_id=tipo_id;
            this.getDatos();
            console.log("XD: " + this.prueba_tipo_id);
            window.addEventListener("resize", this.ajustarLargo);
        },
        ir_evaluacion(id_resumen){
            var res = this.resumenes;
            var found = null;
            console.log(id_resumen);
            
            res.forEach( function(valor, indice, array) {
                if(res[indice].id==id_resumen){
                    console.log("En el índice "+id_resumen+" | "+res[indice].id);
                   found = res[indice];
                }    
            });

            return this.$router.push({
                name: 'AsignacionResultado',
                params: {
                        asignatura: found.asignatura_id,
                        curso:found.curso_id,
                        prueba:found.asignacion.prueba_id,
                        asignacion: found.asignacion_id
                    }
                });
                
                
        },
        ajustarLargo(){
            var largo =  $(".columns").parent().width();
                    $( "h4" ).each(function( index ) {
                            console.log( index + ": " + $( this ).text() );
                        });

            $(".table-container").width(largo - 40).css('maxWidth','100%');
            
        }
    },
    mounted(){
        this.getDatos();
        window.addEventListener("resize", this.ajustarLargo);
        console.log(this.$refs);
    },
    
}
</script>
<style scoped>

</style>