<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Crear evaluación</router-link>
                        </li>
                        <li>
                            <router-link to="/crear/evaluacion/niveles">Niveles</router-link>
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Asignaturas</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="card">
        <div class="columns is-multiline">
            <div class="column is-3" v-for="ramo in ramos" v-bind:key="ramo.id">
                <div class="card mb-4 ml-4 mr-4"  v-bind:style="{ 'border-color': ramo.asignatura.color }" id="caja">
                    <article :class="' message is -' + ramo.asignatura.imagen" id="asignatura">
                        <div class="card-content" alt="card-content">
                            <div class="media">
                                <div class="media-left">
                                    <figure class="image is-32x32">
                                        <img :src="'/assets/img/asignaturas/' + ramo.asignatura.imagen + '.svg'" :alt="ramo.asignatura.imagen" />
                                    </figure>
                                </div>

                                <router-link :to="{ path: '/crear/evaluacion/niveles/' + nivel + '/asignaturas/' + ramo.asignatura_id + '/evaluaciones' }">
                                    {{ ramo.asignatura.nombre }}
                                </router-link>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</div>
</template>
<script>
import Colegios from "../../../components/Colegios.vue";
import axios from "axios";

export default {
    name: "CrearEvaluacionAsignatura",
    metaInfo: {
        title: "Seleccionar asignatura",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            ramos: [],
            nivel: this.$route.params.nivel,
            isLoading: false,
        };
    },

    methods: {
        getRamos() {
            this.isLoading = true;
            axios
                .get("/api/ramos/get?asignatura=null")
                .then((res) => {
                    this.ramos = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    mounted() {
        this.getRamos();
    },
};
</script>
