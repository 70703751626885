<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Mis evaluaciones</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Finalizadas</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <b-field label="Asignaturas">
                    <b-select v-model="asignatura" expanded placeholder="Selecciona una asignatura" @input="getEvaluacionesPendientes()">
                        <option value="">Todas</option>
                        <option v-for="asignatura in asignaturas" :value="asignatura.id" :key="asignatura.id">
                            {{ asignatura.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Cursos">
                    <b-select v-model="curso" placeholder="Selecciona un curso" expanded @input="getEvaluacionesPendientes()">
                        <option value="">Todos</option>
                        <option v-for="curso in cursos" :value="curso.id" :key="curso.id">
                            {{ curso.nivel.nombre }} {{curso.letra}}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Tipo de evaluación">
                    <b-select v-model="tipo" placeholder="Selecciona un tipo de evaluación" expanded @input="getEvaluacionesPendientes()">
                        <option value="">Todos</option>
                        <option v-for="tipo in tipos" :value="tipo.id" :key="tipo.id">
                            {{ tipo.nombre }} {{tipo.letra}}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Seleccione rango de fechas">
                    <b-datepicker @input="getEvaluacionesPendientes()" range :date-formatter="formatter" :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
                        :month-names="['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']" :show-week-number="true" placeholder="Seleccione rango de fechas"
                        icon="calendar-today" v-model="search_fechas">
                    </b-datepicker>
                </b-field>
            </div>
        </div>
        <div class="columns is-multiline">
            <!-- {{loadingButton}} -->
            <div class="column is-4" v-for="asignacion in pendientes" v-bind:key="asignacion.id">
                <article :class="'message is-' + asignacion.asignatura.imagen">
                    <div class="message-body">
                        <div class="columns">
                            <div class="column">
                                <b-progress :value="asignacion.asignacion_alumnos[0].avg" show-value format="percent" :type="'is-' + asignacion.asignatura.imagen"></b-progress>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-3">
                                <figure class="image is-64x64">
                                    <img :src="'/assets/img/asignaturas/' + asignacion.asignatura.imagen + '.svg'" :alt="asignacion.asignatura.imagen">
                                </figure>
                            </div>
                            <div class="column" style="height:170px">
                                <h4 v-if="asignacion.prueba.nombre" class="title is-5">Evaluación, {{asignacion.prueba.nombre}}</h4>
                                <h4  v-else class="title is-5">Evaluación, {{asignacion.prueba.prueba_tipo.nombre}} ({{asignacion.prueba.prueba_etapa.nombre}})</h4>
                                <p>{{asignacion.asignatura.nombre}}</p>
                                <p>Profesor: {{asignacion.user.name}}</p>
                                <p>Curso: {{asignacion.curso.nivel.nombre}} {{asignacion.curso.letra}}</p>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <p class="is-size-7">{{formatDateTime(asignacion.created_at)}}</p>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-10">
                                <b-button :type="'is-' + asignacion.asignatura.imagen" name="button" outlined @click="ir_evaluacion(asignacion.asignatura_id,asignacion.curso_id,asignacion.prueba_id,asignacion.id)">Ver resultados</b-button>
                            </div>
                            <div class="column">
                                <b-dropdown :triggers="['hover']" aria-role="list">
                                    <button :class="'is-outlined button is-' + asignacion.asignatura.imagen" slot="trigger">
                                        <b-icon icon="ellipsis-h" pack="fas"></b-icon>
                                    </button>

                                    <b-dropdown-item aria-role="listitem" @click="descargar(asignacion.prueba.id, asignacion.curso.nivel.id)"><i class="fas fa-file-pdf"></i> Descargar evaluación en formato PDF</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" @click="descargarRespuestas(asignacion.prueba.id)"><i class="fas fa-file-excel"></i> Descargar respuestas</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" @click="remedial(asignacion.id, asignacion.prueba.id, asignacion.asignatura.id, asignacion.curso.id)"><i class="fas fa-file-pdf"></i> Generar pdf Remedial</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" @click="reabrir(asignacion.id)"><i class="fas fa-redo"></i> Reabrir evaluación</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" @click="respuestasCSV(asignacion.id)"><i class="fas fa-file-excel"></i> Cargar respuestas desde CSV</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" class="has-text-danger" @click="eliminar(asignacion.id)"><i class="fas fa-trash-alt"></i> Eliminar evaluación</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <b-pagination :total="total" v-model="current" :range-before="rangeBefore" :range-after="rangeAfter" :order="order" :size="size" :simple="isSimple" :rounded="isRounded" :per-page="perPage" :icon-prev="prevIcon" :icon-next="nextIcon"
            aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">
        </b-pagination>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "EvaluacionesEnCurso",
    metaInfo: {
        title: 'Evaluaciones en curso'
    },
    components: {
        Colegios
    },
    data() {
        return {
            pendientes: [],
            isLoading: false,
            total: 0,
            current: 1,
            perPage: 6,
            rangeBefore: 3,
            rangeAfter: 1,
            order: '',
            size: '',
            isSimple: false,
            isRounded: false,
            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            asignaturas: [],
            cursos: [],
            tipos: [],
            asignatura: null,
            curso: null,
            tipo: null,
            search_fechas: null
        }
    },
    watch: {
        'current'() {
            this.isLoading = true;
            axios.post('/api/asignaciones/finalizadas', {
                paginate: this.current - 1,
                asignatura: this.asignatura,
                curso: this.curso,
                tipo: this.tipo,
                fechas: this.search_fechas
            }).then((res) => {
                this.pendientes = res.data.asignaciones
                this.total = res.data.total
                this.isLoading = false;
            }).catch((err) => {
                throw err
            })
        }
    },
    methods: {
        formatter(d) {
            return d[0].toLocaleDateString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }) + ' - ' + d[1].toLocaleDateString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })
        },
        descargar(id, niv_id) {
            window.open(process.env.VUE_APP_API_URL + '/prueba/' + id +'/'+ niv_id );
        },
        descargarRespuestas(id) {
            window.open(process.env.VUE_APP_API_URL + '/excel/exportRespuesta/'+id);

        },
        ir_evaluacion(asignatura,curso,prueba,asignacion) {

             axios.post('/api/pruebas/preguntas_abiertas', {
                        asignatura: asignatura,
                        curso:curso,
                        prueba:prueba,
                        asignacion: asignacion
                    }).then((res) => {
                        if (res.data.status) {
                           return this.$router.push({
                                name: 'AsignacionResultado',
                                params: {
                                    asignatura: asignatura,
                        curso:curso,
                        prueba:prueba,
                                    asignacion: asignacion
                                }
                            });
                        }
                        else{
                            this.isloadingEvaluacion = false
                            this.$buefy.toast.open({
                                message: res.data.message,
                                type: 'is-warning'
                            })                            
                                return this.$router.push({
                                    name: 'TabulacionRevisionConRubricas',
                                    params: {
                                        asignatura: asignatura,
                                        curso: curso,
                                        prueba: prueba,
                                        asignacion: asignacion
                                    }
                                });
                            
                        }
                    }).catch((err) => {
                        throw err
                    })
            
        },
        remedial(asignacion_id,prueba_id,asignatura_id,curso_id) {
            return this.$router.push({
                name: 'AplicarRemedial',
                params: {
                    asignacion_id: asignacion_id,
                    asignatura_id: asignatura_id,
                    curso_id: curso_id,
                    prueba_id: prueba_id
                }
            });
        },
        respuestasCSV(id){
            return this.$router.push({
                name: 'CargaCsvRespuestas',
                params: {
                    asignacion: id
                }
            });
        },
        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar asignación',
                message: '¿Estás seguro que deseas eliminar la asignación?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/asignaciones/eliminar', {
                        asignacion: id
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'Asignación eliminada correctamente',
                                type: 'is-success'
                            })
                            this.getEvaluacionesPendientes()
                        }
                    }).catch((err) => {
                        throw err
                    })
                }
            })
        },
        reabrir(id) {
            this.$buefy.dialog.confirm({
                title: 'Reabrir asignación',
                message: '¿Estás seguro que deseas reabrir la asignación?',
                confirmText: 'Reabrir',
                type: 'is-warning',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/asignaciones/reabrir', {
                        asignacion: id
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'Asignación re-abierta correctamente',
                                type: 'is-success'
                            })
                            this.getEvaluacionesPendientes()
                        }
                    }).catch((err) => {
                        throw err
                    })
                }
            })
        },
        formatDateTime(fecha) {
            return moment(fecha).locale('es').format('DD-MM-YYYY, h:mm:ss a');
        },
        // formatDate(fecha) {
        //     return moment(fecha).locale('es').format('LL');
        // },
        getEvaluacionesPendientes() {
            this.isLoading = true;
            axios.post('/api/asignaciones/finalizadas', {
                paginate: this.current - 1,
                asignatura: this.asignatura,
                curso: this.curso,
                tipo: this.tipo,
                fechas: this.search_fechas
            }).then((res) => {
                this.pendientes = res.data.asignaciones
                this.total = res.data.total
                this.isLoading = false;
            }).catch((err) => {
                throw err
            })
        },
        getAsignaturas() {
            axios.get('/api/ramos/getAll').then((res) => {
                res.data.forEach((item) => {
                    this.asignaturas.push(item.asignatura)
                })
            }).catch((err) => {
                throw err
            })
        },
        getCursos() {
            axios.get('/api/cursos/select').then((res) => {
                this.cursos = res.data
            }).catch((err) => {
                throw err
            })
        },
        getTipos() {
            axios.get('/api/pruebaetapas/get').then((res) => {
                this.tipos = res.data
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getEvaluacionesPendientes()
        this.getAsignaturas()
        this.getCursos()
        this.getTipos()
    }
}
</script>
