import store from '../store/index';

export default function auth({ next, router }) {

    if (!store.getters['auth/acceso']) {
        return router.push({
            name: 'SinColegio'
        });
    }

    if (store.getters['auth/acceso'].rol_id == 8) {
        return router.push({
            name: 'EsEstudiante'
        });
    }

    if (!store.getters['auth/acceso'].wizard) {
        return router.push({
            name: 'Wizard'
        });
    }



    return next();
}
