<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/">Crear evaluación</router-link>
                            </li>
                            <li>
                                <router-link to="/crear/evaluacion/niveles">Niveles</router-link>
                            </li>
                            <li>
                                <router-link :to="'/crear/evaluacion/niveles/' + route_nivel + '/asignaturas'"
                                    >Asignaturas</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/crear/evaluacion/niveles/' +
                                            route_nivel +
                                            '/asignaturas/' +
                                            route_asignatura +
                                            '/evaluaciones'
                                    "
                                    >Evaluaciones</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/crear/evaluacion/niveles/' +
                                            route_nivel +
                                            '/asignaturas/' +
                                            route_asignatura +
                                            '/objetivos/' +
                                            this.$route.params.tipo +
                                            '/' +
                                            route_prueba
                                    "
                                    >Objetivos</router-link
                                >
                            </li>
                             <li>
                                <router-link
                                    :to="
                                        '/crear/evaluacion/niveles/' +
                                            route_nivel +
                                            '/asignaturas/' +
                                            route_asignatura +
                                            '/1/prueba/' +
                                            route_prueba+'/banco'
                                    "
                                    >Banco de preguntas</router-link
                                >
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Crear prueba</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-3">
                <div class="box">
                    <div class="columns">
                        <div class="column is-6">
                            <h2 class="subtitle is-6 mt-3">OAs / Preguntas</h2>
                        </div>
                        <div class="column">
                            <div class="is-pulled-right">
                                <b-button type="is-primary" rounded outlined>Ver detalle</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <div class="columns">
                        <div class="column is-8">
                            <h2 class="subtitle mt-2">
                                Crear evaluación, {{ infoAsignatura.nombre }}, {{ infoNivel.nombre }}
                            </h2>
                        </div>
                        <div class="column">
                            <div class="is-pulled-right">
                                <div class="buttons">
                                    <b-dropdown aria-role="list">
                                        <button
                                            class="button is-primary is-outlined is-rounded"
                                            slot="trigger"
                                            slot-scope="{ active }"
                                        >
                                            <span>Más acciones</span>
                                            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                        </button>

                                        <b-dropdown-item aria-role="listitem" @click="guardar_borrador"
                                            >Guardar como borrador</b-dropdown-item
                                        >
                                        <b-dropdown-item aria-role="listitem" @click="pdf(route_prueba)"
                                            >Descargar prueba
                                        </b-dropdown-item>
                                        
                                        <b-dropdown-item aria-role="listitem" @click="banco_preguntas"
                                            >Ir al  banco de preguntas </b-dropdown-item
                                        >
                                        <b-dropdown-item aria-role="listitem" @click="descargarTablaEspecificaciones()"
                                            >Descargar tabla de especificaciones </b-dropdown-item
                                        >
                                        <!-- <b-dropdown-item aria-role="listitem">Eliminar prueba</b-dropdown-item> -->
                                    </b-dropdown>
                                    <b-button type="is-primary" class="ml-3" rounded @click="guardar">Guardar</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="columns">
                <div class="column">
                    <div v-if="preguntas.length == 0" class="box">
                        <p class="has-text-centered has-text-grey-light">Esta evaluación aún no tiene preguntas.</p>
                    </div>
                    <div class="creador box" v-for="columns in preguntas">
                        <div v-show="columns[0].prueba.origen == 37089 || columns[0].prueba.origen == 38676 || columns[0].prueba.origen == 38675 || columns[0].prueba.origen == 38674 || columns[0].prueba.origen == 38673">
                            ID = {{ columns[0].id }},
                            <br />
                            OBJETIVO =
                            <div v-if="columns[0].objetivo">{{ columns[0].objetivo.codigo }}</div>
                            <div v-else>No posee Objetivo</div>

                            <br />
                            DESCRIPCION HABILIDAD =
                            <div v-if="columns[0].habilidad">{{ columns[0].habilidad.descripcion }}</div>
                            <div v-else>No posee Descripcion</div>
                        </div>
                      
                        <div class="box" v-if="columns.length > 1">
                            <article class="media">
                                <div class="media-content">
                                    <span v-html="columns[0].enunciado"></span>
                                </div>
                                <div class="media-right">
                                    <b-dropdown aria-role="list">
                                        <button class="button is-default" slot="trigger" slot-scope="{ active }">
                                            <span>Acciones</span>
                                            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                        </button>

                                        <b-dropdown-item aria-role="listitem" @click="editarGrupal(columns[0])">
                                            <i class="fas fa-pencil-alt"></i> Editar</b-dropdown-item
                                        >
                                    </b-dropdown>
                                </div>
                            </article>
                        </div>

                        <div class="box" v-for="pregunta in columns" v-bind:key="pregunta.id">
                            <article class="media">
                                <figure class="media-left has-text-centered pa1 ba br1 w2">
                                    {{ pregunta.orden }}
                                </figure>
                                <div class="media-content">
                                    <div class="content">
                                        <b-tooltip :label="columns[0].objetivo.descripcion" position="is-right">
                                            <b-tag type="is-info">{{ columns[0].objetivo.codigo }}</b-tag>
                                        </b-tooltip>
                                        <span v-html="pregunta.pregunta"></span>
                                        <br />
                                        <div v-if="pregunta.criterios">
                                            <hr class="has-background-grey-lighter" />
                                            <hr class="has-background-grey-lighter" />
                                            <hr class="has-background-grey-lighter" />
                                            <hr class="has-background-grey-lighter" />
                                        </div>
                                        <div v-if="pregunta.respuestas[0].respuesta">
                                            <div
                                                class="columns"
                                                v-for="respuesta in pregunta.respuestas"
                                                v-bind:key="respuesta.id"
                                            >
                                                <div class="column is-1" v-show="respuesta.respuesta != ''">
                                                    <div
                                                        v-if="pregunta.clave == respuesta.letra"
                                                        style="height: 25px;width: 25px; background-color: #0303;border-radius: 50%"
                                                    >
                                                        <div class="ml-2">
                                                            {{ respuesta.letra }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else
                                                        style="height: 25px;width: 25px; background-color: #fff;border-radius: 50%;border-color:#0404;border-width:thin;border-style: solid"
                                                    >
                                                        <div class="ml-2">
                                                            {{ respuesta.letra }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-11" v-show="respuesta.respuesta != ''">
                                                    <span v-html="respuesta.respuesta"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right" v-if="columns.length == 1">
                                    <b-dropdown aria-role="list">
                                        <button class="button is-default" slot="trigger" slot-scope="{ active }">
                                            <span>Acciones</span>
                                            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                        </button>

                                        <b-dropdown-item aria-role="listitem" @click="editar(pregunta)">
                                            <i class="fas fa-pencil-alt"></i> Editar</b-dropdown-item
                                        >
                                        <b-dropdown-item
                                            aria-role="listitem"
                                            @click="eliminar(pregunta)"
                                            class="has-text-danger"
                                        >
                                            <i class="fas fa-trash-alt"></i> Eliminar</b-dropdown-item
                                        >
                                    </b-dropdown>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>

        <div class="columns">
            <div class="column">
                <div class="box">
                    <div class="card" style="border-style: dashed;border-width:1px;border-color:#2496f5">
                        <div class="card-content has-text-centered">
                            <div class="columns">
                                <div class="column">
                                    <strong>Pregunta individual</strong>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button type="is-primary" rounded @click="preguntaAlternativa"
                                        >Selección múltiple</b-button
                                    >
                                    <b-button type="is-primary" class="ml-5" rounded @click="preguntaAbierta"
                                        >Abierta</b-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <div class="card" style="border-style: dashed;border-width:1px;border-color:#2496f5">
                        <div class="card-content has-text-centered">
                            <div class="columns">
                                <div class="column">
                                    <strong>Preguntas agrupadas</strong>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button type="is-primary" @click="preguntaGrupal" rounded>Agregar</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../../components/Colegios.vue";
import CrearEvaluacionPreguntaAbierta from "../../../components/crear/evaluacion/PreguntaAbierta.vue";
import CrearEvaluacionPreguntaAlternativa from "../../../components/crear/evaluacion/PreguntaAlternativa.vue";
import CrearEvaluacionPreguntaGrupal from "../../../components/crear/evaluacion/PreguntaGrupal.vue";

import axios from "axios";

export default {
    name: "CrearEvaluacionPrueba",
    metaInfo: {
        title: "Creación evaluación",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            route_nivel: this.$route.params.nivel,
            route_asignatura: this.$route.params.asignatura,
            route_prueba: this.$route.params.prueba,
            infoAsignatura: {},
            infoNivel: {},
            preguntas: [],
            isFullPage: false,
            isLoading: false,
            preguntasTotal: 0,
        };
    },
    methods: {
        pdf(prueba) {
            window.open(process.env.VUE_APP_API_URL + "/prueba/" + prueba +'/'+ this.$route.params.nivel );
        },
        guardar() {
            this.$buefy.dialog.confirm({
                title: "Guardar evaluacion",
                message:
                    'Si guardas la evalucion ya no podras editarla, es recomendable guardar como borrador, selecciona "mas acciones" luego clic en "guardar como borrador", de lo contrario solo Guarda',
                confirmText: "Guardar",
                type: "is-info",
                hasIcon: true,
                onConfirm: () => {
                    axios
                        .post("/api/pruebas/store", {
                            prueba: this.$route.params.prueba,
                        })
                        .then((res) => {
                            if (res.data) {
                                this.$buefy.toast.open({
                                    message: "Prueba guardada exitosamente",
                                    type: "is-success",
                                });
                            }
                            return this.$router.push({
                                path: `/prueba/nombre/${this.$route.params.prueba}`,
                            });
                            self.loadingGuardar = false;
                        })
                        .catch((err) => {
                            self.loadingGuardar = false;
                            throw err;
                        });
                },
            });
        },
        banco_preguntas(){
                return this.$router.push(
                          '/crear/evaluacion/niveles/'+this.route_nivel +'/asignaturas/'+ this.route_asignatura+'/1/prueba/'+this.route_prueba+'/banco'
                        );
        },
        guardar_borrador() {
            axios
                .post("/api/pruebas/store_borrador", {
                    prueba: this.$route.params.prueba,
                })
                .then((res) => {
                    if (res.data) {
                        this.$buefy.toast.open({
                            message: "Prueba guardada como borrador, puedes seguir editando cuando quieras",
                            type: "is-success",
                        });
                    }
                    return this.$router.push({
                        name: "EvaluacionesMisEvaluaciones",
                    });
                    self.loadingGuardar = false;
                })
                .catch((err) => {
                    self.loadingGuardar = false;
                    throw err;
                });
        },
        eliminar(obj) {
            this.$buefy.dialog.confirm({
                title: "Eliminar pregunta",
                message:
                    "¿Estás seguro que quieres <b>eliminar</b> la pregunta Nº " +
                    obj.orden +
                    "? Esta acción no se puede revertir.",
                confirmText: "Eliminar pregunta",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    axios
                        .post("/api/creador/evaluacion/pregunta/delete", {
                            id: obj.id,
                        })
                        .then((res) => {
                            if (res.data.status) {
                                this.$buefy.toast.open({
                                    message: res.data.message,
                                    type: "is-success",
                                });
                                this.getPreguntas();
                            }
                        })
                        .catch((err) => {
                            throw err;
                        });
                },
            });
        },
        editarGrupal(obj) {
            this.$buefy.modal.open({
                parent: this,
                component: CrearEvaluacionPreguntaGrupal,
                trapFocus: true,
                canCancel: false,
                // width: 2000,
                hasModalCard: true,
                fullScreen: true,
                props: {
                    route_nivel: this.$route.params.nivel,
                    route_asignatura: this.$route.params.asignatura,
                    route_prueba: this.$route.params.prueba,
                    preguntas: this.preguntasTotal,
                    informacion: obj,
                },
                events: {
                    getPreguntas: this.getPreguntas,
                },
            });
        },
        editar(obj) {
            if (obj.puntos) {
                this.$buefy.modal.open({
                    parent: this,
                    component: CrearEvaluacionPreguntaAbierta,
                    trapFocus: true,
                    canCancel: false,
                    // width: 2000,
                    hasModalCard: true,
                    fullScreen: true,
                    props: {
                        route_nivel: this.$route.params.nivel,
                        route_asignatura: this.$route.params.asignatura,
                        route_prueba: this.$route.params.prueba,
                        pregunta: obj.orden,
                        informacion: obj,
                        grupo: false,
                    },
                    events: {
                        getPreguntas: this.getPreguntas,
                    },
                });
            } else {
                this.$buefy.modal.open({
                    parent: this,
                    component: CrearEvaluacionPreguntaAlternativa,
                    trapFocus: true,
                    canCancel: false,
                    // width: 2000,
                    hasModalCard: true,
                    fullScreen: true,
                    props: {
                        route_nivel: this.$route.params.nivel,
                        route_asignatura: this.$route.params.asignatura,
                        route_prueba: this.$route.params.prueba,
                        pregunta: obj.orden,
                        informacion: obj,
                        grupo: false,
                    },
                    events: {
                        getPreguntas: this.getPreguntas,
                    },
                });
            }
        },
        getPreguntas() {
            let self = this;
            self.preguntasTotal = 0;
            this.isLoading = true;
            axios
                .get(`/api/creador/evaluacion/preguntas/${this.route_prueba}`)
                .then((res) => {
                    this.preguntas = res.data;
                    // console.log(Object.keys(res.data).length)
                    Object.values(res.data).forEach((item) => {
                        item.forEach((i) => {
                            self.preguntasTotal++;
                        });
                    });

                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
        preguntaGrupal() {
            this.$buefy.modal.open({
                parent: this,
                component: CrearEvaluacionPreguntaGrupal,
                trapFocus: true,
                canCancel: false,
                // width: 2000,
                hasModalCard: true,
                fullScreen: true,
                props: {
                    route_nivel: this.$route.params.nivel,
                    route_asignatura: this.$route.params.asignatura,
                    route_prueba: this.$route.params.prueba,
                    preguntas: this.preguntasTotal + 1,
                },
                events: {
                    getPreguntas: this.getPreguntas,
                },
            });
        },
        preguntaAbierta() {
            this.$buefy.modal.open({
                parent: this,
                component: CrearEvaluacionPreguntaAbierta,
                trapFocus: true,
                canCancel: false,
                // width: 2000,
                hasModalCard: true,
                fullScreen: true,
                props: {
                    route_nivel: this.$route.params.nivel,
                    route_asignatura: this.$route.params.asignatura,
                    route_prueba: this.$route.params.prueba,
                    pregunta: this.preguntasTotal + 1,
                    grupo: false,
                },
                events: {
                    getPreguntas: this.getPreguntas,
                },
            });
        },
        descargarTablaEspecificaciones(){
            window.open(process.env.VUE_APP_API_URL + '/excel/exportTablaEspecificaciones/'+this.$route.params.prueba); 
        },
        preguntaAlternativa() {
            this.$buefy.modal.open({
                parent: this,
                component: CrearEvaluacionPreguntaAlternativa,
                trapFocus: true,
                canCancel: false,
                // width: 2000,
                hasModalCard: true,
                fullScreen: true,
                props: {
                    route_nivel: this.$route.params.nivel,
                    route_asignatura: this.$route.params.asignatura,
                    route_prueba: this.$route.params.prueba,
                    pregunta: this.preguntasTotal + 1,
                    grupo: false,
                },
                events: {
                    getPreguntas: this.getPreguntas,
                },
            });
        },
        getAsignatura() {
            axios
                .post("/api/asignaturas/asignatura", {
                    asignatura_id: this.$route.params.asignatura,
                })
                .then((res) => {
                    this.infoAsignatura = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getNivel() {
            axios
                .post("/api/niveles/nivel", {
                    nivel_id: this.$route.params.nivel,
                })
                .then((res) => {
                    this.infoNivel = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    mounted() {
        this.getAsignatura();
        this.getNivel();
        this.getPreguntas();
    },
};
</script>

<style>
.creador h1 {
    font-size: 2em;
    font-weight: bold;
}

.creador h2 {
    font-size: 1.5em;
}

.creador .ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}

.creador .ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}

.creador .ql-size-small {
    font-size: 0.75em;
}

.creador .ql-size-large {
    font-size: 1.5em;
}

.creador .ql-size-huge {
    font-size: 2.5em;
}

.creador .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
}

.creador .ql-align-center {
    text-align: center;
}

.creador .ql-align-justify {
    text-align: justify;
}

.creador .ql-align-right {
    text-align: right;
}

.creador blockquote {
    border-left: 4px solid #ccc;
    padding-left: 16px;
}

.creador code,
.creador pre {
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 6px 10px;
}

.creador ul > li[data-checked="true"]::before {
    content: "\2611";
}

.creador ul > li[data-checked="false"]::before {
    content: "\2610";
}

.creador ol > li,
.creador ul > li {
    list-style-type: none;
}

.creador ol {
    counter-reset: mylist;
}

.creador ol > li:before {
    counter-increment: mylist;
    content: counter(mylist, decimal) ". ";
}

.creador ol ol > li:before {
    content: counter(mylist, lower-alpha) ". ";
}

.creador ol ol ol > li:before {
    content: counter(mylist, lower-roman) ". ";
}

.creador ol ol ol ol > li:before {
    content: counter(mylist, decimal) ". ";
}

.creador ol ol ol ol ol > li:before {
    content: counter(mylist, lower-alpha) ". ";
}

.creador ol ol ol ol ol ol > li:before {
    content: counter(mylist, lower-roman) ". ";
}

.creador ol ol ol ol ol ol ol > li:before {
    content: counter(mylist, decimal) ". ";
}

.creador ol ol ol ol ol ol ol ol > li:before {
    content: counter(mylist, lower-alpha) ". ";
}

.creador ol ol ol ol ol ol ol ol ol > li:before {
    content: counter(mylist, lower-roman) ". ";
}

/* ql indent */
.creador .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
}

.creador .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
}

.creador .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
}

.creador .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
}

.creador .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
}

.creador .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
}

.creador .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
}

.creador .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
}

.creador .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
}

.creador .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
}

.creador .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
}

.creador .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
}

.creador .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
}

.creador .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
}

.creador .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
}

.creador .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
}

.creador .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
}

.creador .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
}

/* video */
.creador .ql-video {
    display: block;
    max-width: 100%;
}

.creador .ql-video.ql-align-center {
    margin: 0 auto;
}

.creador .ql-video.ql-align-right {
    margin: 0 0 0 auto;
}
</style>
