<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Aplicar</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>

        <div class="box">
            <div class="columns">
                <div class="column is-10">
                    <h1 class="title" v-if="curso">
                        Evaluación, {{ evaluacion.prueba_tipo.nombre }} {{ evaluacion.prueba_etapa.nombre }}
                        {{ evaluacion.asignatura.nombre }}, {{ curso.nivel.nombre }} {{ curso.letra }}
                    </h1>
                </div>
                <div class="column is-2">
                    <div class="is-pulled-right">
                        <b-button
                            :type="'is-' + evaluacion.asignatura.imagen"
                            icon-left="play"
                            icon-pack="fas"
                            :loading="isloadingEvaluacion"
                            @click="aplicar"
                            >Aplicar evaluación</b-button
                        >
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-12">
                    <b-table :data="alumnos" :loading="isLoading" :default-sort="['name', 'asc']" bordered>
                        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>
                        <b-table-column field="rut" label="RUT" sortable v-slot="props">
                            {{ props.row.rut }}
                        </b-table-column>
                        <b-table-column field="name" label="Nombres" sortable v-slot="props">
                            {{ props.row.name }}
                        </b-table-column>
                        <b-table-column field="estado" label="Estado">
                            <span class="tag">
                                Sin iniciar
                            </span>
                        </b-table-column>
                        <b-table-column field="progreso" label="Progreso">
                            <progress class="progress" value="0" max="100">0%</progress>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../components/Colegios.vue";
import axios from "axios";

export default {
    name: "Cursos",
    metaInfo: {
        title: "Aplicar evaluación",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            evaluacion: null,
            curso: null,
            alumnos: [],
            isLoading: false,
            isloadingEvaluacion: false,
        };
    },
    methods: {
        aplicar() {
            this.isloadingEvaluacion = true;
            axios
                .post("/api/pruebas/aplicar", {
                    curso_id: this.$route.params.curso,
                    prueba_id: this.$route.params.prueba,
                })
                .then((res) => {
                    if (res.data.status == true) {
                        return this.$router.push({
                            name: "EvaluacionEnCurso",
                            params: {
                                asignatura: this.$route.params.asignatura,
                                curso: this.$route.params.curso,
                                prueba: this.$route.params.prueba,
                                asignacion: res.data.asignacion,
                            },
                        });
                    } else {
                        this.$buefy.toast.open({
                            message: res.data.message,
                            position: "is-bottom",
                            type: "is-danger",
                        });
                    }
                    this.isloadingEvaluacion = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getInformacion() {
            axios
                .post("/api/pruebas/get", {
                    prueba: this.$route.params.prueba,
                })
                .then((res) => {
                    this.evaluacion = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getCurso() {
            axios
                .post("/api/cursos/curso", {
                    curso_id: this.$route.params.curso,
                })
                .then((res) => {
                    this.curso = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getAlumnos() {
            this.isLoading = true;
            axios
                .post("/api/cursos/get/alumnos", {
                    curso_id: this.$route.params.curso,
                })
                .then((res) => {
                    this.alumnos = res.data.users;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    mounted() {
        this.getInformacion();
        this.getCurso();
        this.getAlumnos();
    },
};
</script>
