<template>
<div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title">Pregunta {{pregunta}}</p>
        <div class="is-pulled-right">
            <b-button rounded type="is-primary" :loading="loadingGuardar" @click="guardar">Guardar</b-button>
        </div>
        <div class="is-pulled-right ml-2">
            <b-button rounded @click="cancelar">Cancelar</b-button>
        </div>
    </header>
    <section class="modal-card-body">
        <div class="columns">
            <div class="column is-3">

                <b-button expanded @click="getObjetivos">+ Objetivos de Aprendizaje</b-button>

                <div v-if="Object.keys(objetivo).length !== 0">
                    <div class="notification">
                        <p class="title is-5">{{objetivo.codigo}} </p>
                        <p>{{objetivo.descripcion | truncate(100)}}</p>
                    </div>
                </div>

                <b-button :disabled="Object.keys(objetivo).length === 0" expanded class="mt-2" @click="getIndicadores">+ Indicador</b-button>

                <div v-if="Object.keys(indicador).length !== 0">
                    <div class="notification">
                        <p>{{indicador.descripcion | truncate(100)}}</p>
                    </div>
                </div>

                <b-button expanded class="mt-2" @click="getHabilidades">+ Habilidad</b-button>

                <div v-if="Object.keys(habilidad).length !== 0">
                    <div class="notification">
                        <p class="title is-5">{{habilidad.codigo}} </p>
                        <p>{{habilidad.descripcion | truncate(100)}}</p>
                    </div>
                </div>

                <hr>
                <b-field label="Dificultad">
                    <b-select v-model="dificultad" placeholder="Selecciona la dificultad" expanded>
                        <option value="1">Inicial</option>
                        <option value="2">Intermedia</option>
                        <option value="3">Avanzada</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-9">
                <div class="columns">
                    <div class="column is-2">
                        Agregar salto de linea
                    </div>
                    <div class="column is-10">
                        <b-checkbox
                            v-model="page_break"
                        ></b-checkbox>
                    </div>
                </div>
                <vue-editor v-model="enunciado" :editorOptions="configEnunciado"></vue-editor>
                <br>
                <!-- {{valores}} -->
                <table class="table is-fullwidth is-bordered">
                    <tr>
                        <td></td>
                        <td :colspan="criteriosTitulos.length" class="has-text-centered">
                            <strong>Categorías / Nivel</strong>

                            <b-button type="is-danger is-light is-small" class="is-pulled-right" outlined rounded @click="quitarNivel">- Quitar Nivel</b-button>
                            <b-button type="is-info is-light is-small" class="is-pulled-right mr-2" outlined rounded @click="agregarNivel">+ Agregar Nivel</b-button>

                        </td>
                    </tr>
                    <tr>
                        <td class="has-text-centered">
                            <strong>Criterio</strong>
                            <!-- <br>
                            <b-button class="mt-2" type="is-info is-light is-small" outlined rounded @click="agregarCriterio">+ Agregar Criterio</b-button>
                            <b-button class="mt-2" type="is-danger is-light is-small" outlined rounded @click="quitarCriterio">- Quitar Criterio</b-button> -->
                        </td>
                        <td v-show="key < nivelesTotal" v-for="(criterio, key) in criteriosTitulos" v-bind:key="key" class="has-background-white-ter">

                            <div class="columns">
                                <div class="column">
                                    <p class="is-small">Puntos</p>
                                </div>
                                <div class="column">
                                    <b-numberinput rounded size="is-small" :controls="false" v-model.lazy="puntos[key]" :value="puntos[key]"></b-numberinput>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-input rounded :lazy="true" v-model="criteriosTitulos[key]" :value="criterio"></b-input>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr v-show="key < criteriosTotal" v-for="(crit, key) in criteriosTotal" v-bind:key="key">
                        <td v-show="k - 1 < nivelesTotal" v-for="(criterio, k) in criterios[key]" v-bind:key="k">
                            <b-input :lazy="true" :has-counter="false" v-model="criterios[key][k]" maxlength="200" type="textarea"></b-input>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import Objetivos from '../../../components/crear/evaluacion/Objetivos.vue'
import Indicadores from '../../../components/crear/evaluacion/Indicadores.vue'
import Habilidades from '../../../components/crear/evaluacion/Habilidades.vue'

import {
    VueEditor,
    Quill
} from "vue2-editor";
import {
    ImageDrop
} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import axios from 'axios';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
    props: ['route_nivel', 'route_asignatura', 'route_prueba', 'pregunta', 'informacion', 'grupo'],
    name: 'CrerEvaluacionPreguntaAbierta',
    components: {
        VueEditor
    },
    data() {
        return {
            configEnunciado: {
                readOnly: false,
                placeholder: 'Ingresa un enunciado',
                modules: {
                    imageDrop: true,
                    imageResize: {},
                    toolbar: {
                        container: [
                            [{
                                'font': []
                            }],
                            [{
                                header: [1, 2, false]
                            }],
                            ['bold', 'italic', 'underline'],
                            [{
                                'color': []
                            }, {
                                'background': []
                            }], // dropdown with defaults from theme
                            [{
                                align: []
                            }],
                            [{
                                'list': 'ordered'
                            }, {
                                'list': 'bullet'
                            }],
                            ['link', 'image', 'formula'],
                            ['clean']
                        ]
                    }
                }
            },
            criteriosTitulos: [
                'Excelente',
                'Bueno',
                'Regular',
                'Deficiente',
                '',
                '',
                '',
                ''
            ],
            enunciado: null,
            puntos: [
                4, 3, 2, 1, 0, 0, 0, 0
            ],
            criterios: [
                [
                    '', '', '', '', '', '', '', '', ''
                ],
            ],
            criteriosTotal: 1,
            nivelesTotal: 4,
            dificultad: 2,
            page_break:false,
            objetivo: {},
            indicador: {},
            habilidad: {},
            loadingGuardar: false
        }
    },
    methods: {
        setObjetivo(args) {
            this.objetivo = args;
            this.indicador = {};
        },
        setIndicador(args) {
            this.indicador = args;
        },
        setHabilidad(args) {
            this.habilidad = args;
        },
        getObjetivos() {
            this.$buefy.modal.open({
                parent: this,
                component: Objetivos,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba
                },
                events: {
                    setObjetivos: this.setObjetivo
                }
            })
        },
        getIndicadores() {
            this.$buefy.modal.open({
                parent: this,
                component: Indicadores,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba,
                    objetivo: this.objetivo.id
                },
                events: {
                    setIndicador: this.setIndicador
                }
            })
        },
        getHabilidades() {
            this.$buefy.modal.open({
                parent: this,
                component: Habilidades,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    nivel: this.route_nivel,
                    asignatura: this.route_asignatura,
                    prueba: this.route_prueba
                },
                events: {
                    setHabilidad: this.setHabilidad
                }
            })
        },
        cancelar() {
            this.$emit('close')
        },
        render() {
            for (var i = 0; i <= 30; i++) {
                this.$set(this.criterios, i, [
                    '', '', '', '', '', '', '', '', ''
                ]);
            }
            if (this.informacion) {
                console.log(this.informacion);
                this.enunciado = this.informacion.pregunta;
                this.objetivo = this.informacion.objetivo;
                this.indicador = this.informacion.indicador ? this.informacion.indicador : {};
                this.habilidad = this.informacion.habilidad ? this.informacion.habilidad : {};
                this.page_break = this.informacion.page_break;
                let criterios = JSON.parse(this.informacion.criterios);
                criterios.forEach((criterio, key) => {
                    let nuevo_criterio=criterio;
                    for(var i=criterio.length; i<= 9; i++) {
                        this.$set(nuevo_criterio, i, '');
                    }
                    this.$set(this.criterios, key, nuevo_criterio);
                })
                this.criteriosTotal = criterios.length;

                let criteriosTitulos = JSON.parse(this.informacion.criterios_titulos);
                criteriosTitulos.forEach((criterio, key) => {
                    this.$set(this.criteriosTitulos, [key], criterio);
                })
                this.nivelesTotal = criteriosTitulos.length;

                let puntajes = JSON.parse(this.informacion.puntos);
                puntajes.forEach((item, key) => {
                    this.$set(this.puntos, [key], item);
                })
            }
        },
        guardar() {

            if (Object.keys(this.objetivo).length === 0) {
                return this.$buefy.toast.open({
                    message: 'Debe seleccionar un objetivo de aprendizaje',
                    type: 'is-danger'
                })
            }

            if (!this.enunciado) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar un enunciado',
                    type: 'is-danger'
                })
            }
            if (!this.criterios[0][0]) {
                return this.$buefy.toast.open({
                    message: 'Debe ingresar al menos un criterio',
                    type: 'is-danger'
                })
            }

            let self = this;
            self.loadingGuardar = true;

            let link = '/api/creador/evaluacion/pregunta/abierta/update';

            if (!this.informacion) {
                link = '/api/creador/evaluacion/pregunta/abierta/store';
            }

            axios.post(link, {
                enunciado: this.enunciado,
                puntos: this.puntos,
                criterios: this.criterios,
                criteriosTotal: this.criteriosTotal,
                nivelesTotal: this.nivelesTotal,
                dificultad: this.dificultad,
                objetivo: this.objetivo,
                indicador: this.indicador,
                habilidad: this.habilidad,
                criteriosTitulos: this.criteriosTitulos,
                nivel: this.route_nivel,
                asignatura: this.route_asignatura,
                prueba: this.route_prueba,
                informacion: this.informacion,
                page_break: this.page_break,
                grupo: this.grupo
            }).then((res) => {
                if (res.data) {
                    this.$buefy.toast.open({
                        message: '¡Pregunta creada!',
                        type: 'is-success'
                    });
                    this.$emit('getPreguntas')
                    this.$emit('close');
                }
                self.loadingGuardar = false;
            }).catch((err) => {
                self.loadingGuardar = false;
                throw err
            })
        },
        agregarNivel: function() {
            if (this.nivelesTotal >= 6) {
                return this.$buefy.toast.open({
                    message: 'No puedes crear más de 6 niveles',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.nivelesTotal++;
        },
        quitarNivel: function() {
            if (this.nivelesTotal <= 1) {
                return this.$buefy.toast.open({
                    message: 'No puedes quitar más niveles',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.nivelesTotal--;
        },
        agregarCriterio: function() {
            if (this.criteriosTotal >= 30) {
                return this.$buefy.toast.open({
                    message: 'No puedes crear más de 30 criterios',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.criteriosTotal++;
        },
        quitarCriterio: function() {
            if (this.criteriosTotal <= 1) {
                return this.$buefy.toast.open({
                    message: 'No puedes quitar más criterios',
                    queue: false,
                    type: 'is-danger'
                })
            }
            this.criteriosTotal--;
        },

    },
    filters: {
        truncate(value, length) {
            return value.length > length ? value.substr(0, length) + '...' : value
        }
    },
    mounted() {
        this.render()
    }
}
</script>
