<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Cursos</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title" v-if="colegio">{{colegio.nombre}} - Cursos</h1>
                <p>Permite visualizar los cursos de tu colegio .</p>
            </div>
        </div>
        <div class="columns">
            <div class="column" style="text-align:right;">
                <div class="buttons is-block">
                    <b-button tag="router-link" :to="'/cursos/crear/'" type="is-primary" icon-left="fas fa-plus" icon-pack="fas">Crear curso</b-button>


                    
                    <section>
                        <div class="block">
                            <b-radio v-model="option"
                                name="name"
                                native-value="aeduc">
                                Formato AEDUC
                            </b-radio>
                            <b-radio v-model="option"
                                name="name"
                                native-value="sige">
                                Formato SIGE
                            </b-radio>
                        </div>
                        <b-upload v-model="file" class="file-label">
                        <span class="file-cta">
                            <span class="file-label">Seleccione archivo</span>
                        </span>
                        <span class="file-name" v-if="file">
                            {{ file.name }}
                        </span>
                        </b-upload>
                        <b-button v-if="option == 'sige'" type="is-primary" class="ml-2" icon-left="fas fa-upload" icon-pack="fas" @click="sige" :loading="loadingSige">Carga masiva SIGE</b-button>
                        <b-button v-if="option == 'aeduc'" type="is-primary" class="ml-2" icon-left="fas fa-upload" icon-pack="fas" @click="aeducFormato" :loading="loadingSige">Carga masiva AEDUC</b-button>
                        <b-button 
                            v-if="option == 'aeduc'" 
                            type="is-success" 
                            @click="descargarformato">
                        <b-icon 
                            pack="fas"
                            icon="file-excel"
                            size="is-small">
                        </b-icon>                       
                        <span>Formato archivo excel AEDUC</span>
                        </b-button>

                    </section>
                    
                </div>

            </div>
        </div>
        <div class="columns">
            <div class="column">
                <section>
                    <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                        aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                        <b-table-column field="id" label="ID" sortable v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="colegio_id" label="Colegio" sortable v-slot="props">
                            {{ props.row.colegio.nombre }}
                        </b-table-column>

                        <b-table-column field="nivel_id" label="Nivel" sortable v-slot="props">
                            {{ props.row.nivel.nombre }}
                        </b-table-column>

                        <b-table-column field="letra" label="Letra" sortable v-slot="props">
                            {{ props.row.letra }}
                        </b-table-column>

                        <b-table-column label="Alumnos" v-slot="props">
                            {{ props.row.users.length }}
                        </b-table-column>

                        <b-table-column field="created_at" label="Creación" sortable v-slot="props">
                            {{ props.row.created_at ? new Date(props.row.created_at).toLocaleString('ES-CL') : 'unknown' }}
                        </b-table-column>

                        <b-table-column label="Acciones" v-slot="props">
                            <div class="buttons">
                                <b-button type="button" class="button is-small is-success is-outlined" tag="router-link" :to="'/cursos/alumnos/' + props.row.id + '/'" name="button"> <i class="fas fa-users"></i> Alumnos
                                </b-button>
                            </div>

                        </b-table-column>
                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>No se encontraron cursos</p>
                                </div>
                            </section>
                        </template>
                    </b-table>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            id: this.$route.params.id,
            colegio: null,
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            file: null,
            loadingSige: false,
            option:"aeduc"
        }
    },
    methods: {

        sige() {
            let self = this
            self.loadingSige = true;
            let formData = new FormData();
            formData.append('excel', this.file);
            formData.append('periodo', this.periodo);
            axios.post('/api/cursos/uploadUtp', formData).then(response => {

                self.loadingSige = false;

                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.loadAsyncData();
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-danger'
                    })
                }


            }).catch(error => {
                self.loadingSige = false;

                throw error
            });
        },
        descargarformato(){
             window.open(process.env.VUE_APP_API_URL + '/archivoformatoAeduc/');
        },
        aeducFormato() {
            let self = this
            self.loadingSige = true;
            let formData = new FormData();
            formData.append('file', this.file);
            axios.post('/api/cursos/cargarAeducFormato', formData).then(response => {


                
                self.loadingSige = false;

                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.loadAsyncData();
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-danger'
                    })
                }


            }).catch(error => {
                self.loadingSige = false;
                throw error
            });
        },
        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar curso',
                message: '¿Estás seguro que deseas <b>eliminar</b> el curso con id <b>' + id +
                    '</b>?. Esta accion no se puede revertir. <br><br> Si el curso estaba asociado a alumnos, estos alumnos seguirán existiendo pero sin cursos asociados.',
                confirmText: 'Eliminar curso',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                        id: id,
                        periodo: this.periodo
                    }
                    axios.post('/api/cursos/delete', params)
                        .then(response => {
                            this.$buefy.toast.open({
                                message: response.data.message,
                                type: 'is-success'
                            })
                            this.loadAsyncData()
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo eliminar el curso',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
            })
        },

        getColegio() {
            
            axios.post('/api/colegios/getUtp').then((res) => {
                this.colegio = res.data
            }).catch((err) => {
                throw err
            })
        },
        /*
         * Load async data
         */
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `periodo=${this.periodo}`
            ].join('&')

            this.loading = true
            axios.get(`/api/cursos/listUtp?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
    },
    mounted() {
        this.loadAsyncData()
        this.getColegio()
    }
}
</script>
