<template>
    <div class="container" >
        <div class="box">
            <div class="columns">
                <div class="column">
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link :to="'/cursos/'">Cursos</router-link></li>
                            <li class="is-active"><a href="#" aria-current="page">Alumnos</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="columns">
                <div class="column">
                    <h1 class="title" v-show="curso">Alumnos - Curso {{curso.nivel.nombre}} {{curso.letra}} </h1>                   
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <section>
                        <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                            aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                            <b-table-column field="id" label="ID" sortable v-slot="props">
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="rut" label="RUT" sortable v-slot="props">
                                {{ props.row.rut }}
                            </b-table-column>

                            <b-table-column field="name" label="Nombres" sortable v-slot="props">
                                {{ props.row.name | truncate(30) }}
                            </b-table-column>

                            <b-table-column field="email" label="Email" sortable v-slot="props">
                                {{ props.row.email | truncate(30) }}
                            </b-table-column>

                            <b-table-column field="created_at" label="Creación" sortable v-slot="props">
                                {{ props.row.created_at ? new Date(props.row.created_at).toLocaleString('ES-CL') : 'unknown' }}
                            </b-table-column>                           

                            <template slot="empty">
                                <section class="section">
                                    <div class="content has-text-grey has-text-centered">
                                        <p>No se encontraron alumnos</p>
                                    </div>
                                </section>
                            </template>
                        </b-table>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            id: this.$route.params.id,
            curso: {
                nivel: {
                    nombre: null
                },
                letra: null
            },
            data: [],
            total: 0,
            loading: false,
            sortField: 'check',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            buscar_nombre: null
        }
    },
    methods: {      
        getCurso() {
            let params = {
                id: this.$route.params.curso
            }
            axios.post('/api/cursos/get', params)
                .then((res) => {
                    this.curso = res.data;
                }).catch((err) => {
                    throw err
                })
        },
        /*
         * Load async data
         */
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `buscar_nombre=${this.buscar_nombre}`,
                `curso=${this.$route.params.curso}`
            ].join('&')

            this.loading = true
            axios.get(`/api/cursos/usuarios/list_curso?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        // item.accesos = item.accesos_2021;
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },

    },
    filters: {
        /**
         * Filter to truncate string, accepts a length parameter
         */
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getCurso()
        this.loadAsyncData()
    }
}
</script>
