import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import wizard from "../middleware/wizard";
import utp from "../middleware/utp";

import SinColegio from "../views/error/SinColegio.vue";

import Configuracion from "../views/configuracion/Home.vue";
import Wizard from "../views/wizard/Home.vue";
import EsEstudiante from "../views/error/EsEstudiante.vue";

import Educacion from "../views/asignacion/Educacion.vue";
import AsignacionCurso from "../views/asignacion/Curso.vue";
import AsignacionAsignatura from "../views/asignacion/Asignatura.vue";
import AsignacionEvaluacion from "../views/asignacion/Evaluacion.vue";
import AsignacionResultado from "../views/asignacion/Resultado.vue";
import Resultado_por_estudiantes from "../views/asignacion/Resultado_por_estudiantes.vue";
import Resultado_estudiante from "../views/asignacion/Resultado_estudiante.vue";

import AplicarEvaluacion from "../views/aplicar/Evaluacion.vue";
import AplicarRemedial from "../views/evaluaciones/Remedial.vue";
import EvaluacionEnCurso from "../views/aplicar/EvaluacionEnCurso.vue";
import Tabulacion from "../views/aplicar/Tabulacion.vue";
import TabulacionRubrica from "../views/aplicar/TabulacionRubrica.vue";
import TabulacionRevisionConRubricas from "../views/aplicar/TabulacionRevisionConRubricas.vue";

import EvaluacionesMisEvaluaciones from "../views/evaluaciones/MisEvaluaciones.vue";
import EvaluacionesEnCurso from "../views/evaluaciones/EnCurso.vue";
import EvaluacionesFinalizadas from "../views/evaluaciones/Finalizadas.vue";

import RecursosDigitales from "../views/recursosdigitales/asignaturas.vue";
import RecursosDigitalesNiveles from "../views/recursosdigitales/niveles.vue";
import RecursosDigitalesRecursos from "../views/recursosdigitales/recursos.vue";

import CrearEvaluacionNivel from "../views/crear/evaluacion/Nivel.vue";
import CrearEvaluacionAsignatura from "../views/crear/evaluacion/Asignatura.vue";
import CrearEvaluacionEvaluaciones from "../views/crear/evaluacion/Evaluaciones.vue";
import CrearEvaluacionObjetivos from "../views/crear/evaluacion/Objetivos.vue";
import CrearEvaluacionPrueba from "../views/crear/evaluacion/Prueba.vue";
import BancoPreguntas from "../views/crear/evaluacion/BancoPreguntas.vue";
import CrearEvaluacionRubrica from "../views/crear/evaluacion/Rubrica.vue";
import CrearEvaluacionListaCotejo from "../views/crear/evaluacion/ListaCotejo.vue";
import CrearEvaluacionEscalaApreciacion from "../views/crear/evaluacion/EscalaApreciacion.vue";
import PruebaNombre from "../views/crear/evaluacion/AgregarNombre.vue";

import PruebaPDF from "../views/asignacion/pruebaPDF.vue";
import ConfiguracionMisUsuarios from "../views/configuracion/ConfiguracionMisUsuarios.vue";
import ConfiguracionUsuarioEditar from "../views/configuracion/ConfiguracionUsuarioEditar.vue";
import ConfiguracionUsuarioCrear from "../views/configuracion/ConfiguracionUsuarioEditar.vue";
import ConfiguracionMiCuenta from "../views/configuracion/ConfiguracionMiCuenta.vue";
PruebaPDF
import Resumen from "../views/asignacion/Resumen.vue";

import Cursos from "../views/utp/cursos/List.vue";
import CursosCrear from "../views/utp/cursos/Form.vue";
import CursosAlumnos from "../views/utp/cursos/alumnos/List.vue";

import UtpPrincipal from "../views/utp/cursos/Principal.vue";
import UtpGraficos from "../views/utp/cursos/Graficos.vue";
import UtpCurso from "../views/utp/cursos/Curso.vue";
import CargaCsvRespuestas from "../views/asignacion/CargaCSVRespuestas.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/configuracion/inicial",
        name: "Wizard",
        component: Wizard,
    },
    {
        path: "/error/8005",
        name: "SinColegio",
        component: SinColegio,
    },
    {
        path: "/configuracion",
        name: "Configuracion",
        component: Configuracion,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/asignacion/educacion/:asignatura",
        name: "Educacion",
        component: Educacion,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/asignacion/cursos/:asignatura/:educacion?",
        name: "AsignacionCurso",
        component: AsignacionCurso,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/asignacion/asignatura",
        name: "AsignacionAsignatura",
        component: AsignacionAsignatura,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/asignacion/cursos/:nivel/:curso/:asignatura",
        name: "AsignacionEvaluacion",
        component: AsignacionEvaluacion,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/aplicar/evaluacion/:asignatura/:curso/:prueba",
        name: "AplicarEvaluacion",
        component: AplicarEvaluacion,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/aplicar/remedial/:asignacion_id/:asignatura_id/:curso_id/:prueba_id",
        name: "AplicarRemedial",
        component: AplicarRemedial,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/aplicar/evaluacion/en_curso/:asignatura/:curso/:prueba/:asignacion",
        name: "EvaluacionEnCurso",
        component: EvaluacionEnCurso,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/aplicar/evaluacion/tabulacion/:asignatura/:curso/:prueba/:asignacion/:alumno",
        name: "Tabulacion",
        component: Tabulacion,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/asignacion/resultado/:asignacion",
        name: "AsignacionResultado",
        component: AsignacionResultado,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/aplicar/evaluacion/tabulacion/rubrica/:asignatura/:curso/:prueba/:asignacion/:alumno",
        name: "TabulacionRubrica",
        component: TabulacionRubrica,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/aplicar/evaluacion/tabulacion/con/rubricas/:asignatura/:curso/:prueba/:asignacion/:alumno?",
        name: "TabulacionRevisionConRubricas",
        component: TabulacionRevisionConRubricas,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/asignacion/resultadosporestudiantes/:asignacion",
        name: "ResultadoPorEstudiantes",
        component: Resultado_por_estudiantes,
        meta: {
            middleware: wizard,
        }
    },
    {
        path: "/asignacion/resultado/:asignacion/estudiante/:alumno",
        name: "ResultadoEstudiante",
        component: Resultado_estudiante,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/prueba/pdf",
        name: "pruebaPDF",
        component: PruebaPDF,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/evaluaciones/en_curso",
        name: "EvaluacionesEnCurso",
        component: EvaluacionesEnCurso,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/evaluaciones/finalizadas",
        name: "EvaluacionesFinalizadas",
        component: EvaluacionesFinalizadas,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/recursosdigitales",
        name: "RecursosDigitales",
        component: RecursosDigitales,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/recursosdigitales/:asignatura",
        name: "RecursosDigitalesNiveles",
        component: RecursosDigitalesNiveles,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/recursosdigitales/:asignatura/:nivel",
        name: "RecursosDigitalesRecursos",
        component: RecursosDigitalesRecursos,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/crear/evaluacion/niveles",
        name: "CrearEvaluacionNivel",
        component: CrearEvaluacionNivel,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas",
        name: "CrearEvaluacionAsignatura",
        component: CrearEvaluacionAsignatura,
        meta: {
            middleware: wizard,
        }
    },
    {
        path: "/evaluaciones/mis_evaluaciones",
        name: "EvaluacionesMisEvaluaciones",
        component: EvaluacionesMisEvaluaciones,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/prueba/nombre/:prueba",
        name: "PruebaNombre",
        component: PruebaNombre,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/objetivos/:tipo/:prueba?",
        name: "CrearEvaluacionObjetivos",
        component: CrearEvaluacionObjetivos,
        meta: {
            middleware: wizard,
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/evaluaciones",
        name: "CrearEvaluacionEvaluaciones",
        component: CrearEvaluacionEvaluaciones,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/:tipo/prueba/:prueba/banco",
        name: "BancoPreguntas",
        component: BancoPreguntas,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/:tipo/prueba/:prueba",
        name: "CrearEvaluacionPrueba",
        component: CrearEvaluacionPrueba,
        meta: {
            middleware: wizard
        },
    },
    {

        path: "/configuracion/usuarios",
        name: "configuracionMisUsuarios",
        component: ConfiguracionMisUsuarios,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/:tipo/rubrica/:prueba",
        name: "CrearEvaluacionRubrica",
        component: CrearEvaluacionRubrica,

        meta: {
            middleware: wizard
        },
    },
    {

        path: "/configuracion/docente/:id/editar",
        name: "configuracionUsuarioEditar",
        component: ConfiguracionUsuarioEditar,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/:tipo/listacotejo/:prueba",
        name: "CrearEvaluacionListaCotejo",
        component: CrearEvaluacionListaCotejo,
        meta: {
            middleware: wizard
        },
    },
    {

        path: "/configuracion/docente/crear",
        name: "configuracionUsuarioCrear",
        component: ConfiguracionUsuarioCrear,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/configuracion/cuenta",
        name: "configuracionMiCuenta",
        component: ConfiguracionMiCuenta,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/crear/evaluacion/niveles/:nivel/asignaturas/:asignatura/:tipo/escalaapreciacion/:prueba",
        name: "CrearEvaluacionEscalaApreciacion",
        component: CrearEvaluacionEscalaApreciacion,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/error/8007",
        name: "EsEstudiante",
        component: EsEstudiante,
    },
    {
        path: "/resumen/:asignacion_id",
        name: "resumen",
        component: Resumen,
    },
    {
        path: "/asignacion/respuestascsv/:asignacion",
        name: "CargaCsvRespuestas",
        component: CargaCsvRespuestas,
        meta: {
            middleware: wizard
        },
    },
    {
        path: "/cursos",
        name: "Cursos",
        component: Cursos,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/cursos/crear",
        name: "CursosCrear",
        component: CursosCrear,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/cursos/alumnos/:curso",
        name: "CursosAlumnos",
        component: CursosAlumnos,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/utp/principal",
        name: "UtpPrincipal",
        component: UtpPrincipal,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/utp/graficos",
        name: "UtpGraficos",
        component: UtpGraficos,
        meta: {
            middleware: utp
        },
    },
    {
        path: "/utp/dash",
        name: "UtpCurso",
        component: UtpCurso,
        meta: {
            middleware: utp
        },
    }
    // {
    //     path: '/404',
    //     name: 'Notfound'
    // },
    // {
    //     path: '*',
    //     redirect: '/404'
    // }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index, 1);
        subsequentMiddleware({
            ...context,
            next: nextMiddleware
        });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ?
            to.meta.middleware : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({
            ...context,
            next: nextMiddleware
        });
    }

    return next();
});

export default router;
