<template>
<div class="container" >
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Configuración</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <template>
                    <b-tabs type="is-boxed" expanded :animated="false">
                        <b-tab-item label="Mi cuenta" icon-pack="fa" icon="user">

                            <ConfiguracionMiCuenta />

                        </b-tab-item>
                        <b-tab-item label="Mis asignaturas" icon="google-classroom">

                            <ConfiguracionMisAsignaturas :method="parentMethod" />

                        </b-tab-item>
                        <b-tab-item label="Mis cursos" icon-pack="fa" icon="chalkboard-teacher">

                            <ConfiguracionMisCursos :key="componentKey" />

                        </b-tab-item>
                    </b-tabs>
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ConfiguracionMiCuenta from './ConfiguracionMiCuenta'
import ConfiguracionMisCursos from './ConfiguracionMisCursos'
import ConfiguracionMisAsignaturas from './ConfiguracionMisAsignaturas'
import Colegios from '../../components/Colegios.vue'
export default {
    metaInfo: {
        title: 'Configuración'
    },
    components: {
        ConfiguracionMiCuenta,
        ConfiguracionMisCursos,
        ConfiguracionMisAsignaturas,
        Colegios
    },
    data() {
        return {
            componentKey: 0
        }
    },
    methods: {
        parentMethod() {
             this.componentKey += 1;
        }
    }
}
</script>
