<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/configuracion">Configuración</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Inicial</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">

        <b-steps v-model="activeStep">
            <b-step-item label="Asignaturas" clickable icon="google-classroom">
                <h1 class="title is-3">Bienvenid@ {{user.name}}</h1>
                <p>Para comenzar a utilizar la plataforma, por favor selecciona las asignaturas que impartes en el colegio. Cuando termines, selecciona el icono de <b-icon
                icon="chalkboard-teacher" pack="fa">
            </b-icon> cursos para asociar tus cursos.</p>
                <br>
                <ConfiguracionMisAsignaturas :method="parentMethod" />
            </b-step-item>
            <b-step-item label="Cursos" clickable icon-pack="fa" icon="chalkboard-teacher">
                <h1 class="title is-3">Bienvenid@ {{user.name}}</h1>
                <p>Una vez que termines de seleccionar tus cursos pincha en el botón <strong>he terminado de configurar mi perfil.</strong></p>
                <div class="has-text-right">
                    <b-button @click="terminar" :loading="buttonTerminar" class="mt-2" type="is-success" icon-left="check" outlined>He terminado de configurar mi perfil</b-button>
                </div>
                <br>
                <ConfiguracionMisCursos :key="componentKey" />
            </b-step-item>
        </b-steps>

    </div>
</div>
</template>
<script>
import {
    mapGetters
} from 'vuex'
import Colegios from '../../components/Colegios.vue'
import ConfiguracionMisAsignaturas from '../configuracion/ConfiguracionMisAsignaturas'
import ConfiguracionMisCursos from '../configuracion/ConfiguracionMisCursos'
import axios from 'axios'

export default {
    name: "wizard",
    metaInfo: {
        title: 'Configuración inicial'
    },
    components: {
        Colegios,
        ConfiguracionMisAsignaturas,
        ConfiguracionMisCursos
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },
    data() {
        return {
            activeStep: 0,
            componentKey: 0,
            buttonTerminar: false
        }
    },
    methods: {
        parentMethod() {
              this.componentKey += 1;
        },
        terminar(){

            this.buttonTerminar = true;

            axios.post('/api/usuarios/wizard').then((res) => {
                this.buttonTerminar = false;
                if (res.data.status) {
                    location.href = "/";
                }

            }).catch((err) => {
                this.buttonTerminar = false;
                throw err
            })
        }
    }
}
</script>
