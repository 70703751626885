<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Asignación</router-link>
                        </li>
                        <li>
                            <router-link to="/asignacion/cursos">Cursos</router-link>
                        </li>
                        <li>
                            <router-link :to="'/asignacion/cursos/' + this.$route.params.nivel + '/' + this.$route.params.curso + ''">Asignaturas</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Evaluaciones</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <section>
                    <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                        aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                        <b-table-column field="id" label="ID" sortable v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="asignatura_id" label="Asignatura" sortable v-slot="props">
                            <span :class="'tag is-' + props.row.asignatura.imagen + ''">{{ props.row.asignatura.nombre }}</span>
                        </b-table-column>

                        <b-table-column field="nombre" label="Nombre" sortable v-slot="props">
                            {{ props.row.nombre }}
                        </b-table-column>

                        <b-table-column field="nivel_id" label="Nivel" sortable v-slot="props" v-if="curso">
                            {{ props.row.nivel.nombre }} {{curso.letra}}
                        </b-table-column>

                        <b-table-column field="prueba_tipo_id" label="Tipo" sortable v-slot="props">
                            {{ props.row.prueba_tipo.nombre }}
                        </b-table-column>

                        <b-table-column field="prueba_etapa_id" label="Etapa" sortable v-slot="props">
                            {{ props.row.prueba_etapa.nombre }}
                        </b-table-column>

                        <b-table-column label="Asignaciones" v-slot="props">
                            <ul v-for="asignacion in props.row.asignaciones" v-bind:key="asignacion.id" style="list-style-type:disc;">
                                <li class="is-size-7">{{ asignacion.name }} | {{ getDate(asignacion.inicio) }}</li>
                            </ul>
                        </b-table-column>
                        

                        <b-table-column label="Acciones" v-slot="props">
                            <b-dropdown :triggers="['hover']" aria-role="list">
                                <template #trigger>
                                    <b-button
                                        icon-right="dots-vertical" />
                                </template>

                                <b-dropdown-item aria-role="listitem" @click="aplicar(props.row.id)">Aplicar evaluación</b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" @click="ver(props.row.id)">Descargar evaluación en PDF</b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" @click="descargarTablaEspecificaciones(props.row.id)">Descargar tabla especificaciones</b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" v-if="props.row.evaluacion_tipo_id == 1" @click="abreModalSeleccionarObjetivos(props.row.id)"> <b-tag  type="is-success" rounded>Nuevo</b-tag> Crear evaluación a partir de esta</b-dropdown-item>
                            </b-dropdown>
                        </b-table-column>

                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>No se encontraron evaluaciones</p>
                                </div>
                            </section>
                        </template>
                    </b-table>
                </section>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Colegios from '../../components/Colegios.vue'
import Objetivos from '../../components/crearDesde/Objetivos.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "Evaluaciones",
    metaInfo: {
        title: 'Seleccionar evaluación'
    },
    components: {
        Colegios
    },
    data() {
        return {
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            curso: null
        }
    },
    methods: {
        abreModalSeleccionarObjetivos(id){
            this.$buefy.modal.open({
                parent: this,
                component: Objetivos,
                trapFocus: true,
                hasModalCard: true,
                props: {
                    prueba: id
                }
            })
        },
        getDate(date) {
            return moment(date).locale('es').format('LLL')
        },
        aplicar(prueba) {
            let asignatura = this.$route.params.asignatura;
            let curso = this.$route.params.curso;
            return this.$router.push({
                name: 'AplicarEvaluacion',
                params: {
                    asignatura: asignatura,
                    curso: curso,
                    prueba: prueba
                }
            });
        },
        ver(id) {
            window.open(process.env.VUE_APP_API_URL + '/prueba/' + id +'/'+ this.$route.params.nivel );
        },
        descargar(id) {
            window.open(process.env.VUE_APP_API_URL + '/prueba/descargar/' + id);
        },
        descargarTablaEspecificaciones(id){
            window.open(process.env.VUE_APP_API_URL + '/excel/exportTablaEspecificaciones/'+id); 
        },
        descargarRespuestas(id) {
            window.open(process.env.VUE_APP_API_URL + '/excel/exportRespuesta/'+id);
        },
        getCurso() {
            axios.post('/api/cursos/curso', {
                curso_id: this.$route.params.curso
            }).then((res) => {
                this.curso = res.data;
            }).catch((err) => {
                throw err
            })
        },
        /*
         * Load async data
         */
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `page=${this.page}`,
                `asignatura=${this.$route.params.asignatura}`,
                `nivel=${this.$route.params.nivel}`,
                `curso=${this.$route.params.curso}`,
            ].join('&')

            this.loading = true
            axios.get(`/api/pruebas/list?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
    },
    mounted() {
        this.loadAsyncData(),
        this.getCurso()
    }
}
</script>
