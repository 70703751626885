<template>
<div>
    <b-navbar class="container aeduc-menu">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="/assets/img/logo-evalua.png" alt="Aeduc">
            </b-navbar-item>
        </template>
        <template slot="start">
            <b-navbar-item tag="router-link" to="/">
                <h1 @click="gohome()">Cambiar de plataforma</h1>
            </b-navbar-item>

            <b-navbar-item tag="router-link" to="/crear/evaluacion/niveles">
                Crear evaluaciones
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/recursosdigitales">
                Recursos digitales
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/asignacion/asignatura">
                Aplicar evaluación
            </b-navbar-item>
            <b-navbar-dropdown label="Mis evaluaciones">
                <b-navbar-item tag="router-link" to="/evaluaciones/mis_evaluaciones" style="color: #363636">
                    <span>Mis evaluaciones</span>
                </b-navbar-item>
                <!-- <b-navbar-item tag="router-link" to="/listado/cursos" style="color: #363636">
                    <span>Lista de cursos</span>
                </b-navbar-item> -->
                <b-navbar-item tag="router-link" to="/evaluaciones/en_curso" style="color: #363636">
                    <span>Evaluaciones en curso</span>
                </b-navbar-item>
                <b-navbar-item tag="router-link" to="/evaluaciones/finalizadas" style="color: #363636">
                    <span>Evaluaciones finalizadas</span>
                </b-navbar-item>

            </b-navbar-dropdown>
            <b-navbar-dropdown label="Configuraciones" v-if="acceso.rol_id == 5">
                <!-- <b-navbar-item tag="router-link" to="/configuracion/cuenta" style="color: #363636">
                    <span>Ajuste de cuenta</span>
                </b-navbar-item>
                <b-navbar-item tag="router-link" to="#" style="color: #363636">
                    <span>Mis Asignaturas</span>
                </b-navbar-item> -->
                <b-navbar-item tag="router-link" to="/configuracion/usuarios" style="color: #363636">
                    <span>Docentes</span>
                </b-navbar-item>
                <b-navbar-item tag="router-link" to="/cursos" style="color: #363636">
                    <span>Cursos</span>
                </b-navbar-item>
                <b-navbar-item tag="router-link" to="/utp/principal" style="color: #363636">
                    <span>UTP Dashboard</span>
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>

        <template slot="end" v-if="authenticated">
            <b-navbar-dropdown :label="user.name">
                <b-navbar-item tag="router-link" to="/configuracion" style="color: #363636">
                    <b-icon icon="cog" pack="fas"></b-icon><span>Configuración</span>
                </b-navbar-item>
                <b-navbar-item href="#" v-on:click="logout()" style="color: #363636">
                    <b-icon icon="sign-out-alt" pack="fas"></b-icon><span>Cerrar sesión</span>
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
    <section class="mt-5">
        <!-- <div class="container">
            <div class="is-block">
                <a onclick="window.history.go(-1); return false;" class="subtitle is-block is-6 has-text-white"><i class="fa fa-chevron-left has-margin-right-7"></i>Volver</a>
                <div class="columns">
                    <div class="column has-padding-top-7">
                        <h3 class="title is-3 has-text-white">Titulo dinamico</h3>
                    </div>
                    <div class="column is-one-third has-padding-top-7">
                        <div class="is-pulled-right aeduc-cambiar-colegio">

                        </div>
                    </div>
                </div>
            </div>
        </div>> -->
        <router-view />
        <vue-progress-bar></vue-progress-bar>
    </section>
</div>
</template>

<script>

import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Home',
        // all titles will be injected into this template
        titleTemplate: '%s | AEDUC Evalúa'
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
            acceso: 'auth/acceso'
        })
    },

    methods: {
        ...mapActions({
            signOutAction: 'auth/signOut'
        }),

        async logout() {
            await this.signOutAction()

            location.href = process.env.VUE_APP_LOGIN_URL
        },
        gohome(){
            location.href=process.env.VUE_APP_HOME_URL;
        }
    },
    mounted() {
        //  [App.vue specific] When App.vue is finish loading finish the progress bar
        this.$Progress.finish()
    },
    created() {
        //  [App.vue specific] When App.vue is first loaded start the progress bar
        this.$Progress.start()
        //  hook the progress bar to start before we move router-view
        this.$router.beforeEach((to, from, next) => {
            //  does the page we want to go to have a meta.progress object
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress
                // parse meta tags
                this.$Progress.parseMeta(meta)
            }
            //  start the progress bar
            this.$Progress.start()
            //  continue to next page
            next()
        })
        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach(() => {
            //  finish the progress bar
            this.$Progress.finish()
        })
    }
}
</script>