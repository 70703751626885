<template>
<div>
    <div v-for="(programa_array, programa) in programas">

        <div class="columns">
            <div class="column">
                <h1 class="title is-5">{{programa}}</h1>
            </div>
        </div>

        <div v-for="(madres_array, madres) in programa_array">

            <div class="columns" v-if="madres_array.length > 1">
                <div class="column">
                    <h1 class="title is-6">{{madres}}</h1>
                </div>
            </div>

            <table class="table is-fullwidth">
                <tbody>
                    <tr v-for="asignatura in madres_array">
                        <td> <span :class="'has-text-' + asignatura.imagen">
                                <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                    <circle cx="50" cy="50" r="40"></circle>
                                </svg>
                            </span>
                        </td>
                        <td width="80%">{{ asignatura.nombre}}</td>
                        <td width="20%">
                            <b-button :loading="loadingButton[asignatura.id]" v-if="asignatura.ramos" class="is-small" type="is-success" icon-left="check" icon-pack="fas" @click="deseleccionar(asignatura.id)">Seleccionado
                            </b-button>
                            <b-button :loading="loadingButton[asignatura.id]" outlined v-else class="is-small"  @click="seleccionar(asignatura.id)">Seleccionar</b-button>
                        </td>
                    </tr>
                </tbody>
            </table>


            <!-- <div >
                <div class="column is-4" v-for="asignatura in madres_array" v-bind:key="asignatura.id">
                    <article :class="'message is-' + asignatura.imagen">
                        <div class="message-body">
                            <div class="media">
                                <div class="media-left">
                                    <figure class="image is-32x32">
                                        <img :src="'/assets/img/asignaturas/' + asignatura.imagen + '.svg'" :alt="asignatura.imagen">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <h1 class="title is-5">{{asignatura.nombre}}</h1>
                                </div>
                            </div>

                            <p class="mt-5">Pincha el botón seleccionar para elegir la asignatura</p>

                            <b-button :loading="loadingButton[asignatura.id]" v-if="asignatura.ramos.length" class="mt-5" :type="'is-' + asignatura.imagen" icon-left="check" icon-pack="fas" @click="deseleccionar(asignatura.id)">Seleccionado
                            </b-button>
                            <b-button :loading="loadingButton[asignatura.id]" outlined v-else class="mt-5" :type="'is-' + asignatura.imagen" @click="seleccionar(asignatura.id)">Seleccionar</b-button>
                        </div>
                    </article>
                </div>
            </div> -->

        </div>

        <hr>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        method: {
            type: Function
        }
    },
    data() {
        return {
            programas: [],
            loadingButton: []
        }
    },
    methods: {
        seleccionar(asignatura) {

            this.$set(this.loadingButton, asignatura, true)

            axios.post('/api/asignaturas/asignar/usuario', {
                asignatura: asignatura
            }).then((res) => {
                if (res.data.status) {
                    this.getAsignaturas()
                    this.method()
                }
            }).catch((err) => {
                throw err
            })
        },
        deseleccionar(asignatura) {

            this.$set(this.loadingButton, asignatura, true)

            axios.post('/api/asignaturas/desasignar/usuario', {
                asignatura: asignatura
            }).then((res) => {
                if (res.data.status) {
                    this.getAsignaturas()
                    this.method()
                }
            }).catch((err) => {
                throw err
            })
        },
        getAsignaturas() {
            axios.post('/api/asignaturas/usuario/configuracion/get').then((res) => {
                this.programas = res.data;
                Object.values(res.data).forEach((item) => {
                    Object.values(item).forEach((asig) => {
                        Object.values(asig).forEach((a) => {
                            console.log(a);
                            this.$set(this.loadingButton, a.id, false)
                        })
                    })
                })
            }).catch((err) => {
                throw err
            })
        }
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getAsignaturas()
    }
}
</script>
