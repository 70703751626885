<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>                       
                        <li class="is-active"><a href="#" aria-current="page">Recursos Digitales</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
       <h1 class="title">Asignaturas </h1>
    </div>
    <div class="box">        
        <table class="table is-fullwidth">
            <tbody>
                <tr >
                    <td> <span :class="'has-text-' + 'lenguaje'">
                            <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                <circle cx="50" cy="50" r="40"></circle>
                            </svg>
                        </span>
                    </td>
                    <td width="80%">Lenguaje</td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ 1">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> <span :class="'has-text-' + 'matematica'">
                            <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                <circle cx="50" cy="50" r="40"></circle>
                            </svg>
                        </span>
                    </td>
                    <td width="80%">Matematicas</td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+2">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> <span :class="'has-text-' + 'historia'">
                            <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                <circle cx="50" cy="50" r="40"></circle>
                            </svg>
                        </span>
                    </td>
                    <td width="80%">Historia</td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+3">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> <span :class="'has-text-' + 'ciencias'">
                            <svg viewBox="0 0 95 130" style="height: 1.5em; fill: currentcolor;">
                                <circle cx="50" cy="50" r="40"></circle>
                            </svg>
                        </span>
                    </td>
                    <td width="80%">Ciencias</td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+4">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "RecursosDigitalesAsignatura",
    metaInfo: {
        title: 'Recursos digitales - asignatura'
    },
    components: {
        Colegios
    },
    data() {
        return {
           
        }
    },
    watch: {
       
    },
    methods: {
      
    },
    mounted() {
       

    }
}
</script>
