<template >
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Configuracion de Docentes</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
        <div class="box">
            <div class="columns">
                <div class="column is-7">
                    <b-field class="file is-success" :class="{'has-name': !!file}">
                        <b-upload v-model="file" class="file-label" rounded>
                        <span class="file-cta">
                            <b-icon icon="upload"></b-icon>
                            <span class="file-label">{{ file.name || "Seleccione excel de Docentes"}}</span>
                        </span>
                        </b-upload>
                    </b-field> 
                    <b-button @click="cargar" :loading="loadingStore">Cargar</b-button>           
                </div>
                <div class="column is-5">
                    <b-button tag="router-link" to="/configuracion/docente/crear" type="is-primary mr-2" rounded icon-left="fas fa-plus" icon-pack="fas">Crear usuario</b-button>
                    <b-button type="is-success " @click="formato()" rounded>    
                        <b-icon 
                            pack="fas"
                            icon="file-excel"
                            size="is-small">
                        </b-icon>
                        <span>Formato archivo excel   </span>
                    </b-button>
                </div>
            </div>
        </div>     
        <div class="box">
            <!-- <div class="columns">
                <div class="column is-4">
                    <b-field>
                        <b-input v-model="buscar_nombre" placeholder="Buscar RUT o nombre" type="search" icon="magnify">
                        </b-input>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field>
                        <b-input placeholder="Buscar email" type="search" v-model="buscar_email" icon="email">
                        </b-input>
                    </b-field>
                </div>
                <div class="column is-2">
                    <b-button type="is-info" icon-left="magnify" @click="loadAsyncData">Buscar o refrescar</b-button>
                </div>
            </div>             -->
            <div class="columns">
                <div class="column">
                    <section>
                        <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                            aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                            <b-table-column field="id" label="ID" sortable v-slot="props">
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="name" label="Nombres" sortable v-slot="props">
                                {{ props.row.name | truncate(30) }}
                            </b-table-column>

                            <b-table-column field="email" label="Email" sortable v-slot="props">
                                {{ props.row.email | truncate(30) }}
                            </b-table-column>

                            <b-table-column label="Acciones" v-slot="props">
                                <div class="buttons" v-if="props.row.rol_id !== 1">
                                    <b-button type="button" class="button is-small is-info is-outlined" tag="router-link" :to="'/configuracion/docente/' + props.row.id + '/editar'" name="button"> <i class="fas fa-edit"></i> Editar</b-button>
                                    <button @click="eliminar(props.row.id)" type="button" class="button is-small is-danger is-outlined" name="button"> <i class="fas fa-trash"></i> Eliminar</button>
                                </div>
                            </b-table-column>
                            <template slot="empty">
                                <section class="section">
                                    <div class="content has-text-grey has-text-centered">
                                        <p>No se encontraron usuarios</p>
                                    </div>
                                </section>
                            </template>
                        </b-table>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    components: {
        Colegios
    },
    data() {
        return {
            file:{},
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            buscar_nombre: null,
            buscar_email: null,
            rol: null,
            colegio: null,
            loadingStore: false,
        }
    },
    methods: {
        cargar(){
            let self = this

            if (!this.file) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar un archivo excel con los docentes`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                return
            }
            self.loadingStore = true;
            let formData = new FormData();
            formData.append('file', this.file);

            axios.post('/api/usuario/cargar',formData)
                .then(response => {
                    self.loadingStore = false;
                        this.$buefy.toast.open({
                            message: response.data.message,
                            type: 'is-success'
                        })
                })
                .catch(error => {
                     self.loadingStore = false;
                    this.$buefy.toast.open({
                        message: 'no se pudo crear los docentes',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    self.loadingStore = false;
                    throw error
                });
                 this.loadAsyncData()
        },
        formato(){
             window.open(process.env.VUE_APP_API_URL + '/excel/formato/docente/download');
        },
        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar usuario',
                message: '¿Estás seguro que deseas <b>eliminar</b> el usuario ?. Esta accion no se puede revertir.',
                confirmText: 'Eliminar usuario',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                        id : id
                    }
                    axios.post('/api/usuarios/delete', params)
                        .then(response => {
                            this.$buefy.toast.open({
                                message: response.data.message,
                                type: 'is-success'
                            })
                            this.loadAsyncData()
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo eliminar el usuario',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
            })
        },

        /*
         * Load async data
         */
        loadAsyncData() {
            let rol = this.rol
            if (!rol) {
                rol = null
            }
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `buscar_nombre=${this.buscar_nombre}`,
                `buscar_email=${this.buscar_email}`,
                `buscar_rol=${rol}`,
                `buscar_colegio=${this.colegio}`
            ].join('&')

            this.loading = true
            axios.get(`/api/usuarios/list?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        // item.accesos = item.accesos_2021;
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
        /*
         * Type style in relation to the value
         */
        type(value) {
            const number = parseFloat(value)
            if (number < 6) {
                return 'is-danger'
            } else if (number >= 6 && number < 8) {
                return 'is-warning'
            } else if (number >= 8) {
                return 'is-success'
            }
        }
    },
    filters: {
        /**
         * Filter to truncate string, accepts a length parameter
         */
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.loadAsyncData()
    }
}
</script>