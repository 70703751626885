<template>
<div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title">Indicadores</p>
    </header>
    <section class="modal-card-body">
        <b-tabs>
            <b-tab-item label="Currículum nacional">
                <div class="columns is-multiline">
                    <div class="column is-12" v-for="indicador in indicadores" v-bind:key="indicador.id">

                        <div class="card">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    {{indicador.numero}}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-button @click="selectIndicador(indicador)">Seleccionar</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{indicador.descripcion}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Creados">
                <div class="columns" v-show="show">
                    <div class="column">
                        <b-input type="textarea" v-model="descripcionIndicador" maxlength="200"></b-input>
                        <b-button type="is-primary" @click="crearIndicador">Crear Indicador</b-button>
                    </div>
                </div>
                <div v-if="indicadoresCreados.length" class="columns is-multiline">
                    <div class="column is-12" v-for="indicador in indicadoresCreados" v-bind:key="indicador.id">

                        <div class="card">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    {{indicador.numero}}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-button @click="selectIndicador(indicador)">Seleccionar</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{indicador.descripcion}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="card" style="border-style: dashed;border-width:1px;border-color:#2496f5">
                            <div class="card-content has-text-centered">
                                <div class="columns">
                                    <div class="column">
                                        <b-button type="is-primary" rounded @click="show=true" outlined>Crear Indicador</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </section>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['nivel', 'asignatura', 'prueba', 'objetivo'],
    name: 'Indicadores',
    data() {
        return {
            show: false,
            isFullPage: false,
            isLoading: false,
            indicadores: [],
            indicadoresCreados: [],
            descripcionIndicador: null
        }
    },
    methods: {
        getIndicadoresCreados() {
            axios.get(`/api/indicadores/get/creados/${this.objetivo}`).then((res) => {
                this.indicadoresCreados = res.data
            }).catch((err) => {
                throw err
            });
        },
        crearIndicador() {
            axios.post('/api/indicadores/set/creados', {
                asignatura: this.asignatura,
                nivel: this.nivel,
                descripcion: this.descripcionIndicador,
                objetivo: this.objetivo
            }).then(() => {
                this.getIndicadoresCreados();
            }).catch((err) => {
                throw err
            })
        },
        getIndicadores() {
            let self = this;
            self.isLoading = true;
            axios.get(`/api/indicadores/get/${this.objetivo}`).then((res) => {
                this.indicadores = res.data
                self.isLoading = false;
            }).catch((err) => {
                throw err
            });
        },
        selectIndicador(args) {
            this.$emit('close')
            this.$emit("setIndicador", {
                'id': args.id,
                'descripcion': args.descripcion
            })
        }
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getIndicadores()
        this.getIndicadoresCreados()
    }
}
</script>
