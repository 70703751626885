<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Asignación</router-link>
                        </li>
                        <li>
                            <router-link to="/asignacion/asignatura">Asignaturas</router-link>
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Educación</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div id="cuerpo" class="box">
        <div id="nivelText" class="title is-4 has-text-primary">Educación</div>

        <div class="columns is-centered">
            <div class="column is-4 has-text-centered">
                <div id="router" @click="parvu()">
                    <div id="item" class="card">
                        <div id="titulo" class="column media-content">
                            <h1 class="title">
                                Educación<br />
                                Parvularia
                            </h1>
                        </div>

                        <div id="logo" class="card-image">
                            <figure class="image is-square ">
                                <img src="/assets/img/niveles/ed_parvularia.svg" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-4 has-text-centered">
                <div id="router" @click="basic()">
                    <div id="item" class="card">
                        <div id="titulo" class="column media-content">
                            <h1 class="title">
                                Educación<br />
                                Básica
                            </h1>
                        </div>

                        <div id="logo" class="card-image">
                            <figure class="image  is-square ">
                                <img src="/assets/img/niveles/ed_basica.svg" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-4 has-text-centered">
                <div id="router" @click="media()">
                    <div id="item" class="card">
                        <div id="titulo" class="column media-content">
                            <h1 class="title">
                                Educación<br />
                                Media
                            </h1>
                        </div>
                        <div id="logo" class="card-image">
                            <figure class="image is-square ">
                                <img src="/assets/img/niveles/ed_media.svg" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Colegios from "../../components/Colegios.vue";

export default {
    name: "Educacion",
    data() {
        return {
             asignatura: this.$route.params.asignatura
        };
    },
    components: {
        Colegios,
    },
    methods: {
        parvu() {
            return this.$router.push({
                name: "AsignacionCurso",
                params: {
                    asignatura:this.$route.params.asignatura,
                    educacion: 3,
                },
            });
        },
        basic() {
            return this.$router.push({
                name: "AsignacionCurso",
                params: {
                    asignatura:this.$route.params.asignatura,
                    educacion: 1,
                },
            });
        },
        media() {
            return this.$router.push({
                name: "AsignacionCurso",
                params: {
                    asignatura:this.$route.params.asignatura,
                    educacion: 2,
                },
            });
        },
    },
};
</script>
<style scoped>
#item {
 border: 1px solid rgba(0, 0, 0, 0.268);
 border-radius: 25px;
}
#item:hover{
    background-color:#69c6ff29;
    cursor: pointer;
}
</style>
