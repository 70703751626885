<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>                          <li>
                            <router-link :to="'/recursosdigitales'">Asignaturas</router-link>
                        </li>                       
                        <li class="is-active"><a href="#" aria-current="page">Niveles</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
       <h1 class="title">Niveles </h1>
    </div>
    <div class="box">        
        <table class="table is-fullwidth">
            <tbody>
                <tr v-show="asignatura == 1 || asignatura == 2">
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">NT1 </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 1">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr v-show="asignatura == 1 || asignatura == 2">
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">NT2 </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 2">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">1° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 3">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">2° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 4">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">3°BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 5">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">4° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 6">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">5° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 7">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">6° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 8">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">7° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 9">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">8° BASICO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 10">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">1° MEDIO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 11">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">2° MEDIO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 12">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">3° MEDIO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 13">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>
                <tr >
                    <td> 
                        <span >
                             <b-icon :icon="'folder'"></b-icon>
                        </span>
                    </td>
                    <td width="80%">4° MEDIO </td>
                    <td width="20%">
                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                        <router-link class="button is-small" :to="'/recursosdigitales/'+ asignatura + '/'+ 14">
                            Seleccionar
                        </router-link>
                    </td>
                </tr>                              
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "RecursosDigitalesNiveles",
    metaInfo: {
        title: 'Recursos digitales - niveles'
    },
    components: {
        Colegios
    },
    data() {
        return {
              asignatura:this.$route.params.asignatura,
        }
    },
    watch: {
       
    },
    methods: {
      
    },
    mounted() {
       

    }
}
</script>
