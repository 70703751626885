<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>                          <li>
                            <router-link :to="'/recursosdigitales'">Asignaturas</router-link>
                        </li>                            <li>
                            <router-link :to="'/recursosdigitales/'+asignatura">Niveles</router-link>
                        </li>                      
                        <li class="is-active"><a href="#" aria-current="page">Recursos</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
       <h1 class="title">Recursos </h1>
    </div>
    <div class="box">        
        <table class="table is-fullwidth">
            <tbody>
                <div v-for="recurso in recursos">
                    <div v-if="recurso == '.'"></div>
                    <div v-else-if="recurso == '..'"></div>
                    <div v-else-if="recurso == '.DS_Store'"></div>
                    <div v-else>
                                <tr >
                                    <td> 
                                        <span >
                                            <b-icon :icon="'download'"></b-icon>
                                        </span>
                                    </td>
                                    <td width="80%">{{recurso}} </td>
                                    <td width="20%">
                                        <!-- <router-link class="button is-small" :to="{ path: '/asignacion/cursos/' + nivel + '/' + curso + '/' + asignatura.id}"> -->
                                    <button class="button is-small" @click="getArchivo(recurso)">
                                            Descargar
                                        </button> 
                                    </td>
                                </tr>   
                    </div>
                    
                 
                </div>
                                         
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "RecursosDigitalesNiveles",
    metaInfo: {
        title: 'Recursos digitales - recursos'
    },
    components: {
        Colegios
    },
    data() {
        return {
              asignatura:this.$route.params.asignatura,
              nivel:this.$route.params.nivel,
              recursos:[]
        }
    },
    methods: {
      getRecursos(){
        axios.get('/api/recursos/get/'+this.asignatura+'/'+this.nivel, {
            }).then((res) => {
                this.recursos = res.data;
            }).catch((err) => {
                throw err
            })
      },
       getArchivo(recurso) {
              
            let self = this;
            let formData = new FormData();
            //formData.append("id", recurso.id);
            formData.append("nombre", recurso);
            formData.append("asignatura", this.$route.params.asignatura);
            formData.append("nivel", this.$route.params.nivel);
            self.loadingFile = true;
            axios
                .post("/api/recursos/download", formData, {
                responseType: "arraybuffer",
                })
                .then((res) => {
                let blob = new Blob([res.data], {
                    type: "application/*",
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = recurso;
                link.click();
                self.loadingFile = false;
            });
        },
    },
    mounted() {
       this.getRecursos();

    }
}
</script>
