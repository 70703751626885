<template>
<div class="container mt-5">
    <article class="message is-warning">
        <div class="message-body">
            Este módulo no es válido para estudiantes.
        </div>
    </article>
</div>
</template>

<script>
export default {}
</script>
