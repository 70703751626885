<template>
<div class="container">
        <div class="columns">
            <div class="column">
                <div class="box">
                    <h1 class="title is-3">DETALLE POR RESPUESTA</h1>
                    <div class="columns">
                        <div class="field is-horizontal">
                            <b-field label="Total preguntas">
                                {{resumen.total}}
                            </b-field>
                            <b-field label="Total alumnos" class="ml-5">
                                {{resumen.total_alumnos}}
                            </b-field>
                            <b-field label="Porcentaje correctas" class="ml-5">
                                {{resumen.porcentaje_correctas}} %
                            </b-field>
                            <b-field label="Porcentaje incorrectas" class="ml-5">
                                {{resumen.porcentaje_incorrectas}} %
                            </b-field>
                            <b-field label="Porcentaje omitidas" class="ml-5">
                                {{resumen.porcentaje_omitidas}} %
                            </b-field>
                            <b-field label="Aprobados" class="ml-5">
                                {{resumen.aprobados}}
                            </b-field>
                            <b-field label="Reprobados" class="ml-5">
                                {{resumen.reprobados}}
                            </b-field>
                        </div>

                    </div>
                        <h1 class="title is-3">PROMEDIO RENDIMIENTO POR EJES POR CURSO</h1>
                    <div class="columns">
                        <div class="field is-horizontal">
                            <div  v-for="eje in resumen.ejes" v-bind:key="eje.id">
                                <b-field :label='eje.eje.eje' class="mr-5">
                                    {{eje.porcentaje}} %
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <h1 class="title is-3">PROMEDIO RENDIMIENTO POR HABILIDADES POR CURSO</h1>
                    <div class="columns">
                        <div class="field is-horizontal">
                            <div  v-for="habilidad in resumen.habilidades" v-bind:key="habilidad.id">
                                <b-field :label='habilidad.habilidad.descripcion' class="mr-5" >
                                    {{habilidad.porcentaje}} %
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <h1 class="title is-3">PROMEDIO RENDIMIENTO POR OBJETIVOS POR CURSO</h1>
                    <div class="columns">
                        <div class="field is-horizontal">
                            <div  v-for="objetivo in resumen.objetivos" v-bind:key="objetivo.id">
                                <b-field :label='objetivo.objetivo.codigo' class="mr-5" >
                                    {{objetivo.porcentaje}} %
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <h1 class="title is-3">PROMEDIO RENDIMIENTO POR INDICADORES POR CURSO</h1>
                    <div class="columns">
                        <div class="field is-horizontal">
                            <div  v-for="indicador in resumen.indicadores" v-bind:key="indicador.id">
                                <b-field :label='indicador.indicador.descripcion' class="mr-5" >
                                    {{indicador.porcentaje}} %
                                </b-field>
                            </div>
                        </div>
                    </div>
                     <h1 class="title is-3">ESPECIFICACIÓN DESEMPEÑO POR ALUMNO</h1>
                    <div class="columns">
                      <table class="table">
                        <thead>
                            <tr>
                                <th>ALUMNO</th>
                                <th>TOTAL PC</th>
                                <th>TOTAL PI</th>
                                <th>TOTAL PO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="resumen in resumen.resumenes" v-bind:key="resumen.id">
                                <td>{{resumen.alumno.name}}</td>
                                <td>{{resumen.correctas}}</td>
                                <td>{{resumen.incorrectas}}</td>
                                <td>{{resumen.omitidas}}</td>
                           </tr>
                        </tbody>
                        </table>
                    </div>

                    <h1 class="title is-3">ESPECIFICACIÓN DESEMPEÑO POR EJE</h1>
                   <div class="columns">
                     <table class="table">
                       <thead>
                           <tr>
                               <th>ALUMNO</th>
                               <th>EJE</th>
                               <th>%</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr v-for="resumen in resumen.resumen_ejes_alumnos" v-bind:key="resumen.id">
                               <td>{{resumen.resumen.alumno.name}}</td>
                               <td>{{resumen.eje.eje}}</td>
                               <td>{{resumen.porcentaje}}</td>
                          </tr>
                       </tbody>
                       </table>
                   </div>

                   <h1 class="title is-3">ESPECIFICACIÓN DESEMPEÑO POR OBJETIVO</h1>
                  <div class="columns">
                    <table class="table">
                      <thead>
                          <tr>
                              <th>ALUMNO</th>
                              <th>OBJETIVO</th>
                              <th>%</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="resumen in resumen.resumen_objetivos_alumnos" v-bind:key="resumen.id">
                              <td>{{resumen.resumen.alumno.name}}</td>
                              <td>{{resumen.objetivo.codigo}}</td>
                              <td>{{resumen.porcentaje}}</td>
                         </tr>
                      </tbody>
                      </table>
                  </div>

                  <h1 class="title is-3">ESPECIFICACIÓN DESEMPEÑO POR INDICADOR</h1>
                 <div class="columns">
                   <table class="table">
                     <thead>
                         <tr>
                             <th>ALUMNO</th>
                             <th>INDICADOR</th>
                             <th>%</th>
                         </tr>
                     </thead>
                     <tbody>
                         <tr v-for="resumen in resumen.resumen_indicadores_alumnos" v-bind:key="resumen.id">
                             <td>{{resumen.resumen.alumno.name}}</td>
                             <td>{{resumen.indicador.descripcion}}</td>
                             <td>{{resumen.porcentaje}}</td>
                        </tr>
                     </tbody>
                     </table>
                 </div>

                 <h1 class="title is-3">ESPECIFICACIÓN DESEMPEÑO POR HABILIDAD</h1>
                <div class="columns">
                  <table class="table">
                    <thead>
                        <tr>
                            <th>ALUMNO</th>
                            <th>HABILIDAD</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="resumen in resumen.resumen_habilidades_alumnos" v-bind:key="resumen.id">
                            <td>{{resumen.resumen.alumno.name}}</td>
                            <td>{{resumen.habilidad.descripcion}}</td>
                            <td>{{resumen.porcentaje}}</td>
                       </tr>
                    </tbody>
                    </table>
                </div>

                    <h1 class="title is-3">RENDIMIENTO POR ALUMNO - NOTA</h1>
                    <div class="columns">
                      <table class="table">
                        <thead>
                            <tr>
                                <th>ALUMNO</th>
                                <th>CORRECTAS</th>
                                <th>%LOGRO</th>
                                <th>NOTA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="resumen in resumen.resumenes" v-bind:key="resumen.id">
                                <td>{{resumen.alumno.name}}</td>
                                <td>{{resumen.correctas}}</td>
                                <td>{{resumen.porcentaje_logro}}</td>
                                <td>{{resumen.nota}}</td>
                           </tr>
                        </tbody>
                        </table>
                    </div>
                    <h1 class="title is-3">DETALLE DE REPETICIONES A LAS ALTERNATIVAS POR PREGUNTA</h1>
                    <div class="columns">
                      <table class="table">
                        <thead>
                            <tr>
                                <th>NUMERO</th>
                                <th>CORRECTA</th>
                                <th>A</th>
                                <th>B</th>
                                <th>C</th>
                                <th>D</th>
                                <th>E</th>
                                <th>Omitidas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="resumen_pregunta in resumen.preguntas" v-bind:key="resumen_pregunta.id">
                                <td>{{resumen_pregunta.pregunta.orden}}</td>
                                <td>{{resumen_pregunta.pregunta.pregunta.clave}}</td>
                                <td>{{resumen_pregunta.alternativa_A}}</td>
                                <td>{{resumen_pregunta.alternativa_B}}</td>
                                <td>{{resumen_pregunta.alternativa_C}}</td>
                                <td>{{resumen_pregunta.alternativa_D}}</td>
                                <td>{{resumen_pregunta.alternativa_E}}</td>
                                <td>{{resumen_pregunta.pregunta.omitidas}}</td>
                           </tr>
                        </tbody>
                        </table>
                    </div>
                     <h1 class="title is-3">DETALLE DE PREGUNTA POR ORDEN DE REPETICIÓN</h1>
                    <div class="columns">
                      <table class="table">
                        <thead>
                            <tr>
                                <th>N° pregunta</th>
                                <th>Correctas Repeticiones </th>
                                <th>Incorrectas Repeticiones</th>
                                <th>Omitidas Repeticiones</th>                              
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="resumen_pregunta in resumen.preguntas" v-bind:key="resumen_pregunta.id">
                                <td>{{resumen_pregunta.pregunta.orden}}</td>
                                <td>{{resumen_pregunta.repeticion_correcta}}</td>
                                <td>{{resumen_pregunta.repeticion_incorrecta}}</td>
                                <td>{{resumen_pregunta.repeticion_omitida}}</td>
                           </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: "Resumen",
    metaInfo: {
        title: 'Resumen'
    },
    data() {
        return {
           asignacion: this.$route.params.asignacion_id,
           resumen:null
        }
    },
    methods: {
        Resumen() {
            axios.post('/api/informe/resumen', {id: this.$route.params.asignacion_id }).then((res) => {
                this.resumen = res.data;
                
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.Resumen();
    }
}
</script>
