import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: null,
        acceso: null
    },

    getters: {
        authenticated(state) {
            return state.authenticated
        },

        user(state) {
            return state.user
        },

        acceso(state) {
            return state.acceso
        }
    },

    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value
        },

        SET_USER(state, value) {
            state.user = value
        },

        SET_ACCESO(state, value) {
            state.acceso = value
        }
    },


    actions: {
        async signIn({
            dispatch
        }, credentials) {
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/login', credentials)

            return dispatch('me')
        },

        async signOut({
            dispatch
        }) {
            await axios.post('/logout')

            return dispatch('me')
        },

        async me({
            commit
        }) {
            try {
                let users = await axios.get('/api/user');
                if (users.data.user) {
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_USER', users.data.user)
                    commit('SET_ACCESO', users.data.acceso)
                    return true;
                } else {
                    location.href = process.env.VUE_APP_LOGIN_URL;
                    return false;
                }
            }catch (Exception) {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
                commit('SET_ACCESO', null)
                location.href = process.env.VUE_APP_LOGIN_URL;
                return false;
            }
        }
    }

}
