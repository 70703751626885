<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Seleccione los OA / Unidad Temática a trabajar</p>
        </header>
        <section class="modal-card-body">
            <div class="columns is-multiline">
                <div class="column is-12" v-for="objetivo in objetivos" v-bind:key="objetivo.id">

                    <div class="card">
                        <div class="card-content">
                            <div class="media">
                                <div class="media-content">
                                    <div class="field">
                                        <div class="field is-pulled-left">
                                            <p class="title is-5">
                                                <b-icon v-if="objetivo.priorizado == 1" icon="star" size="is-small"></b-icon>
                                                <b-icon v-if="objetivo.priorizado == 2" icon="star-outline" size="is-small">
                                                </b-icon> {{objetivo.codigo}}
                                            </p>
                                        </div>
                                        <div class="field is-pulled-right">
                                            <b-checkbox v-model="checkboxGroup"
                                                :native-value="objetivo.id">
                                                Seleccionar
                                            </b-checkbox>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="field">
                                        <p class="subtitle is-6 has-text-grey">{{objetivo.asignatura.nombre}} / {{objetivo.nivel.nombre}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="content">
                                <p>{{objetivo.descripcion}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column-12">
                    <b-button type="is-primary" @click="createEvaluacion" class="ml-3">Crear evaluación</b-button>
                </div>
            </div>
            <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['prueba'],
    name: 'CrearDesdeObjetivos',
    data() {
        return {
            isFullPage: false,
            isLoading: false,
            objetivos: [],
            checkboxGroup: []
        }
    },
    methods:{
        createEvaluacion(){
            let self = this;
            self.isLoading = true;
            axios.post(`/api/evaluacion/crear/desde`, { prueba: this.prueba, checkboxGroup: this.checkboxGroup }).then((res) => {
                self.isLoading = false;
                this.$emit('close')
                return this.$router.push({
                    name: 'CrearEvaluacionPrueba',
                    params: {
                        nivel: res.data.nivel,
                        asignatura: res.data.asignatura,
                        prueba: res.data.prueba,
                        tipo: res.data.tipo
                    }
                });
            }).catch((err) => {
                throw err
            });
        },
        getObjetivos() {
            let self = this;
            self.isLoading = true;
            axios.get(`/api/objetivos/evaluacion/${this.prueba}`).then((res) => {
                this.objetivos = res.data.objetivos
                self.isLoading = false;
            }).catch((err) => {
                throw err
            });
        },  
    },
    mounted() {
        this.getObjetivos()
    }
}
</script>