<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/">Crear evaluación</router-link>
                            </li>
                            <li>
                                <router-link to="/crear/evaluacion/niveles">Niveles</router-link>
                            </li>
                            <li>
                                <router-link :to="'/crear/evaluacion/niveles/' + route_nivel + '/asignaturas'"
                                    >Asignaturas</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/crear/evaluacion/niveles/' +
                                            route_nivel +
                                            '/asignaturas/' +
                                            asignatura +
                                            '/evaluaciones'
                                    "
                                    >Evaluaciones</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/crear/evaluacion/niveles/' +
                                            this.$route.params.nivel +
                                            '/asignaturas/' +
                                            this.$route.params.asignatura +
                                            '/objetivos/' +
                                            1 +
                                            '/' +
                                            this.$route.params.prueba
                                    "
                                    >Seleccionar objetivos</router-link
                                >
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Selecciona Preguntas</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <div class="box">
                    <h2 class="subtitle">Preguntas seleccionadas</h2>
                    <hr />
                     <div v-for="(pre, index) in preguntasSeleccionados" v-bind:key="pre.id">
                        <div class="columns">
                            <div class="column">
                                <h1 class="title is-5"><span v-html="pre.pregunta"></span></h1>
                                <div v-if="pre.indicador">
                                    <p class="subtitle is-6 has-text-grey"> {{pre.indicador.descripcion }}</p> 
                                </div>
                            </div>
                            <div class="column is-3">
                                <!-- <b-button
                                    icon-left="fas fa-trash-alt"
                                    type="is-danger"
                                    outlined
                                    icon-pack="fas"
                                    @click="eliminapregunta(index)"
                                ></b-button> -->
                            </div>
                        </div>

                        <hr />
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <div class="columns">
                        <div class="column is-8">
                            <h2 class="subtitle">
                                Selecciona Preguntas, {{ infoAsignatura.nombre }}, {{ infoNivel.nombre }}
                            </h2>
                        </div>
                        <div class="column">
                            <div class="is-pulled-right">
                                <b-button type="is-success" @click="siguiente" :loading="loadingSiguiente"
                                    >Agregar</b-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="columns">
                        <div class="column">

                            <div v-for="(preguntas, pruebasIndex) in pruebas" v-bind:key="pruebasIndex">
                                <div class="creador box" v-for="(columns, index) in preguntas" v-bind:key="index">
                                    <div class="box" v-if="columns.length > 1">
                                        <article class="media">
                                            <div class="media-content">
                                                <span v-html="columns[0].enunciado"></span>
                                            </div>
                                            <div class="media-right">
                                                <b-checkbox
                                                    @input="seleccionarPregunta(columns[0], $event)"
                                                ></b-checkbox>
                                            </div>
                                        </article>
                                    </div>

                                    <div class="box" v-for="pregunta in columns" v-bind:key="pregunta.id">
                                    <b-tag type="is-info">{{pregunta.objetivo.codigo}} </b-tag>
                                    <br></br> 
                                        <article class="media">
                                            <figure class="media-left has-text-centered pa1 ba br1 w2">
                                                {{ pregunta.orden }}
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <span v-html="pregunta.pregunta"></span>
                                                    <br />
                                                    <div v-if="pregunta.criterios">
                                                        <hr class="has-background-grey-lighter" />
                                                        <hr class="has-background-grey-lighter" />
                                                        <hr class="has-background-grey-lighter" />
                                                        <hr class="has-background-grey-lighter" />
                                                    </div>
                                                    <div v-if="pregunta.respuestas[0].respuesta">
                                                        <div
                                                            class="columns"
                                                            v-for="respuesta in pregunta.respuestas"
                                                            v-bind:key="respuesta.id"
                                                        >
                                                            <div class="column is-1" v-show="respuesta.respuesta != ''">
                                                                <div
                                                                    v-if="pregunta.clave == respuesta.letra"
                                                                    style="height: 25px;width: 25px; background-color: #0303;border-radius: 50%"
                                                                >
                                                                    <div class="ml-2">
                                                                        {{ respuesta.letra }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    v-else
                                                                    style="height: 25px;width: 25px; background-color: #fff;border-radius: 50%;border-color:#0404;border-width:thin;border-style: solid"
                                                                >
                                                                    <div class="ml-2">
                                                                        {{ respuesta.letra }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="column is-11"
                                                                v-show="respuesta.respuesta != ''"
                                                            >
                                                                <span v-html="respuesta.respuesta"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="media-right" v-if="columns.length == 1">
                                                <b-checkbox
                                                    @input="seleccionarPregunta(pregunta, $event)"
                                                ></b-checkbox>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../../components/Colegios.vue";
import axios from "axios";

export default {
    name: "CrearEvaluacionPreguntas",
    metaInfo: {
        title: "Seleccionar preguntas",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            isFullPage: true,
            isLoading: false,
            route_nivel: this.$route.params.nivel,
            objetivos: this.$route.params.objetivos,
            prueba: this.$route.params.prueba,
            infoAsignatura: {},
            infoNivel: {},
            niveles: [],
            nivel: this.$route.params.nivel,
            asignaturas: [],
            asignatura: this.$route.params.asignatura,
            unidades: [],
            unidad: 0,
            loadingUnidad: true,
            priorizacion: 0,
            pruebas: [],
            preguntas: [],
            pregunta: "",
            preguntasSeleccionados: [],
            loadingSiguiente: false,
            informacion: [],
            codigo: [],
            alternativa1: null,
            alternativa2: null,
            alternativa3: null,
            alternativa4: null,
            alternativa5: null,
        };
    },
    methods: {
        siguiente() {
            if (!this.preguntasSeleccionados.length) {
                this.$buefy.dialog.confirm({
                    title: "Agregar Preguntas",
                    message: "¿Deseas continuar sin agregar ninguna pregunta?",
                    confirmText: "Continuar",
                    cancelText: "No",
                    type: "is-info",
                    hasIcon: true,
                    onConfirm: () => {
                        return this.$router.push({
                            name: "CrearEvaluacionPrueba",
                            params: {
                                asignatura: this.$route.params.asignatura,
                                nivel: this.$route.params.nivel,
                                prueba: this.prueba,
                                tipo: 1,
                            },
                        });
                    },
                });
            } else {
                this.loadingSiguiente = true;
                let params = {
                        prueba : this.prueba,
                        preguntas : this.preguntasSeleccionados
                    };
                axios
                    .post("/api/creador/preguntas/banco/store", { params })
                    .then((res) => {
                        this.loadingSiguiente = false;
                            return this.$router.push({
                             name: "CrearEvaluacionPrueba",
                             params: {
                                 nivel: this.nivel,
                                 asignatura: this.asignatura,
                                 prueba: this.prueba,
                                 tipo: 1,
                             },
                         });
                    })
                    .catch((err) => {
                        this.loadingSiguiente = false;
                        throw err;
                    });
            }
        },
        eliminapregunta(key) {
            let codigo = this.preguntasSeleccionados[key];
            this.preguntasSeleccionados.splice(key, 1);
            this.$set(this.objetivosSeleccionados, codigo.key, false);

        },
        seleccionarPregunta(args, evt) {
            
            console.log(args)
             if (args.indicador) {
                let info = {
                    id: args.id,
                    enunciado: args.enunciado,
                    pregunta: args.pregunta,
                    indicador: args.indicador.descripcion
                }       
            } else {
                let info = {
                    id: args.id,
                    enunciado: args.enunciado,
                    pregunta: args.pregunta,
                    //indicador: args.indicador.descripcion
                }   
            }
           
            //if para el checkbox, agrega o quita del array las preguntas dependiedo del estado 
             if (evt) {
                let encuentra = false;
                this.preguntasSeleccionados.forEach((item) => {
                    if (item.codigo == args.id) {
                        encuentra = true;
                    }
                });
                if (!encuentra) {
                    this.preguntasSeleccionados.push(args);
                }
            } else {
                this.preguntasSeleccionados.forEach((item, key) => {
                    if (item.id == args.id) {
                        this.preguntasSeleccionados.splice(key, 1);
                    }
                });
            }
        },
        getPreguntas() {
            this.isLoading = true;
            if (this.unidad == "") {
                this.unidad = 0;
            }
            axios
                .get(`/api/creador/preguntas/banco/${this.$route.params.prueba}`)
                .then((res) => {
                    this.pruebas = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getUnidades() {
            this.loadingUnidad = true;
            axios
                .get(`/api/unidades/get/${this.asignatura}/${this.nivel}`)
                .then((res) => {
                    this.unidades = res.data;
                    this.loadingUnidad = false;
                    this.unidad = "";
                })
                .catch((err) => {
                    throw err;
                });
        },
        getAsignatura() {
            axios
                .post("/api/asignaturas/asignatura", {
                    asignatura_id: this.$route.params.asignatura,
                })
                .then((res) => {
                    this.infoAsignatura = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getNivel() {
            axios
                .post("/api/niveles/nivel", {
                    nivel_id: this.$route.params.nivel,
                })
                .then((res) => {
                    this.infoNivel = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    filters: {
        truncate(value, length) {
            return value.length > length ? value.substr(0, length) + "..." : value;
        },
    },
    mounted() {
        this.getAsignatura();
        this.getNivel();
        this.getUnidades();
        this.getPreguntas();
    },
};
</script>
