<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Crear evaluación</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Niveles</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns is-multiline">
            <div class="column is-one-third" v-for="nivel in niveles" v-bind:key="nivel.id">
                <div class="card has-text-centered pt-2	my-2" id="caja-niveles">
                <router-link :to="{ path: '/crear/evaluacion/niveles/' + nivel.id + '/asignaturas'}" id="texto-nivel">
                    {{nivel.nombre}} 
                </router-link>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>

</div>
</template>
<script>
import Colegios from '../../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "CrearEvaluacionNivel",
    metaInfo: {
        title: 'Seleccionar nivel'
    },
    components: {
        Colegios
    },
    data() {
        return {
            niveles: [],
            isLoading: false
        }
    },
    methods: {
        getNiveles() {
            this.isLoading = true;
            axios.get('/api/niveles/get').then((res) => {
                this.niveles = res.data;
                this.isLoading = false;
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getNiveles()
    }
}
</script>
