<template>
    <div class="select is-rounded is-fullwidth">
        <select v-model="colegio" class="is-small" @change="cambiar">
            <option v-for="colegio in colegios" v-bind:key="colegio.id" :value="colegio.id">{{colegio.nombre}}</option>
        </select>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Colegios',
  data() {
      return {
          colegio: null,
          colegios: []
      }
  },
  computed: {
      ...mapGetters({
          authenticated: 'auth/authenticated',
          user: 'auth/user',
          acceso: 'auth/acceso'
      })
  },
  methods: {
      cambiar() {
          axios.post('/api/usuarios/cambiar/colegio', { colegio: this.colegio }).then(() => {
              location.href="/";
          }).catch((err) => {
              throw err
          })
      },
      getColegio(){
          axios.post('/api/colegio/from/user').then((res) => {
              this.colegios = res.data;
          }).catch((err) => {
              throw err
          })
      }
  },
  mounted() {
      this.getColegio()
      this.colegio = this.acceso.colegio_id
  }
}
</script>
