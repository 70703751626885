<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Asignación</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Resultados por Estudiante </a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns ">
            <div class="column is-10">
                <b-button class="is-info mr-2" rounded outlined tag="router-link" :to="'/asignacion/resultado/'+asignacion">Resultados del Curso</b-button>
                <b-button class="is-info" rounded>Resultados por estudiante</b-button>
            </div>
            <div class="column is-2">
                <!-- <b-button class="is-info" rounded tag="router-link" :to="'/resumen/'+asignacion">Informe estadístico</b-button> -->
            </div>
        </div>
        <div class="columns">
            <div class="column ">
              <div class="box">
                    <b-table :data="resumen" :hoverable="true" :default-sort="['nombre', 'desc']" >
                        <b-table-column field="rut" label="Rut" width="150" sortable  v-slot="props">
                            {{ props.row.alumno.rut}}
                        </b-table-column>
                        <b-table-column field="nombre" label="Nombre"  sortable v-slot="props">
                            {{ props.row.alumno.name}}
                        </b-table-column>
                        <b-table-column field="aprendizaje" label="Nivel de aprendizaje" sortable v-slot="props">
                            {{ props.row.logro.nombre}}
                        </b-table-column>
                        <b-table-column field="logro" label="% Logro" sortable v-slot="props">
                            {{ props.row.porcentaje_logro}} %
                        </b-table-column>
                        <b-table-column field="nota" label="Nota" numeric sortable v-slot="props">
                            {{ props.row.nota}}
                        </b-table-column>
                        <b-table-column  width="150" v-slot="props" >
                            <b-button class="is-info"  rounded tag="router-link" :to="'/asignacion/resultado/'+asignacion+'/estudiante/'+props.row.alumno.id">ver resultado</b-button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>

        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>

</div>
</template>
<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "AsignacionResultadoPorEstudiante",
    metaInfo: {
        title: 'Resultado por estudiante'
    },
     components: {
        Colegios
    },
    data() {
        return {
            resumen: [],
            isFullPage: true,
            isLoading: false,
            asignacion: this.$route.params.asignacion
        }
    },
    methods: {
        getInformacion() {
            let self = this
            this.isLoading = true;
            axios.post('/api/pruebas/estudiantes_prueba', {
                asignacion: this.$route.params.asignacion
            }).then((res) => {
                this.resumen = res.data;
            })
            this.isLoading = false;
        }
    },
    mounted() {
        this.getInformacion()
    }
}
</script>
