<template>
<div class="container">
<div class="box">
    <div class="columns">
        <div class="column is-9">
            <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                <ul>
                    <li class="is-active"><a href="#" aria-current="page"></a></li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="columns">
        <div class="column">
        <div class="tabs is-toggle is-toggle-rounded is-fullwidth">
            <ul>
                <li class="is-active">
                    <a>
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro por pruebas</span>
                    </a>
                </li>
                <li>
                    <router-link :to="{ name: 'UtpPrincipal', params: { prueba_tipo: 1 } }" >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro progresiva</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'UtpPrincipal', params: { prueba_tipo: 2 } }" >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro SIMCE</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'UtpPrincipal', params: { prueba_tipo: 3 } }" >
                        <span class="icon is-small">
                            <i class="fas fa-image"></i>
                        </span>
                        <span>Porcentaje logro PSU</span>
                    </router-link>
                </li>
                <li class="" >
                        <router-link to="/utp/graficos">
                            <span class="icon is-small">
                                <i class="fas fa-image"></i>
                            </span>
                            <span >Graficos por curso</span>
                        </router-link>
                </li>
            </ul>
        </div>
        </div>
        </div>
    <div class="columns">    
                <div class="column" v-for="(tipo, index) in tipos" v-bind:key="tipo.id">
                    <div v-on:click="recargaTablas(index+1)" class="box">
                        <b>{{tipo.nombre}} </b>
                        <a > 
                           <div class="columns" v-if="cantidad_pruebas[index] > 0">
                               <div class="column">
                                    <b-progress :type="'is-info'" :value="porcentaje_rendido[index]" :max="max" show-progress></b-progress>
                               </div>
                               <div class="column is-4">
                                   <h4 class="title is-4 has-text-info">
                                       {{cantidad_pruebas_rendidas[index]}} / {{cantidad_pruebas[index]}}
                                   </h4>
                               </div>
                           </div> 
                            <div class="columns" v-else>
                                <div class="column">
                                </div>
                                <div class="column is-3">
                                    <h4 class="title is-4">
                                        0/0
                                    </h4>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
    <div class="columns">
        <div class="column">
            <div class="table-container" >
                <table class="table is-bordered">
                    <thead>
                        <tr>
                            <th rowspan="2" style="vertical-align: middle">Cursos</th>
                            <template v-for="(asignatura, indice) in pruebas_realizadas"> 
                                <th class="has-text-centered" v-if="indice==='Ciencias Naturales' && prueba_tipo_id==3" v-bind:key="asignatura.id" :colspan="18">{{indice}}</th>
                                <th class="has-text-centered" v-else-if="prueba_tipo_id==3" v-bind:key="asignatura.id" :colspan="6">{{indice}}</th>
                                <th class="has-text-centered" v-else-if="prueba_tipo_id==1" v-bind:key="asignatura.id" :colspan="3">{{indice}}</th>  
                                <th class="has-text-centered" v-else-if="prueba_tipo_id==2" v-bind:key="asignatura.id" :colspan="6">{{indice}}</th>  
                            </template>
                        </tr>
                        <tr>
                            <template v-if="prueba_tipo_id==1"> 
                                <template v-for="numero in pruebas_realizadas">
                                    <th>
                                        Diagnóstica
                                    </th>
                                    <th>
                                        Intermedia
                                    </th>
                                    <th>
                                        Final
                                    </th>
                                </template>
                            </template>
                            <template v-else-if="prueba_tipo_id==2">
                                <template v-for="innn in pruebas_realizadas">
                                    <template v-for="numero in 6">
                                        <th>
                                            {{numero}}
                                        </th>
                                    </template>
                                </template>
                            </template>
                            <template v-else-if="prueba_tipo_id==3">
                                <template v-for="(innn,indice) in pruebas_realizadas">
                                    <template v-if="indice=='Ciencias Naturales'">
                                            <th v-for="etapa in etapas_ciencias_psu" v-bind:key="numero1">
                                                {{etapa.nombre.slice(-2)}}
                                            </th>
                                    </template>
                                    <template v-else>
                                        <template v-for="numero2 in 6">
                                            <th>
                                                {{numero2}}
                                            </th>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="curso in cursos" v-bind:key="curso.id">
                            <td>
                                {{curso.nivel.codigo}} {{curso.letra}}                           
                            </td>
                            <template v-for="(asignatura, indice) in pruebas_realizadas"> 
                                <template v-if="prueba_tipo_id==1">
                                    <td class="has-text-centered" v-for="numero in 3"> <!-- Aqui va la casilla que depende del colspan-->
                                        <template v-for="(tipo, index) in asignatura"> <!-- index para ProgreseivaDiagnostico -->
                                            <h4 v-if="numero==1 && index.includes('Diagnóstico')" >
                                                <template v-for="(icono, idnivel) in tipo">
                                                    <span :class="['icon is-small '+ icono]" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra"> <i class="fas fa-check-circle"></i> </span>
                                                </template>
                                            </h4>
                                            <h4 v-else-if="numero==2 && index.includes('Intermedia')" >
                                                <template v-for="(icono, idnivel) in tipo">
                                                    <span :class="['icon is-small '+ icono]" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra"> <i class="fas fa-check-circle"></i> </span>
                                                </template>
                                            </h4>
                                            <h4 v-else-if="numero==3 && index.includes('Final')" >
                                                <template v-for="(icono, idnivel) in tipo">
                                                    <span :class="['icon is-small '+ icono]" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra"> <i class="fas fa-check-circle"></i> </span>
                                                </template>
                                            </h4>
                                        </template>
                                    </td>
                                </template>
                                <template v-else-if="prueba_tipo_id==2">
                                       <td class="has-text-centered" v-for="numero in 6"> <!-- Aqui va la casilla que depende del colspan-->
                                            <template v-for="(tipo, index) in asignatura"> <!--Index es el nombre de ensayo -->
                                                <h4 v-if="numero==index.slice(-1)" >
                                                    <template v-for="(icono, idnivel) in tipo">
                                                        <span :class="['icon is-small '+ icono]" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra"> <i class="fas fa-check-circle"></i> </span>
                                                    </template>
                                               </h4>
                                            </template>
                                        </td>
                                </template>
                                <template v-else-if="prueba_tipo_id==3">
                                        <template v-if="indice=='Ciencias Naturales'">
                                            <td class="has-text-centered" v-for="etapa in etapas_ciencias_psu"> <!-- Aqui va la casilla que depende del colspan-->
                                                <template v-for="(tipo, index) in asignatura"> <!--Index es el nombre de ensayo -->
                                                    <h4 v-if="etapa.nombre.slice(-2)==index.slice(-2)" >
                                                        <template v-for="(icono, idnivel) in tipo">
                                                            <span :class="['icon is-small '+ icono]" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra"> <i class="fas fa-check-circle"></i> </span>
                                                        </template>
                                                    </h4>
                                                </template>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td class="has-text-centered" v-for="numero in 6"> <!-- Aqui va la casilla que depende del colspan-->
                                                <template v-for="(tipo, index) in asignatura"> <!--Index es el nombre de ensayo -->
                                                    <h4 v-if="numero==index.slice(-1)" >
                                                        <template v-for="(icono, idnivel) in tipo">
                                                            <span :class="['icon is-small '+ icono]" v-if="idnivel.slice(-3,-1)==curso.nivel.id && idnivel.slice(-1)==curso.letra"> <i class="fas fa-check-circle"></i> </span>
                                                        </template>
                                                    </h4>
                                                </template>
                                            </td>
                                        </template>
                                    </template>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>

</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'

export default{
    name: "UtpGraficos",
    metaInfo: {
        title: 'General'
    },
    components:{

    },
    data(){
        return{
            tipos:null,
            cursos:null,
            etapas:null,
            asignaturas:null,
            prueba_tipo_id:1,
            etapas_ciencias_psu:null,
            pruebas_realizadas : null,
            cantidad_pruebas:null,
            cantidad_pruebas_rendidas:null,
            porcentaje_rendido:null,
            max:100
        }
    },
    methods: {
        getDatos(){
            axios.post('/api/utp/curso',{
                prueba_tipo : this.prueba_tipo_id
            }).then((res)=>{
                this.tipos = res.data.tipos;
                this.cursos = res.data.cursos;
                this.etapas = res.data.etapas;
                this.asignaturas = res.data.asignaturas;
                this.prueba_tipo_id = res.data.prueba_tipo_id;
                this.etapas_ciencias_psu = res.data.etapas_ciencias_psu;
                this.pruebas_realizadas = res.data.pruebas_realizadas;
                this.cantidad_pruebas = res.data.cantidad_pruebas;
                this.cantidad_pruebas_rendidas = res.data.cantidad_pruebas_rendidas;
                this.porcentaje_rendido = res.data.porcentaje_rendido;
                console.log(res.data);

            });
        },
         ajustarLargo(){
            var largo =  $(".columns").parent().width();
            $(".table-container").width(largo - 40).css('maxWidth','100%');
        },
        recargaTablas(tipo_id){
            this.prueba_tipo_id=tipo_id;
            this.getDatos();
            console.log("XD: " + this.prueba_tipo_id);
            window.addEventListener("resize", this.ajustarLargo);
        },
    },
    mounted(){
        this.getDatos();
        window.addEventListener("resize", this.ajustarLargo);
    }
    
}


</script>
