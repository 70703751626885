<template>
<div class="container mt-5">
    <article class="message is-warning">
        <div class="message-body">
            Parece que no tienes ningún colegio asociado a tu cuenta, contáctate con el departamento de soporte al correo soporte@aeduc.cl.
        </div>
    </article>
</div>
</template>

<script>
export default {}
</script>
