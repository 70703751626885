<template lang="html">
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Configuracion Cuenta</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
        <div class="box">
            <div class="columns">
                <div class="column">
                    <h1 class="title">Mi cuenta</h1>
                </div>
            </div>
            <div class="box">
                <div class="columns">
                    <div class="column">
                        <b-field label="Nombres">
                            <b-input v-model="name" ref="name" placeholder="Ingrese nombres y apellidos"></b-input>
                        </b-field>
                        <b-field label="Rut">
                            <b-input v-model="rut" ref="rut" placeholder="Ingrese su RUT" maxlength="11"></b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Email">
                            <b-input v-model="email" placeholder="Ingrese su email"></b-input>
                        </b-field>
                        <b-field label="Fono">
                            <b-input v-model="phone" placeholder="Ingrese su número celular (opcional)" maxlength="9"></b-input>
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-button type="is-primary" @click="guardar" :loading="loadingGuardar">Guardar</b-button>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="columns">
                    <div class="column">
                        <h2 class="subtitle">Cambiar contraseña</h2>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-6">
                        <b-field label="Contraseña anterior">
                            <b-input v-model="anterior" type="password" password-reveal placeholder="Ingrese la contraseña del usuario"></b-input>
                        </b-field>
                        <b-field label="Contraseña nueva">
                            <b-input v-model="password" type="password" password-reveal placeholder="Ingrese la contraseña del usuario"></b-input>
                        </b-field>
                        <b-field label="Repetir Contraseña">
                            <b-input v-model="nueva" type="password" password-reveal placeholder="Ingrese la contraseña del usuario"></b-input>
                        </b-field>
                        <b-button type="is-primary" @click="cambiar_password" :loading="loadingPassword">Cambiar contraseña</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            usuario: [],
            name: null,
            rut: null,
            password: null,
            phone: null,
            anterior: null,
            nueva: null,
            email: null,
            loadingGuardar: false,
            loadingPassword: false,
        }
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },
    methods: {
        guardar() {
            let self = this
            self.loadingGuardar=true;

            let params = {
                params : {
                    id: this.user.id,
                    name: this.name,
                    rut: this.rut,
                    phone: this.phone,
                    email: this.email,
                    password : null
                }
            };

            axios.post('/api/usuarios/update', params).then((response) => {
                self.loadingGuardar = false;
                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: 'Información de usuario actualizada correctamente',
                        type: 'is-success'
                    })
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                }
            }).catch((err) => {
                throw err
            })
        },
        cambiar_password() {

            let self = this
            self.loadingPassword=true;

            if (this.password != this.nueva) {
                self.loadingPassword = false;
                this.$buefy.toast.open({
                    message: 'La contraseña nueva no coincide',
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                return
            }
            let params = {
                id: this.user.id,
                password : this.password,
                anterior : this.anterior,
                nueva : this.nueva
            };
            axios.post('/api/usuarios/change/password', params).then((response) => {
                self.loadingPassword = false;
                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: 'Contraseña actualizada correctamente',
                        type: 'is-success'
                    })
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                }
            }).catch((err) => {
                self.loadingPassword = false;
                throw err
            })
        },
        getCliente() {
            axios.post('/api/usuarios/cuenta/get', {id: this.user.id})
                .then((res) => {
                    this.usuario = res.data
                    this.name = this.usuario.name
                    this.rut = this.usuario.rut
                    this.phone = this.usuario.phone
                    this.email = this.usuario.email
                })
                .catch((err) => {
                    throw err
                })
        }
    },
    mounted() {
        this.getCliente()
    }
}
</script>
