import Vue from "vue";
import Vuex from "vuex";
import Buefy from 'buefy';
import axios from "axios";
import auth from './auth';
import Sass from '../assets/sass/main.scss';
import VueMeta from 'vue-meta'
import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';
// import VueQuill from 'vue-quill'
// import VueQuillEditor from 'vue-quill-editor'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// import 'buefy/dist/buefy.css';

Vue.use(Buefy);
Vue.use(Vuex);
Vue.use(Sass);
Vue.use(VueMeta);
Vue.use(VueKatex);
// Vue.use(VueQuillEditor)
// Vue.use(VueQuill)


export default new Vuex.Store({
    modules: {
        auth
    }
})
