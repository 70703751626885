<template>
<div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title">Objetivos de aprendizaje</p>
    </header>
    <section class="modal-card-body">
        <b-tabs>
            <b-tab-item label="Currículum nacional">
                <div class="columns is-multiline">
                    <div class="column is-12" v-for="objetivo in objetivos" v-bind:key="objetivo.id">

                        <div class="card">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    <b-icon v-if="objetivo.priorizado == 1" icon="star" size="is-small"></b-icon>
                                                    <b-icon v-if="objetivo.priorizado == 2" icon="star-outline" size="is-small">
                                                    </b-icon> {{objetivo.codigo}}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-button @click="selectObjetivo(objetivo)">Seleccionar</b-button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="field">
                                            <p class="subtitle is-6 has-text-grey">{{objetivo.asignatura.nombre}} / {{objetivo.nivel.nombre}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{objetivo.descripcion}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Creados">
                <div class="columns" v-show="show">
                    <div class="column">
                        <b-input type="textarea" v-model="descripcionObjetivo" maxlength="200"></b-input>
                        <b-button type="is-primary" @click="crearObjetivo">Crear Objetivo</b-button>
                    </div>
                </div>
                <div v-if="objetivosCreados.length" class="columns is-multiline">
                    <div class="column is-12" v-for="objetivo in objetivosCreados" v-bind:key="objetivo.id">

                        <div class="card">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    {{objetivo.codigo}}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-button @click="selectObjetivo(objetivo)">Seleccionar</b-button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="field">
                                            <p class="subtitle is-6 has-text-grey">{{objetivo.asignatura.nombre}} / {{objetivo.nivel.nombre}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{objetivo.descripcion}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="card" style="border-style: dashed;border-width:1px;border-color:#2496f5">
                            <div class="card-content has-text-centered">
                                <div class="columns">
                                    <div class="column">
                                        <b-button type="is-primary" rounded @click="show=true" outlined>Crear Objetivo de Aprendizaje</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </section>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['nivel', 'asignatura', 'prueba', 'index'],
    name: 'Objetivos',
    data() {
        return {
            show: false,
            isFullPage: false,
            isLoading: false,
            objetivos: [],
            objetivosCreados: [],
            descripcionObjetivo: null
        }
    },
    methods: {
        getObjetivosCreados() {
            axios.get(`/api/objetivos/get/creados/${this.asignatura}/${this.nivel}`).then((res) => {
                this.objetivosCreados = res.data
            }).catch((err) => {
                throw err
            });
        },
        crearObjetivo() {
            axios.post('/api/objetivos/set/creados', {
                asignatura: this.asignatura,
                nivel: this.nivel,
                descripcion: this.descripcionObjetivo
            }).then(() => {
                this.getObjetivosCreados();
            }).catch((err) => {
                throw err
            })
        },
        getObjetivos() {
            let self = this;
            self.isLoading = true;
            axios.get(`/api/objetivos/evaluacion/${this.prueba}`).then((res) => {
                this.objetivos = res.data.objetivos
                self.isLoading = false;
            }).catch((err) => {
                throw err
            });
        },
        selectObjetivo(args) {
            this.$emit('close')
            this.$emit("setObjetivos", {
                'id': args.id,
                'codigo': args.codigo,
                'descripcion': args.descripcion,
                'index': this.index
            })
        }
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.getObjetivos()
        this.getObjetivosCreados()
    }
}
</script>
