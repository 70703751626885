<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/aplicar/evaluacion/en_curso/' +
                                            asignatura +
                                            '/' +
                                            curso +
                                            '/' +
                                            prueba +
                                            '/' +
                                            asignacion
                                    "
                                    >Aplicar</router-link
                                >
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Tabular respuestas</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
        <div class="box">
            <div class="columns" v-if="cursoInfo">
                <div class="column">
                    <h1 class="title">
                        Evaluación, {{ evaluacionInfo.prueba_tipo.nombre }} {{ evaluacionInfo.prueba_etapa.nombre }}
                        {{ evaluacionInfo.asignatura.nombre }}, {{ cursoInfo.nivel.nombre }} {{ cursoInfo.letra }}
                    </h1>

                    <div class="columns">
                        <div class="column">
                            <h2 class="subtitle mt-2">Tabulación de respuestas para, {{ alumnoInfo.name }}</h2>
                        </div>
                        <div class="column">
                            <div class="is-pulled-right">
                                <b-button type="is-primary" @click="guardar" :loading="loadingGuardar"
                                    >Guardar respuestas</b-button
                                >
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <table class="table is-fullwidth is-bordered">
                                <tr>
                                    <td></td>
                                    <td :colspan="criteriosTitulos.length" class="has-text-centered">
                                        <strong>Categorías / Nivel</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="has-text-centered">
                                        <strong>Criterios</strong>
                                    </td>
                                    <td
                                        v-show="key < nivelesTotal"
                                        v-for="(criterio, key) in criteriosTitulos"
                                        v-bind:key="key"
                                        class="has-background-white-ter"
                                    >
                                        <div class="columns">
                                            <div class="column">
                                                <b class="is-small">{{ criterio }}</b>
                                            </div>
                                            <div class="column">{{ puntos[key] }} pts.</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    v-show="key < criteriosTotal"
                                    v-for="(crit, key) in criteriosTotal"
                                    v-bind:key="key"
                                >
                                    <td
                                        v-show="k - 1 < nivelesTotal"
                                        v-for="(criterio, k) in criterios[key]"
                                        v-bind:key="k"
                                    >
                                        <b v-if="k == 0">{{ wordWrap(criterios[key][k], 20) }}</b>
                                        <b-radio
                                            v-if="k != 0"
                                            v-model="respuestas[key]"
                                            :name="'r' + key"
                                            :native-value="k"
                                        >
                                            {{ wordWrap(criterios[key][k], 20) }}
                                        </b-radio>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../components/Colegios.vue";
import axios from "axios";
export default {
    name: "Tabulacion",
    metaInfo: {
        title: "Tabular respuestas",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            asignatura: this.$route.params.asignatura,
            curso: this.$route.params.curso,
            prueba: this.$route.params.prueba,
            asignacion: this.$route.params.asignacion,
            alumno: this.$route.params.alumno,
            alumnoInfo: [],
            evaluacionInfo: null,
            cursoInfo: null,
            preguntasInfo: [],
            respuestas: [],
            isLoading: false,
            loadingGuardar: false,
            criteriosTitulos: ["Excelente", "Bueno", "Regular", "Deficiente", "", "", "", ""],
            puntos: [4, 3, 2, 1, 0, 0, 0, 0],
            criterios: [["", "", "", "", "", "", "", "", ""]],
            objetivos: [],
            criteriosTotal: 1,
            nivelesTotal: 4,
            loadingGuardar: false,
            informacion: [],
        };
    },
    methods: {
        wordWrap(str, maxWidth) {
            if (!str) {
                return;
            }
            let newLineStr = "\n",
                done = false,
                res = "";
            while (str.length > maxWidth) {
                let found = false;
                // Inserts new line at first whitespace of the line
                for (let i = maxWidth - 1; i >= 0; i--) {
                    if (this.testWhite(str.charAt(i))) {
                        res = res + [str.slice(0, i), newLineStr].join("");
                        str = str.slice(i + 1);
                        found = true;
                        break;
                    }
                }
                // Inserts new line at maxWidth position, the word is too long to wrap
                if (!found) {
                    res += [str.slice(0, maxWidth), newLineStr].join("");
                    str = str.slice(maxWidth);
                }
            }

            return res + str;
        },

        testWhite(x) {
            var white = new RegExp(/^\s$/);
            return white.test(x.charAt(0));
        },
        limpiar(orden) {
            this.$set(this.respuestas, orden, null);
        },
        guardar() {
            let self = this;
            self.loadingGuardar = true;
            axios
                .post("/api/pruebas/tabular/rubrica/store", {
                    asignacion: this.$route.params.asignacion,
                    alumno: this.$route.params.alumno,
                    respuestas: this.respuestas,
                })
                .then((res) => {
                    if (res.data) {
                        return this.$router.push({
                            name: "EvaluacionEnCurso",
                            params: {
                                asignatura: this.$route.params.asignatura,
                                curso: this.$route.params.curso,
                                prueba: this.$route.params.prueba,
                                asignacion: this.$route.params.asignacion,
                            },
                        });
                    }
                    self.loadingGuardar = false;
                })
                .catch((err) => {
                    self.loadingGuardar = false;
                    throw err;
                });
        },
        getRespuestas() {
            axios
                .post("/api/asignaciones/respuestas/get", {
                    asignacion: this.$route.params.asignacion,
                    alumno: this.$route.params.alumno,
                })
                .then((res) => {
                    this.respuestas = JSON.parse(res.data);
                })
                .catch((err) => {
                    throw err;
                });
        },
        getInformacion() {
            axios
                .post("/api/pruebas/get", {
                    prueba: this.$route.params.prueba,
                })
                .then((res) => {
                    this.evaluacionInfo = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getPreguntas() {
            this.isLoading = true;
            for (var i = 0; i <= 30; i++) {
                this.$set(this.criterios, i, ["", "", "", "", "", "", "", "", ""]);
                this.$set(this.objetivos, i, {});
            }
            axios
                .get(`/api/creador/evaluacion/rubrica/${this.$route.params.prueba}`)
                .then((res) => {
                    this.informacion = res.data[0];
                    if (this.informacion) {
                        let criterios = JSON.parse(this.informacion.criterios);
                        criterios.forEach((criterio, key) => {
                            let nuevo_criterio = criterio;
                            for (var i = criterio.length; i <= 9; i++) {
                                this.$set(nuevo_criterio, i, "");
                            }
                            this.$set(this.criterios, key, nuevo_criterio);
                        });
                        this.criteriosTotal = criterios.length;

                        let criteriosTitulos = JSON.parse(this.informacion.criterios_titulos);
                        criteriosTitulos.forEach((criterio, key) => {
                            this.$set(this.criteriosTitulos, [key], criterio);
                        });
                        this.nivelesTotal = criteriosTitulos.length;

                        let puntajes = JSON.parse(this.informacion.puntos);
                        puntajes.forEach((item, key) => {
                            this.$set(this.puntos, [key], item);
                        });

                        let objetivos = JSON.parse(this.informacion.objetivos);
                        objetivos.forEach((item, key) => {
                            this.$set(this.objetivos, [key], item);
                        });
                    }

                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getCurso() {
            axios
                .post("/api/cursos/curso", {
                    curso_id: this.$route.params.curso,
                })
                .then((res) => {
                    this.cursoInfo = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getAlumno() {
            axios
                .post("/api/usuarios/get", {
                    id: this.alumno,
                })
                .then((res) => {
                    this.alumnoInfo = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    filters: {
        truncate(value, length) {
            return value.length > length ? value.substr(0, length) + "..." : value;
        },
    },
    mounted() {
        this.getInformacion();
        this.getPreguntas();
        this.getRespuestas();
        this.getCurso();
        this.getAlumno();
    },
};
</script>
