<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/">Crear evaluación</router-link>
                            </li>
                            <li>
                                <router-link to="/crear/evaluacion/niveles">Niveles</router-link>
                            </li>
                            <li>
                                <router-link :to="'/crear/evaluacion/niveles/' + route_nivel + '/asignaturas'"
                                    >Asignaturas</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/crear/evaluacion/niveles/' +
                                            route_nivel +
                                            '/asignaturas/' +
                                            asignatura +
                                            '/evaluaciones'
                                    "
                                    >Evaluaciones</router-link
                                >
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Seleccionar objetivos</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <div class="box">
                    <h2 class="subtitle">OAs seleccionados</h2>
                    <hr />
                    <div v-for="(obs, index) in objetivosSeleccionados" v-bind:key="obs.codigo">
                        <div class="columns">
                            <div class="column">
                                <h1 class="title is-5">{{ obs.codigo }}</h1>
                                <p class="subtitle is-6 has-text-grey">{{ obs.asignatura }} / {{ obs.nivel }}</p>
                            </div>
                            <div class="column is-3">
                                <b-button
                                    icon-left="fas fa-trash-alt"
                                    type="is-danger"
                                    outlined
                                    icon-pack="fas"
                                    @click="eliminaObjetivo(index)"
                                ></b-button>
                            </div>
                        </div>

                        <hr />
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <div class="columns">
                        <div class="column is-8">
                            <h2 class="subtitle">
                                Crear evaluación, {{ infoAsignatura.nombre }}, {{ infoNivel.nombre }}
                            </h2>
                        </div>
                        <div class="column">
                            <div class="is-pulled-right">
                                <b-button type="is-primary" @click="siguiente" :loading="loadingSiguiente"
                                    >Siguiente</b-button
                                >
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div class="columns">
                        <div class="column">
                            <b-field label="Nivel">
                                <b-select
                                    placeholder="Selecciona un nivel"
                                    @input="
                                        getTipos();
                                        getObjetivos();
                                    "
                                    expanded
                                    v-model="nivel"
                                >
                                    <option v-for="nivel in niveles" :value="nivel.id" :key="nivel.id">
                                        {{ nivel.nombre }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Asignatura">
                                <b-select
                                    placeholder="Selecciona una asignatura"
                                    @input="
                                        getTipos();
                                        getObjetivos();
                                    "
                                    expanded
                                    v-model="asignatura"
                                >
                                    <option
                                        v-for="asignatura in asignaturas"
                                        :value="asignatura.id"
                                        :key="asignatura.id"
                                    >
                                        {{ asignatura.nombre }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Tipo">
                                <b-select
                                    placeholder="Selecciona un tipo de objetivo"
                                    :loading="loadingUnidad"
                                    expanded
                                    v-model="unidad"
                                    @input="getObjetivos()"
                                >
                                    <option value="0">Todas</option>
                                    <option v-for="unidad in unidades" :value="unidad.tipo" :key="unidad.tipo">
                                        {{ unidad.tipo }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </div>

                <div class="columns is-multiline">
                    <div class="column is-4" v-for="objetivo in objetivos" v-bind:key="objetivo.id">
                        <div class="card" style="height:310px">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="field">
                                            <b-tag>{{ objetivo.tipo }}</b-tag>
                                        </div>
                                        <div class="field">
                                            <div class="field is-pulled-left">
                                                <p class="title is-5">
                                                    {{ objetivo.codigo }}
                                                </p>
                                            </div>
                                            <div class="field is-pulled-right">
                                                <b-checkbox
                                                    v-model="objetivosSeleccionados[objetivo.codigo]"
                                                    @input="seleccionarObjetivo(objetivo, $event)"
                                                ></b-checkbox>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div class="field">
                                            <p class="subtitle is-6 has-text-grey">
                                                {{ objetivo.asignatura.nombre }} / {{ objetivo.nivel.nombre }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="content">
                                    <p>{{ objetivo.descripcion | truncate(180) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../../components/Colegios.vue";
import axios from "axios";

export default {
    name: "CrearEvaluacionObjetivos",
    metaInfo: {
        title: "Seleccionar objetivos",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            isFullPage: true,
            isLoading: false,
            route_nivel: this.$route.params.nivel,
            infoAsignatura: {},
            infoNivel: {},
            niveles: [],
            nivel: this.$route.params.nivel,
            asignaturas: [],
            asignatura: this.$route.params.asignatura,
            unidades: [],
            unidad: 0,
            loadingUnidad: true,
            priorizacion: 0,
            objetivos: [],
            objetivo: "",
            objetivosSeleccionados: [],
            loadingSiguiente: false,
            prueba_id:0,
            pruebas: {},
            unidad:0,
        };
    },
    methods: {
        getPruebaEnCurso() {
            if (this.$route.params.prueba) {
                axios
                    .post("/api/creador/evaluacion/en_curso", {
                        prueba_id: this.$route.params.prueba,
                        evaluacion_tipo_id: this.$route.params.tipo,
                    })
                    .then((res) => {
                        if (!res.data.codigo) {
                            this.$buefy.dialog.confirm({
                                title: "Código de prueba incorrecto",
                                message: "El código de la evaluación no corresponde",
                                type: "is-danger",
                                hasIcon: true,
                            });

                            this.$buefy.loading.open({
                                container: this.isFullPage ? null : this.$refs.element.$el,
                            });
                        }

                        res.data.objetivos.forEach((item) => {
                            let informacion = {
                                id: item.id,
                                codigo: item.codigo,
                                asignatura: item.asignatura.nombre,
                                nivel: item.nivel.nombre,
                            };
                            this.$set(this.objetivosSeleccionados, item.codigo, true);
                            this.objetivosSeleccionados.push(informacion);
                        });
                    })
                    .catch((err) => {
                        throw err;
                    });
            }
        },
        siguiente() {
            if (!this.objetivosSeleccionados.length && this.$route.params.tipo == 1) {
                return this.$buefy.toast.open({
                    message: "Debes selecionar al menos 1 objetivo de aprendizaje",
                    type: "is-danger",
                });
            }

            this.loadingSiguiente = true;

            axios
            //guarda la prueba con los objetivos seleccionados
                .post("/api/creador/evaluacion/store", {
                    asignatura_id: this.$route.params.asignatura,
                    nivel_id: this.$route.params.nivel,
                    objetivos: this.objetivosSeleccionados,
                    prueba_id: this.$route.params.prueba,
                    evaluacion_tipo_id: this.$route.params.tipo,
                })
                .then((res) => {
                    //consulta al api si existen preguntas en el banco de preguntas con los objetivos seleccionados
                    // si lo hay consulta si desea agregar alguna 

                    this.prueba_id = res.data.id;

                     this.isLoading = true;
                        if (this.unidad == "") {
                            this.unidad = 0;
                        }
                        axios
                            .get(`/api/creador/preguntas/banco/${this.prueba_id}`)
                            .then((res) => {
                                this.pruebas = res.data;
                                this.isLoading = false;
                                
                                    this.loadingSiguiente = false;
                                     if (this.$route.params.tipo == 1) {

                                        if (this.pruebas.length == 0){
                                            console.log('no hay preguntas')
                                            this.loadingSiguiente = false;
                                                return this.$router.push({
                                                    name: "CrearEvaluacionPrueba",
                                                    params: {
                                                        asignatura: this.$route.params.asignatura,
                                                        nivel: this.$route.params.nivel,
                                                        prueba: this.prueba_id,
                                                        tipo: this.$route.params.tipo,
                                                    },
                                                });
                                            }
                                        if (this.pruebas) {
                                            console.log('si hay preguntas ')
                                                this.$buefy.dialog.confirm({
                                                    title: "Banco de  Preguntas",
                                                    message: "Existen preguntas en nuestro banco de preguntas asociadas a los objetivos seleccionados, <br> ¿Te gustaria revisar algunas de ellas?",
                                                    confirmText: "Revisar banco de preguntas",
                                                    cancelText: "No, gracias",
                                                    type: "is-success",
                                                    hasIcon: true,
                                                    onConfirm: () => {
                                                        return this.$router.push({
                                                            name: "BancoPreguntas",
                                                            params: {
                                                                asignatura: this.$route.params.asignatura,
                                                                nivel: this.$route.params.nivel,
                                                                objetivos: this.objetivosSeleccionados,
                                                                prueba: this.prueba_id,
                                                                tipo: this.$route.params.tipo,
                                                            },
                                                        });
                                                    },
                                                    onCancel: () => {
                                                        return this.$router.push({
                                                            name: "CrearEvaluacionPrueba",
                                                            params: {
                                                                asignatura: this.$route.params.asignatura,
                                                                nivel: this.$route.params.nivel,
                                                                prueba: this.prueba_id,
                                                                tipo: this.$route.params.tipo,
                                                            },
                                                        });
                                                    },
                                                });
                                        }
                                    }
                                    if (this.$route.params.tipo == 2) {
                                        return this.$router.push({
                                            name: "CrearEvaluacionRubrica",
                                            params: {
                                                asignatura: this.$route.params.asignatura,
                                                nivel: this.$route.params.nivel,
                                                prueba: this.prueba_id,
                                                tipo: this.$route.params.tipo,
                                            },
                                        });
                                    }
                                    if (this.$route.params.tipo == 3) {
                                        return this.$router.push({
                                            name: "CrearEvaluacionListaCotejo",
                                            params: {
                                                asignatura: this.$route.params.asignatura,
                                                nivel: this.$route.params.nivel,
                                                prueba: this.prueba_id,
                                                tipo: this.$route.params.tipo,
                                            },
                                        });
                                    }
                                    if (this.$route.params.tipo == 4) {
                                        return this.$router.push({
                                            name: "CrearEvaluacionEscalaApreciacion",
                                            params: {
                                                asignatura: this.$route.params.asignatura,
                                                nivel: this.$route.params.nivel,
                                                prueba: this.prueba_id,
                                                tipo: this.$route.params.tipo,
                                            },
                                        });
                                    }
                            })
                            .catch((err) => {
                                throw err;
                            });
                })
                .catch((err) => {
                    this.loadingSiguiente = false;
                    throw err;
                });
        },
        eliminaObjetivo(key) {
            let codigo = this.objetivosSeleccionados[key];
            this.objetivosSeleccionados.splice(key, 1);
            this.$set(this.objetivosSeleccionados, codigo.codigo, false);
        },
        seleccionarObjetivo(args, evt) {
            //console.log(args.id)
            let informacion = {
                id: args.id,
                codigo: args.codigo,
                asignatura: args.asignatura.nombre,
                nivel: args.nivel.nombre,
            };
                console.log(informacion)
            if (evt) {
                let encuentra = false;
                this.objetivosSeleccionados.forEach((item) => {
                    if (item.codigo == args.codigo) {
                        encuentra = true;
                    }
                });
                if (!encuentra) {
                    this.objetivosSeleccionados.push(informacion);
                }
            } else {
                this.objetivosSeleccionados.forEach((item, key) => {
                    if (item.codigo == args.codigo) {
                        this.objetivosSeleccionados.splice(key, 1);
                    }
                });
            }
        },
        getObjetivos() {
            this.isLoading = true;
            if (this.unidad == "" || this.unidad == null) {
                this.unidad = 0;
            }
            axios
                .get(`/api/objetivos/get/${this.asignatura}/${this.nivel}/${this.unidad}/${this.priorizacion}`)
                .then((res) => {
                    this.objetivos = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getTipos() {
            this.loadingUnidad = true;
            axios
                .get(`/api/objetivos/tipos/get/${this.asignatura}/${this.nivel}`)
                .then((res) => {
                    this.unidades = res.data;
                    this.loadingUnidad = false;
                    this.unidad = "";
                })
                .catch((err) => {
                    throw err;
                });
        },
        getAsignatura() {
            axios
                .post("/api/asignaturas/asignatura", {
                    asignatura_id: this.$route.params.asignatura,
                })
                .then((res) => {
                    this.infoAsignatura = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getNivel() {
            axios
                .post("/api/niveles/nivel", {
                    nivel_id: this.$route.params.nivel,
                })
                .then((res) => {
                    this.infoNivel = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getNiveles() {
            axios
                .get("/api/niveles/get")
                .then((res) => {
                    this.niveles = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
        getAsignaturas() {
            this.isLoading = true;
            axios
                .get("/api/asignaturas/select")
                .then((res) => {
                    this.asignaturas = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    filters: {
        truncate(value, length) {
            if (!value) {
                return value;
            }
            return value.length > length ? value.substr(0, length) + "..." : value;
        },
    },
    mounted() {
        this.getAsignatura();
        this.getNivel();
        this.getNiveles();
        this.getAsignaturas();
        this.getTipos();
        this.getObjetivos();
        this.getPruebaEnCurso();
    },
};
</script>
