<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column is-9">
                    <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/aplicar/evaluacion/en_curso/' +
                                            asignatura +
                                            '/' +
                                            curso +
                                            '/' +
                                            prueba +
                                            '/' +
                                            asignacion
                                    "
                                    >Aplicar</router-link
                                >
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">Tabular respuestas</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="column is-3">
                    <Colegios />
                </div>
            </div>
        </div>
        <div class="box">
            <div class="columns" v-if="cursoInfo">
                <div class="column">
                    <h1 class="title">
                        Evaluación, {{ evaluacionInfo.prueba_tipo.nombre }} {{ evaluacionInfo.prueba_etapa.nombre }}
                        {{ evaluacionInfo.asignatura.nombre }}, {{ cursoInfo.nivel.nombre }} {{ cursoInfo.letra }}
                    </h1>

                    <div class="columns">
                        <div class="column">
                            <h2 class="subtitle mt-2">Tabulación de respuestas</h2>
                        </div>
                        <div class="column">
                            <div class="is-pulled-right">
                                <b-button
                                    type="is-primary"
                                    v-if="alumnos.length != 0"
                                    @click="guardar"
                                    :loading="loadingGuardar"
                                    >Guardar respuestas</b-button
                                >
                            </div>
                        </div>
                    </div>

                    <div v-if="alumnos.length === 0">
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>No se encontraron respuestas de estudiantes</p>
                            </div>
                        </section>
                    </div>

                    <div class="columns" v-for="(alumno, index) in alumnos" v-bind:key="alumno.id">
                        <div class="column box mt-3 mb-3">
                            <h2 class="subtitle is-4">{{ alumno.alumno.name }}</h2>
                            <p class="mb-5">
                                <b>{{ alumno.pregunta.orden }}) Pregunta: </b>
                                <span v-html="alumno.pregunta.pregunta"></span>
                            </p>
                            <p class="mb-5">
                                <b>Respuesta: </b> <br />
                                <b-input
                                    v-model="respuestas[index].respuestaTexto"
                                    maxlength="500"
                                    type="textarea"
                                ></b-input>
                            </p>
                            <table class="table is-fullwidth is-bordered">
                                <tr>
                                    <td></td>
                                    <td
                                        :colspan="JSON.parse(alumno.pregunta.criterios_titulos).length"
                                        class="has-text-centered"
                                    >
                                        <strong>Categorías / Nivel</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="has-text-centered">
                                        <strong>Criterios</strong>
                                    </td>
                                    <td
                                        v-for="(criterio, key) in JSON.parse(alumno.pregunta.criterios_titulos)"
                                        v-bind:key="key"
                                        class="has-background-white-ter"
                                    >
                                        <div class="columns">
                                            <div class="column">
                                                <b class="is-small">{{ criterio }}</b>
                                            </div>
                                            <div class="column">{{ JSON.parse(alumno.pregunta.puntos)[key] }} pts.</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    v-for="(crit, key) in JSON.parse(alumno.pregunta.criterios).length"
                                    v-bind:key="key"
                                >
                                    <td
                                        v-for="(criterio, k) in JSON.parse(alumno.pregunta.criterios)[key]"
                                        v-bind:key="k"
                                        width="20%"
                                    >
                                        <b v-if="k == 0">{{
                                            wordWrap(JSON.parse(alumno.pregunta.criterios)[key][k], 20)
                                        }}</b>
                                        <b-radio
                                            v-if="k != 0"
                                            v-model="respuestas[index].respuesta"
                                            :name="'r' + index + key"
                                            :native-value="k"
                                        >
                                            {{ wordWrap(JSON.parse(alumno.pregunta.criterios)[key][k], 20) }}
                                        </b-radio>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Colegios from "../../components/Colegios.vue";
import axios from "axios";
export default {
    name: "Tabulacion",
    metaInfo: {
        title: "Tabular respuestas",
    },
    components: {
        Colegios,
    },
    data() {
        return {
            asignatura: this.$route.params.asignatura,
            curso: this.$route.params.curso,
            prueba: this.$route.params.prueba,
            asignacion: this.$route.params.asignacion,
            alumno: this.$route.params.alumno ?? null,
            evaluacionInfo: null,
            cursoInfo: null,
            preguntasInfo: [],
            respuestas: [],
            isLoading: false,
            loadingGuardar: false,
            informacion: [],
            alumnos: [],
        };
    },
    methods: {
        wordWrap(str, maxWidth) {
            if (!str) {
                return;
            }
            let newLineStr = "\n",
                done = false,
                res = "";
            while (str.length > maxWidth) {
                let found = false;
                // Inserts new line at first whitespace of the line
                for (let i = maxWidth - 1; i >= 0; i--) {
                    if (this.testWhite(str.charAt(i))) {
                        res = res + [str.slice(0, i), newLineStr].join("");
                        str = str.slice(i + 1);
                        found = true;
                        break;
                    }
                }
                // Inserts new line at maxWidth position, the word is too long to wrap
                if (!found) {
                    res += [str.slice(0, maxWidth), newLineStr].join("");
                    str = str.slice(maxWidth);
                }
            }

            return res + str;
        },

        testWhite(x) {
            var white = new RegExp(/^\s$/);
            return white.test(x.charAt(0));
        },
        limpiar(orden) {
            this.$set(this.respuestas, orden, null);
        },
        guardar() {
            let self = this;
            self.loadingGuardar = true;
            axios
                .post("/api/tabular/alternativas/con/rubrica/store", {
                    asignacion: this.$route.params.asignacion,
                    respuestas: this.respuestas,
                })
                .then((res) => {
                    if (this.alumno) {
                        return this.$router.push({
                            name: "EvaluacionEnCurso",
                            params: {
                                asignatura: this.$route.params.asignatura,
                                curso: this.$route.params.curso,
                                prueba: this.$route.params.prueba,
                                asignacion: this.$route.params.asignacion,
                            },
                        });
                    } else {
                        if (res.data.status) {
                            return this.$router.push({
                                name: "AsignacionResultado",
                                params: {
                                    asignacion: this.$route.params.asignacion,
                                },
                            });
                        } else {
                            this.$buefy.toast.open({
                                message: res.data.message,
                                type: "is-warning",
                            });
                        }
                    }
                    self.loadingGuardar = false;
                })
                .catch((err) => {
                    self.loadingGuardar = false;
                    throw err;
                });
        },
        getRespuestas() {
            axios
                .post("/api/asignaciones/respuestas/get", {
                    asignacion: this.$route.params.asignacion,
                    alumnoPreguntaAbierta: this.alumno,
                })
                .then((res) => {
                    this.alumnos = res.data;
                    this.alumnos.forEach((item, key) => {
                        this.$set(this.respuestas, key, {
                            alumno: item.alumno.id,
                            pregunta: item.pregunta.id,
                            respuesta: "",
                            respuestaTexto: item.respuestas,
                        });
                    });
                })
                .catch((err) => {
                    throw err;
                });
        },
        getInformacion() {
            axios
                .post("/api/pruebas/get", {
                    prueba: this.$route.params.prueba,
                })
                .then((res) => {
                    this.evaluacionInfo = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        getCurso() {
            axios
                .post("/api/cursos/curso", {
                    curso_id: this.$route.params.curso,
                })
                .then((res) => {
                    this.cursoInfo = res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
    filters: {
        truncate(value, length) {
            return value.length > length ? value.substr(0, length) + "..." : value;
        },
    },
    mounted() {
        this.getInformacion();
        this.getRespuestas();
        this.getCurso();
    },
};
</script>
