<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Agregar Nombre</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns"> 
            <div class="column">
                <h1 class="title">Deseas agregar un nombre a tu prueba para identificarla</h1>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-input v-model="nombre" ref="nombre" expanded placeholder="Ingresa un nombre para tu prueba"></b-input>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-button type="is-primary" rounded  @click="AgregarNombre">Agregar</b-button>
                <b-button type="is-primary " class="ml-5" rounded @click="Saltar">Saltar</b-button>
            </div>
        </div>
                           
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
    <div class="box">
          <table class="table is-fullwidth is-bordered">
                    <tr>
                        <td>ID</td>
                        <td>Estado</td>
                        <td>Asignatura</td>
                        <td>Nombre</td>
                        <td>Tipo</td>
                        <td>Etapa</td>
                        
                    </tr>
                    <tr>
                        <td>{{prueba.id}}</td>
                        <td><span v-html="getEstado(prueba.estado)" /></td>
                        <td> <span :class="'tag is-' + prueba.asignatura.imagen + ''">{{ prueba.asignatura.nombre }}</span></td>
                        <td>{{prueba.nombre}}</td>
                        <td>{{prueba.prueba_tipo.nombre}}</td>
                        <td>{{prueba.prueba_etapa.nombre}}</td>
                        
                    </tr>
                    
                </table>
    </div>

</div>
</template>
<script>
import Colegios from '../../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "PruebaNombre",
    metaInfo: {
        title: 'agregar nombre'
    },
    components: {
        },
    data() {
        return {   
            prueba:[]    ,    
            id: this.$route.params.prueba,
            isLoading: false,
            nombre:null
        }
    },
    methods: {
        Saltar(){
            return this.$router.push({
                name: 'EvaluacionesMisEvaluaciones'               
            });
        },
        AgregarNombre(){
             axios.post('/api/pruebas/nombre', {
                        prueba: this.$route.params.prueba,
                        nombre: this.nombre
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'Nombre guardado exitosamente',
                                type: 'is-success'
                            });
                        }
                        return this.$router.push({
                             name: 'EvaluacionesMisEvaluaciones'                          
                        });
                        self.loadingGuardar = false;
                    }).catch((err) => {
                        self.loadingGuardar = false;
                        throw err
                    })
        },
        getPrueba(){
             axios.post('api/pruebas/get', {
                prueba: this.$route.params.prueba
            }).then((res) => {
                this.prueba = res.data;
            }).catch((err) => {
                throw err
            });
           
        },
        getEstado(args){
            if (args == 0){
                return '<span class="tag is-light">Borrador</span>';
            }
            if (args == 1){
                return '<span class="tag is-success">Cerrada</span>';
            }
        },
      
    },
    mounted() {
        this.getPrueba();
    }
}
</script>
