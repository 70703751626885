<template>
<div class="container">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/">Crear evaluación</router-link>
                        </li>
                        <li>
                            <router-link to="/crear/evaluacion/niveles">Niveles</router-link>
                        </li>
                        <li>
                            <router-link :to="'/crear/evaluacion/niveles/' + this.$route.params.nivel + '/asignaturas'">Asignaturas</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Evaluaciones</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Selecciona el tipo de evaluación</h1>
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <h2 class="subtitle">Pruebas</h2>
                            <p style="height:300px">Instrumento evaluativo con el cual se busca evidenciar el aprendizaje de los estudiantes.</p>
                            <hr>
                            <b-button tag="router-link" :to="'/crear/evaluacion/niveles/' + this.$route.params.nivel + '/asignaturas/' + this.$route.params.asignatura + '/objetivos/1'">Seleccionar</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <h2 class="subtitle">Rúbrica</h2>
                            <p style="height:300px">Instrumento evaluativo de observación que presentan niveles de logro, ya sean cualitativos, cuantitativos e inclusive ambos. Cada uno de los niveles poseen una descripción respectiva de acuerdo al indicador correspondiente. Esto nos ayuda a dar un feedback detallado e informativo del estudiante.</p>
                            <hr>
                            <b-button tag="router-link" :to="'/crear/evaluacion/niveles/' + this.$route.params.nivel + '/asignaturas/' + this.$route.params.asignatura + '/objetivos/2'">Seleccionar</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <h2 class="subtitle">Lista de cotejo</h2>
                            <p style="height:300px">Instrumento evaluativo de observación de característica dicotómica. Los criterios consideran aspectos relevantes de proceso de aprendizaje los cuales no requieren escala de apreciación sino más bien si fueron o no logrados aquellos indicadores.</p>
                            <hr>
                            <b-button tag="router-link" :to="'/crear/evaluacion/niveles/' + this.$route.params.nivel + '/asignaturas/' + this.$route.params.asignatura + '/objetivos/3'">Seleccionar</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <h2 class="subtitle">Escala de apreciación</h2>
                            <p style="height:268px">Instrumento evaluativo de observación que presentan niveles de logro. Suele ser similar a la lista de cotejo sin embargo a diferencia esta posee niveles de logro por tanto no es de carácter dicotómico.</p>
                            <hr>
                            <b-button tag="router-link" :to="'/crear/evaluacion/niveles/' + this.$route.params.nivel + '/asignaturas/' + this.$route.params.asignatura + '/objetivos/4'">Seleccionar</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</div>
</template>
<script>
import Colegios from '../../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "CrearEvaluacionEvaluaciones",
    metaInfo: {
        title: 'Seleccionar evaluación'
    },
    components: {
        Colegios
    },
    data() {
        return {
            ramos: [],
            nivel: this.$route.params.nivel,
            isLoading: false
        }
    }
}
</script>
